import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor,HttpRequest,HttpResponse,HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
 
@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
    
  constructor(public router: Router) {
  }
 
	// tslint:disable-next-line:no-any
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 
    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
            if (error.error instanceof ErrorEvent) {
                console.error('Error Event');
            } else {
                switch (error.status) {
                    case 403:     //session expired
                        alert('Your session has expired. Please log in again');
                        sessionStorage.removeItem('isValidUser');
                        sessionStorage.removeItem('uid');
                        sessionStorage.removeItem('currentUser');
                        sessionStorage.removeItem('encKey');
                        sessionStorage.removeItem('accountDetails');
                       this.router.navigate(['/'])
                        break;
                    //More common errors can be added here
                }
            }
        } else {
            console.error('some thing else happened');
        }
        return throwError(error);
      })
    );
  }
}
