import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blue-cross-contact',
  templateUrl: './blue-cross-contact.component.html',
  styleUrls: ['./blue-cross-contact.component.scss']
})
export class BlueCrossContactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','4480');
  }

}
