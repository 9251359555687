import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription, timer } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AuthorizationServices } from '../../../shared/services/authorizationServices';
import { CheckIdleTimeService } from '../../../shared/services/check-idle-time.service';
import { NavService } from '../../../shared/services/commonServices';
import { GlobalServiceService } from '../../../shared/services/globalServices';
import { Logger } from '../../../shared/services/logger.service';
import { HeaderService } from '../header/header.service';

// tslint:disable-next-line:no-any
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {


  public accountId: number;
  public userName: string;
  public sessionInterval: number;
  public showHideContactUs: boolean = true;

  public minutesDisplay: number = 0;
  public secondsDisplay: number = 0;

  public endTime: number = JSON.parse(sessionStorage['config']).sessionExpiryDuration;

  public unsubscribe$: Subject<void> = new Subject();
  public timerSubscription: Subscription;
  public display: string = 'none';
  public url: string;

  public isActiveSearch: boolean;
  public orgName: string = JSON.parse(sessionStorage.getItem('accountDetails')).org_name;

  constructor(
    private headerService: HeaderService,
    private globalwindowService: GlobalServiceService,
    private navsvc: NavService,
    private logger: Logger,
    private authService: AuthorizationServices,
    private checkIdleTimeSvc: CheckIdleTimeService,
    private router: Router) {
    this.router.events.subscribe((newUrl) => {
      if (newUrl instanceof NavigationEnd) {
        const path = newUrl.url.toLowerCase();
        if (path.search('search') >= 0 || path === '/layout') {
          this.isActiveSearch = true;
        } else {
          this.isActiveSearch = false;
        }
      }
    });
  }

  public ngOnInit(): void {
    this.accountId = JSON.parse(sessionStorage.getItem('accountDetails')).account_id;
    this.userName = JSON.parse(sessionStorage.getItem('accountDetails')).contact_first;

    this.resetTimer();
    this.checkIdleTimeSvc.userActionOccured.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      this.resetTimer();
    });
    this.isSessionActive();
    this.decideFeature();
  }

  public decideFeature(): void {
    var globalFeatures = JSON.parse(sessionStorage.getItem('accountDetails')).features;
    var featureCode = globalFeatures.split(',');
    for (let value of featureCode) {
      if (value === 'RC') {
        this.showHideContactUs = false;
      }
    }
  }

  public OnDestroy(): void {
    if (this.sessionInterval) {
      clearInterval(this.sessionInterval);
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  public onMenuChange(): void {
    // $('#ember610').removeClass('active');
  }

  public selectedNavItem(item: number): void {

    this.navsvc.changeNav(item);
  }

  public openModal(): void {
    this.display = 'block';
  }
  public onCloseHandled(): void {
    this.display = 'none';
    sessionStorage.removeItem('isValidUser');
    sessionStorage.removeItem('response');
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('encKey');
    location.replace(JSON.parse(sessionStorage['config']).amaApplicationUrl);
    sessionStorage.removeItem('config');
  }

  public setFooterContact(): void {
    this.selectedNavItem(5);
  }

  public setFooterFaq(): void {
    this.selectedNavItem(4);
  }

  public setFooterToDefault(): void {
    this.selectedNavItem(6);
  }


  public uitrackingClickLog(featureCode: string): void {
    var requestUITrack = {
      'account_id': this.accountId,
      'service_used': 'UITRACK',
      'search_criteria': featureCode,
    };
    this.navsvc.uiTrakcing(requestUITrack).catch(err => {
      this.logger.error(err['error'].message);
    });
  }

  public logOut(): void {
    var sessionId = sessionStorage['uid'];
    if (sessionId != null && sessionId !== undefined) {
      this.url = JSON.parse(sessionStorage['config']).amaApplicationUrl;
      this.authService.deleteSession(sessionId).subscribe(response => {
        this.clearSession();  
      }, (err: Response) => {
        this.clearSession();
      });
    }
    else {
      sessionStorage.clear();
      this.router.navigate(['/']);
      /* this.url = JSON.parse(sessionStorage['config']).amaApplicationUrl;
      sessionStorage.clear();
      location.replace(this.url); */
    }

  }

  public clearSession(): void {
    window.name = '';
    sessionStorage.clear();
    this.authService.getConfig().subscribe(res => {
      let config = res;
      sessionStorage.setItem('config', JSON.stringify(res));
    })
    this.router.navigate(['/']);
    // location.replace(this.url);
  }

  public isSessionActive(): void {
    let sessionId;
    if (window.name) {
      sessionId = window.name ? JSON.parse(window.name)['session_id'] : '';
    } else {
      sessionId = sessionStorage.getItem('sessionId') ? sessionStorage.getItem('sessionId') : '';
    }

    if (sessionId) {
      this.authService.checkSession(sessionId).subscribe(response => {
        if (window.name) {
          if (!response.session_id && !JSON.parse(window.name).multi_logins_allowed) {
            this.openModal();
          }
        } else {
          if (!response.session_id && sessionId !== 'multi_logins_allowed') {
            this.openModal();
          }
        }
      }, (err: Response) => {
        if (window.name) {
          if (err['error'].message === 'Unauthorized - Session Does Not Exist' && !JSON.parse(window.name).multi_logins_allowed) {
            this.openModal();
          }
        } else {
          if (err['error'].message === 'Unauthorized - Session Does Not Exist' && sessionId !== 'multi_logins_allowed') {
            this.openModal();
          }
        }
      });
    }
  }



  public resetTimer(endTime: number = this.endTime): void {
    const interval = 1000;
    const duration = endTime * 60;
    this.timerSubscription = timer(0, interval).pipe(
      take(duration)
    ).subscribe(value =>
      this.render((duration - +value) * interval),
      err => {//
      },
      () => {
        this.checkIdleTimeSvc.logOutUser();
      }
    );
  }

  private render(count: number): void {
    this.secondsDisplay = parseInt(this.getSeconds(count));
    this.minutesDisplay = parseInt(this.getMinutes(count));
  }

  private getSeconds(ticks: number): string {
    const seconds = ((ticks % 60000) / 1000).toFixed(0);
    return this.pad(parseInt(seconds));
  }

  private getMinutes(ticks: number): string {
    const minutes = Math.floor(ticks / 60000);
    return this.pad(minutes);
  }

  private pad(digit: number): string {
    return digit <= 9 ? '0' + digit : digit.toString();
  }
}
