import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NPIRegistrationModel } from 'src/app/shared/models/npiRegistration.model';

@Component({
  selector: 'app-bcbstn-register',
  templateUrl: './bcbstn-register.component.html',
  styleUrls: ['./bcbstn-register.component.scss']
})
export class BcbstnRegisterComponent implements OnInit {    
  public regDetails: NPIRegistrationModel = {};
  public orgName: string = '';
  public isName: boolean = false;
  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','10670');
    if (this.regDetails.org_name) {
      this.isName = true;
      this.orgName = this.regDetails.org_name;
    }
  }

  navigateLogin(){
    this.router.navigate(['/login-form-rcca-lite-bcbstn-white-label']);
  }

}
