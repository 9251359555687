import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { NdcSearchService } from './ndc-search.service';
import { PagerService } from '../../shared/pagination/pager.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Observable, Subscription, interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavService } from '../../shared/services/commonServices';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DrugInfoComponent } from '../../../app/main/layout/drug-info/drug-info.component';
import { NgxMaskModule } from 'ngx-mask';
import { GlobalSearchViews } from '../../shared/models/global-search-views.data.model';
import { NdcCrosswalk } from '../../shared/models/ndc-crosswalk.data.model';
import { AuthorizationServices } from '../../shared/services/authorizationServices';
import { Logger } from '../../shared/services/logger.service';
import { EnvironmentData } from 'src/app/shared/models/environment.data.model';
// tslint:disable-next-line: no-any
declare var $: any;
@Component({
  selector: 'app-ndc-search',
  templateUrl: './ndc-search.component.html',
  styleUrls: ['./ndc-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NdcSearchComponent implements OnInit {
  public ndcPackageType: string;
  public ndcUnitOfMeasure: string;
  public codeBillableUnits: string;
  public isnoc: string;
  public selectedHcpcCodeIsNoc: boolean;
  public ndcManufacturer: string;
  public ndcStatus: string;
  public selectedNdcCode: string;
  public selectedNdcCodeDesc: string;
  public rcId: string;
  public termDate: string;
  public innerpack: string;

  public ndcsCodes: GlobalSearchViews[] = [];
  public model: { ndccode: string } = { ndccode: null };

  public isResultFound: boolean = false;
  public showData: boolean = false;
  // tslint:disable-next-line: no-any
  public pager: any = {};


  public pagedItems: GlobalSearchViews[] = [];

  public searchTextChanged: Subject<string> = new Subject<string>();
  public display: string = 'none';

  public selectedHcpcCodeOnHcpcSearch: string;
  public selectedHcpcDescrOnHcpcSearch: string;
  public isFirstTimeLoad: boolean = true;
  public isFromHCPCS: string = '';
  public currentPage: number = 1;
  public numPerPage: number = JSON.parse(sessionStorage['config']).numPerPage;
  public usertypedValueOnHcpc: string = null;
  public isFromUnitConversion: string = '';
  public usertypedValueOnNdc: string = null;

  public accountId: number;
  public isFromDrugName: string = '';
  public isFromHCpcOnPreviousClick: string;
  public isCC: boolean = false;
  public isCE: boolean = false;
  public isCG: boolean = false;
  public isCM: boolean = false;
  public isCU: boolean = false;
  public isPT: boolean = false;
  public isFN: boolean = false;
  public userTypedValueOnDrugNameSearch: string;
  public isFromUnitConversionForDrug: string;
  public userTypedValueOnDrugNameSearchfromSucess: string = null;
  public pageindex: number;
  public hcpcStatus: string;
  public hideNdcFooter: boolean = false;
  public pageName: string;
  public selectedHcpcCodeFromUnitConversion: string;
  public selectedHcpcDescrFromUnitConversion: string;
  private offset: number = 0;
  private limit: number = 50;
  public isRotate: boolean = false;
  private searchByHCPC: boolean = false;
  public totalLength: number = 0
  public responseMessage: string;
  public mySubscription: Subscription
  public isLoadingFN: boolean = false
  public config: EnvironmentData[] = [];

  @ViewChild(DrugInfoComponent, { static: false }) public appDrug: DrugInfoComponent;
  constructor(private ndcSearchService: NdcSearchService,
    private pagerService: PagerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private logger: Logger,
    private navsvc: NavService,
    private authService: AuthorizationServices,
    private cdr: ChangeDetectorRef) { }

  public ngOnInit(): void {
    this.pageName = 'ndc';

    this.model.ndccode = '';

    this.isResultFound = false;
    this.showData = false;
    this.config = JSON.parse(sessionStorage.getItem('config'));
    this.accountId = JSON.parse(sessionStorage.getItem('accountDetails')).account_id;
    this.decideFeature();
    this.isFromHCPCS = this.activatedRoute.snapshot.queryParamMap.get('isFromHCpc');
    this.usertypedValueOnNdc = this.activatedRoute.snapshot.queryParamMap.get('userTypedValueOnNdc');
    this.hcpcStatus = this.activatedRoute.snapshot.queryParamMap.get('hcpcStatus');

    this.isFromHCpcOnPreviousClick = this.activatedRoute.snapshot.queryParamMap.get('isFromHCpcOnPreviousClick');
    if (this.isFromHCpcOnPreviousClick === 'true') {
      this.model.ndccode = this.usertypedValueOnNdc;
      this.getListOfNcodeValue();
    }
    if (this.isFromHCPCS === 'true') {
      this.pageName = 'ndcFromHcpc';
      this.isFirstTimeLoad = false;
      this.selectedHcpcCodeOnHcpcSearch = this.activatedRoute.snapshot.queryParamMap.get('hcpccode');
      this.selectedHcpcDescrOnHcpcSearch = this.activatedRoute.snapshot.queryParamMap.get('hcpcdescr');
      this.rcId = this.activatedRoute.snapshot.queryParamMap.get('rcId');
      this.usertypedValueOnHcpc = this.activatedRoute.snapshot.queryParamMap.get('usertypedValue');
      this.selectedHcpcCodeIsNoc = this.activatedRoute.snapshot.queryParamMap.get('isnoc') === 'Y';
      //if (!this.cdr['destroyed']) this.cdr.detectChanges();

      this.fetchNdcCrossWalk(this.rcId);
    }
    this.isFromUnitConversion = this.activatedRoute.snapshot.queryParamMap.get('isFromUnitConversion');
    this.isFromUnitConversionForDrug = this.activatedRoute.snapshot.queryParamMap.get('isFromUnitConversionForDrug');
    if (this.isFromUnitConversion === 'true') {
      this.isFirstTimeLoad = false;
      this.pageName = 'ndcFromHcpc';
      this.model.ndccode = this.activatedRoute.snapshot.queryParamMap.get('UserTypedValueOnNdc');
      this.usertypedValueOnHcpc = this.activatedRoute.snapshot.queryParamMap.get('UsertypedValueOnHcpc');
      this.selectedHcpcCodeOnHcpcSearch = this.activatedRoute.snapshot.queryParamMap.get('selectedHcpcCodefromHcpc');
      this.selectedHcpcDescrOnHcpcSearch = this.activatedRoute.snapshot.queryParamMap.get('selectedHcpcDescrfromHcpc');
      this.selectedHcpcCodeIsNoc = (this.activatedRoute.snapshot.queryParamMap.get('isnoc') === 'Y'
        || this.activatedRoute.snapshot.queryParamMap.get('isnoc') === 'true');

      this.rcId = this.activatedRoute.snapshot.queryParamMap.get('rcId');
      this.fetchNdcCrossWalk(this.rcId);
    }
    this.isFromDrugName = this.activatedRoute.snapshot.queryParamMap.get('isFromDrugName');
    if (this.isFromDrugName === 'true') {
      this.pageName = 'ndcFromDrug';
      this.isFirstTimeLoad = false;
      this.selectedHcpcCodeIsNoc = this.activatedRoute.snapshot.queryParamMap.get('isnoc') === 'Y';
      this.selectedHcpcCodeOnHcpcSearch = this.activatedRoute.snapshot.queryParamMap.get('hcpccode');
      this.selectedHcpcDescrOnHcpcSearch = this.activatedRoute.snapshot.queryParamMap.get('hcpcdescr');
      this.rcId = this.activatedRoute.snapshot.queryParamMap.get('rcId');
      this.userTypedValueOnDrugNameSearch = this.activatedRoute.snapshot.queryParamMap.get('userTypedValueOnDrugNameSearch');
      this.fetchNdcCrossWalk(this.rcId);
    }
    if (this.isFromUnitConversionForDrug === 'true') {
      this.isFirstTimeLoad = false;
      this.pageName = 'ndcFromDrug';
      this.selectedHcpcCodeOnHcpcSearch = this.activatedRoute.snapshot.queryParamMap.get('selectedHcpcCodefromDrug');
      this.selectedHcpcDescrOnHcpcSearch = this.activatedRoute.snapshot.queryParamMap.get('selectedHcpcDescrfromDrug');
      this.userTypedValueOnDrugNameSearchfromSucess = this.activatedRoute.snapshot.queryParamMap.get('userTypedValueOnDrugNameSearchfromSucess');
      this.rcId = this.activatedRoute.snapshot.queryParamMap.get('rcId');
      this.fetchNdcCrossWalk(this.rcId);

    }
    this.isSessionActive();
    this.searchTextChanged.pipe(debounceTime(1000))

      .subscribe((ndc) => this.getNDCData(ndc));
    //if (!this.cdr['destroyed']) this.cdr.detectChanges();
  }

  public callOnKeyUp(e: Event): void {
    if ((e['which'] < 48 || e['which'] > 105) && e['which'] !== 8 && e['which'] !== 16) {
      return;
    }
    if (e['which'] === 91 || e['which'] === 92 || e['which'] === 93) {
      return;
    }
    this.searchTextChanged.next(e.target['value']);
    //if (!this.cdr['destroyed']) this.cdr.detectChanges();
  }

  public getNDCData(ndcData: string): void {
    this.model.ndccode = ndcData;
    this.ndcsCodes = [];
    this.pagedItems = [];
    this.pager = {};
    this.showData = false;
    this.selectedNavItem(2);
    this.isResultFound = false;
    if (this.model.ndccode.toString().length >= 3) {
      var allpartNdc = this.model.ndccode.toString();
      allpartNdc = allpartNdc.replace(/[^0-9 -]+/g, '');
      var allpartNdcWithHyphen = this.formatNdcWithHyphen(allpartNdc);

      this.usertypedValueOnNdc = allpartNdcWithHyphen;
      this.model.ndccode = allpartNdcWithHyphen
      this.fetchNdcCrossWalkByNDC(allpartNdcWithHyphen, this.isFirstTimeLoad);
      //if (!this.cdr['destroyed']) this.cdr.detectChanges();

    } else if (this.model.ndccode.toString().length < 1) {
      if (!this.isFirstTimeLoad) {
        this.fetchNdcCrossWalk(this.rcId);
        //if (!this.cdr['destroyed']) this.cdr.detectChanges();
      }

    }
  }

  public getListOfNcodeValue(): void {
    this.searchByHCPC = false;
    this.ndcSearchService.getInitialNDCSearch(this.accountId, this.model.ndccode).subscribe(data => {
      let status = data.status
      if (status === 200) {
        if (data.body === null) {
          this.isResultFound = true;
          this.responseMessage = 'No results returned for this search.'
          if (!this.cdr['destroyed']) this.cdr.detectChanges();
          return;
        } else {
          this.isResultFound = false
          this.responseMessage = ""
        }
        this.filterUnwantedData(Object.assign([], data.body));
      } else if (status === 204) {
        this.isResultFound = true
        this.responseMessage = "No results returned for this search."
      } else if (status === 205) {
        this.isResultFound = true
        this.responseMessage = "Search Results are not managed by this Organization."
      }

    },
      error => {
        this.logger.error(error['error'].message);
      }
    );
  }
  public selectedNavItem(item: number): void {

    if (!this.hideNdcFooter) {
      this.navsvc.changeNav(item);
    }
  }
  public fetchNdcCrossWalkByNDC(ndc: string, isBeginingFromNdcSearch: boolean): void {
    if (isBeginingFromNdcSearch) {
      this.searchByHCPC = false;
      this.ndcSearchService.getInitialNDCSearch(this.accountId, ndc).subscribe(res => {
        let status = res.status
        if (status === 200) {
          if (res.body !== null && Object.keys(res.body).length > 0) {
            this.requestNDCCrossWalkByNDCCompleted(res.body, this.ndcSearchService.url);
          } else {
            this.isResultFound = true;
            this.responseMessage = "No results returned for this search."
            if (!this.cdr['destroyed']) this.cdr.detectChanges();
            return;
          }
        } else if (status === 204) {
          this.isResultFound = true
          this.responseMessage = "No results returned for this search."
        } else if (status === 205) {
          this.isResultFound = true
          this.responseMessage = "Search Results are not managed by this Organization."
        }
      }, error => {
        this.logger.error(error['error'].message);
      });
    } else {
      this.searchByHCPC = false;
      this.ndcSearchService.getNDCCrossWalkWithNDC(this.accountId, this.rcId, ndc, this.offset, this.limit).subscribe(response => {
        if (response !== null && Object.keys(response).length > 0) {
          this.requestNDCCrossWalkByNDCCompleted(response, this.ndcSearchService.url);
        } else {
          this.isResultFound = true;
          this.responseMessage = "No results returned for this search."
          if (!this.cdr['destroyed']) this.cdr.detectChanges();
          return;
        }
      }, error => {
        this.logger.error(error['error'].message);
      });
    }

  }
  public requestNDCCrossWalkByNDCCompleted(response: GlobalSearchViews | NdcCrosswalk, url: string): void {
    var successurl = url;
    var allPart = [];
    var parts = successurl.split('/');
    var allpartNdcWithHyphen = this.formatNdcWithHyphen(this.model.ndccode);
    //setTimeout(()=> {
    //  if ((this.usertypedValueOnNdc === parts[9]) || (allpartNdcWithHyphen === parts[9])) {
    this.calculateResponse(response);
    //  }
    //}, 100);
  }

  // tslint:disable-next-line: no-any
  public calculateResponse(response: any): void {
    if (this.isFirstTimeLoad) {
      if (response) {
        this.filterUnwantedData(response);
      }
    } else {
      if (response.XWalkViews) {
        this.filterUnwantedData(response.XWalkViews);
      } else {
        this.ndcsCodes = [];
      }
    }

    this.selectedNavItem(2);
    this.isResultFound = !(this.ndcsCodes.length > 0);
    this.responseMessage = "No results returned for this search."
    if (!this.cdr['destroyed']) this.cdr.detectChanges();

    if (this.ndcsCodes.length === 1) {
      if (!this.isFirstTimeLoad && this.isFromHCPCS === 'true') {

        this.ndcManufacturer = this.ndcsCodes[0].manufacturer;
        this.selectedNdcCode = this.ndcsCodes[0].ndc;
        this.selectedNdcCodeDesc = this.ndcsCodes[0].drug_name;
        this.ndcStatus = this.pagedItems[0] ? this.pagedItems[0].indicator === 'T' ? 'I' : 'A' : '';
        let navigationExtras: NavigationExtras = {
          queryParams: {
            selectedHcpcCodefromHcpc: this.selectedHcpcCodeOnHcpcSearch,
            selectedHcpcDescrfromHcpc: this.selectedHcpcDescrOnHcpcSearch,
            ndccode: this.selectedNdcCode,
            ndcManufacturer: this.ndcManufacturer,
            ndcdescr: this.selectedNdcCodeDesc,
            isFromNdc: true,
            usertypedValueonNdc: this.model.ndccode,
            usertypedValueOnHcpc: this.usertypedValueOnHcpc,
            rcId: this.rcId,
            hcpcStatus: this.hcpcStatus,
            status: this.pagedItems[0] ? this.pagedItems[0].indicator === 'T' ? 'I' : 'A' : '',
            termDate: this.ndcsCodes[0].term_dt,
            innerpack: this.ndcsCodes[0].inner_pack,
            isnoc: this.selectedHcpcCodeIsNoc,
          },
        };
        // this.isLoadingFN = false;
        // this.router.navigate(['layout/unit-conversion'], navigationExtras);
        let ndcFormat = this.selectedNdcCode.split('-')
        let selectedNdc = ndcFormat[0] + ndcFormat[1] + ndcFormat[2]
        let jsonBody = { "siteCode": this.config['FNSiteCode'], "drugId": selectedNdc }
        if (this.isFN) {
          this.ndcSearchService.getNDCFNSearch(jsonBody).subscribe(data => {
            if (data && data['coverages']) {
              let navigator = data['coverages'][0]
              this.isLoadingFN = false;
              sessionStorage.setItem('ndcNavigator', JSON.stringify(navigator))
              this.router.navigate(['layout/unit-conversion'], navigationExtras);
            } else {
              this.isLoadingFN = false;
              this.router.navigate(['layout/unit-conversion'], navigationExtras);
            }
          }, err => {
            this.isLoadingFN = false;
            this.router.navigate(['/layout/hcpcsearch'], navigationExtras);

          });
        } else {
          this.isLoadingFN = false;
          this.router.navigate(['layout/unit-conversion'], navigationExtras);
        }
      }
      if (this.isFirstTimeLoad) {
        this.searchByHCPC = false;
        this.ndcSearchService.getNDCCrossWalkWithNDC(this.accountId, this.pagedItems[0].rc_id.toString(),
          this.pagedItems[0].ndc, this.offset, this.limit).subscribe(response => {
            if (Object.keys(response).length > 0) {

              this.ndcManufacturer = response['XWalkViews'][0].manufacturer;
              this.termDate = response['XWalkViews'][0].term_dt;
              this.innerpack = response['XWalkViews'][0].inner_pack;

              this.selectedNdcCode = this.pagedItems[0].ndc;
              this.selectedNdcCodeDesc = this.pagedItems[0].drug_name;
              this.ndcStatus = this.pagedItems[0] ? this.pagedItems[0].indicator === 'T' ? 'I' : 'A' : '';
              this.isFirstTimeLoad = false;
              let navigationExtras: NavigationExtras = {
                queryParams: {
                  ndccode: this.selectedNdcCode,
                  ndcManufacturer: this.ndcManufacturer,
                  ndcdescr: this.selectedNdcCodeDesc,
                  isFromNdc: 'true',
                  isnoc: this.pagedItems[0].is_noc,
                  rcId: this.pagedItems[0].rc_id,
                  reImbCode: this.pagedItems[0].reimb_code,
                  rcdescr: this.pagedItems[0].rc_descr,
                  userTypedValue: this.usertypedValueOnNdc,
                  rcstatus: this.pagedItems[0].rc_status,
                  status: this.pagedItems[0] ? this.pagedItems[0].indicator === 'T' ? 'I' : 'A' : '',
                  termDate: this.termDate,
                  innerpack: this.innerpack,
                },
              };
              let ndcFormat = this.selectedNdcCode.split('-')
              let selectedNdc = ndcFormat[0] + ndcFormat[1] + ndcFormat[2]
              let jsonBody = { "siteCode": this.config['FNSiteCode'], "drugId": selectedNdc }
              if (this.isFN) {
                this.ndcSearchService.getNDCFNSearch(jsonBody).subscribe(data => {
                  if (data && data['coverages']) {
                    let navigator = data['coverages'][0]
                    this.isLoadingFN = false;
                    sessionStorage.setItem('ndcNavigator', JSON.stringify(navigator))
                    this.router.navigate(['/layout/hcpcsearch'], navigationExtras);
                  } else {
                    this.isLoadingFN = false;
                    this.router.navigate(['/layout/hcpcsearch'], navigationExtras);
                  }
                }, err => {
                  this.isLoadingFN = false;
                  this.router.navigate(['/layout/hcpcsearch'], navigationExtras);

                });
              } else {
                this.isLoadingFN = false;
                this.router.navigate(['/layout/hcpcsearch'], navigationExtras);
              }
            }
          }, error => {
            this.logger.error(error['error'].message);
          });


      }
    }
    this.redirectForSingleRecord();
  }

  public redirectForSingleRecord(): void {
    if (!this.isFirstTimeLoad && this.isFromDrugName === 'true' && this.ndcsCodes.length === 1) {
      this.ndcManufacturer = this.ndcsCodes[0].manufacturer;
      this.selectedNdcCode = this.ndcsCodes[0].ndc;
      this.selectedNdcCodeDesc = this.ndcsCodes[0].drug_name;
      this.ndcStatus = this.pagedItems[0] ? this.pagedItems[0].indicator === 'T' ? 'I' : 'A' : '';
      this.termDate = this.ndcsCodes[0].term_dt;
      this.innerpack = this.ndcsCodes[0].inner_pack;
      let navigationExtras: NavigationExtras = {
        queryParams: {
          selectedHcpcCodefromHcpc: this.selectedHcpcCodeOnHcpcSearch,
          selectedHcpcDescrfromHcpc: this.selectedHcpcDescrOnHcpcSearch,
          ndccode: this.selectedNdcCode,
          ndcManufacturer: this.ndcManufacturer,
          ndcdescr: this.selectedNdcCodeDesc,
          isFromNdcForDrug: true,
          usertypedValueonNdc: this.ndcsCodes[0].ndc,
          userTypedValueOnDrugNameSearch: this.userTypedValueOnDrugNameSearch,
          rcId: this.rcId,
          hcpcStatus: this.hcpcStatus,
          status: this.pagedItems[0] ? this.pagedItems[0].indicator === 'T' ? 'I' : 'A' : '',
          termDate: this.termDate,
          innerpack: this.innerpack,
          isnoc: this.selectedHcpcCodeIsNoc,
        },
      };
      // this.isLoadingFN = false;
      // this.router.navigate(['layout/unit-conversion'], navigationExtras);
              let ndcFormat = this.selectedNdcCode.split('-')
              let selectedNdc = ndcFormat[0] + ndcFormat[1] + ndcFormat[2]
              let jsonBody = { "siteCode": this.config['FNSiteCode'], "drugId": selectedNdc }
              if (this.isFN) {
                this.ndcSearchService.getNDCFNSearch(jsonBody).subscribe(data => {
                  if (data && data['coverages']) {
                    let navigator = data['coverages'][0]
                    this.isLoadingFN = false;
                    sessionStorage.setItem('ndcNavigator', JSON.stringify(navigator))
                    this.router.navigate(['layout/unit-conversion'], navigationExtras);
                  } else {
                    this.isLoadingFN = false;
                    this.router.navigate(['layout/unit-conversion'], navigationExtras);
                  }
                }, err => {
                  this.isLoadingFN = false;
                  this.router.navigate(['/layout/hcpcsearch'], navigationExtras);
  
                });
              } else  {
                this.isLoadingFN = false;
                this.router.navigate(['layout/unit-conversion'], navigationExtras);
              }  
      
    }
  }
  public decideFeature(): void {
    var globalFeatures = JSON.parse(sessionStorage.getItem('accountDetails')).features;
    var featureCode = globalFeatures.split(',');
    for (let value of featureCode) {
      if (value === 'CC') {
        this.isCC = true;
      }
      if (value === 'CE') {
        this.isCE = true;
      }
      if (value === 'CG') {
        this.isCG = true;
      }
      if (value === 'CM') {
        this.isCM = true;
      }
      if (value === 'CU') {
        this.isCU = true;
      }
      if (value === 'PT') {
        this.isPT = true;
      }
      if (value === 'OC') {
        this.hideNdcFooter = true;
      }
      if (value === 'FN') {
        this.isFN = true
        sessionStorage.removeItem('ndcNavigator')
      }
    }
  }

  public fetchNdcCrossWalk(rcId: string): void {
    this.searchByHCPC = true;
    this.ndcSearchService.getNDCCrossWalk(this.accountId, rcId, this.offset, this.limit).subscribe(data => {

      this.filterUnwantedData(data['XWalkViews']);
      if (!this.cdr['destroyed']) this.cdr.detectChanges();
    },
      error => {
        this.logger.error(error['error'].message);
      });
  }

  public clearModel(): void {
    this.isRotate = !this.isRotate;
    this.model.ndccode = null;
    this.ndcsCodes = [];
    this.pagedItems = [];
    this.isResultFound = false;
    this.pager = {};
    this.showData = false;
  }

  public filterUnwantedData(ndcsCodesList: GlobalSearchViews[]): void {
    if (this.isFromHCpcOnPreviousClick === 'true') {
      this.selectedNavItem(2);
    }

    if (ndcsCodesList !== undefined) {
      this.ndcsCodes = [];
      this.showData = true;
      for (let obj of ndcsCodesList) {
        if (obj.ndc) {
          this.ndcsCodes.push(obj);
        }
      }
      this.ndcsCodes.sort(this.compare);


      this.isResultFound = !(this.ndcsCodes.length > 0);
      if (!this.cdr['destroyed']) this.cdr.detectChanges();
      this.setPage(1);
      if (this.isFromDrugName === 'true') {
        this.redirectForSingleRecord();
      } else if (this.isFromHCPCS === 'true' && this.ndcsCodes.length === 1) {
        this.ndcManufacturer = this.ndcsCodes[0].manufacturer;
        this.selectedNdcCode = this.ndcsCodes[0].ndc;
        this.selectedNdcCodeDesc = this.ndcsCodes[0].drug_name;
        this.ndcStatus = this.pagedItems[0] ? this.pagedItems[0].indicator === 'T' ? 'I' : 'A' : '';
        let navigationExtras: NavigationExtras = {
          queryParams: {
            selectedHcpcCodefromHcpc: this.selectedHcpcCodeOnHcpcSearch,
            selectedHcpcDescrfromHcpc: this.selectedHcpcDescrOnHcpcSearch,
            ndccode: this.selectedNdcCode,
            ndcManufacturer: this.ndcManufacturer,
            ndcdescr: this.selectedNdcCodeDesc,
            isFromNdc: true,
            usertypedValueonNdc: this.model.ndccode,
            usertypedValueOnHcpc: this.usertypedValueOnHcpc,
            rcId: this.rcId,
            hcpcStatus: this.hcpcStatus,
            status: this.pagedItems[0] ? this.pagedItems[0].indicator === 'T' ? 'I' : 'A' : '',
            innerpack: this.ndcsCodes[0].inner_pack,
            isnoc: this.selectedHcpcCodeIsNoc,
          },
        };
        // this.router.navigate(['/layout/unit-conversion'], navigationExtras);
      }
    }
    else {
      this.isResultFound = true;
      this.responseMessage = "No results returned for this search."
      if (!this.cdr['destroyed']) this.cdr.detectChanges();
    }
  }

  public compare(a: GlobalSearchViews, b: GlobalSearchViews): number {
    const bandA = a.ndc;
    const bandB = b.ndc;
    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  public setNDCCode(index: number): void {
    this.isLoadingFN = true;
    if (this.isFirstTimeLoad === true) {
      this.searchByHCPC = false;
      this.ndcSearchService.getNDCCrossWalkWithNDC(this.accountId,
        this.pagedItems[index].rc_id.toString(), this.pagedItems[index].ndc, this.offset, this.limit).subscribe(response => {
          if (Object.keys(response).length > 0) {

            this.codeBillableUnits = response['XWalkViews'][0].bill_units_per_pkg;
            this.ndcUnitOfMeasure = response['XWalkViews'][0].pkg_size_uom;
            this.ndcPackageType = response['XWalkViews'][0].pkg_qty_uom;
            this.ndcManufacturer = response['XWalkViews'][0].manufacturer;
            this.termDate = response['XWalkViews'][0].term_dt;
            this.innerpack = response['XWalkViews'][0].inner_pack;
            if (!this.cdr['destroyed']) this.cdr.detectChanges();

            this.selectedNdcCode = this.pagedItems[index].ndc;
            this.selectedNdcCodeDesc = this.pagedItems[index].drug_name;
            this.ndcStatus = this.pagedItems[0] ? this.pagedItems[0].indicator === 'T' ? 'I' : 'A' : '';
            this.isFirstTimeLoad = false;
            let navigationExtras: NavigationExtras = {
              queryParams: {
                ndccode: this.selectedNdcCode,
                ndcManufacturer: this.ndcManufacturer,
                ndcdescr: this.selectedNdcCodeDesc,
                isFromNdc: 'true',
                isnoc: this.pagedItems[index].is_noc,
                rcId: this.pagedItems[index].rc_id,
                reImbCode: this.pagedItems[index].reimb_code,
                rcdescr: this.pagedItems[index].rc_descr,
                rcstatus: this.pagedItems[index].rc_status,
                userTypedValue: this.usertypedValueOnNdc,
                status: this.pagedItems[index].indicator === 'T' ? 'I' : 'A',
                termDate: this.termDate,
                innerpack: this.innerpack
              },
            };
            let ndcFormat = this.selectedNdcCode.split('-')
            let selectedNdc = ndcFormat[0] + ndcFormat[1] + ndcFormat[2]
            let jsonBody = { "siteCode": this.config['FNSiteCode'], "drugId": selectedNdc }
            if (this.isFN) {
              this.ndcSearchService.getNDCFNSearch(jsonBody).subscribe(data => {
                if (data && data['coverages']) {
                  let navigator = data['coverages'][0]
                  this.isLoadingFN = false;
                  sessionStorage.setItem('ndcNavigator', JSON.stringify(navigator))
                  this.router.navigate(['/layout/hcpcsearch'], navigationExtras);
                } else {
                  this.isLoadingFN = false;
                  this.router.navigate(['/layout/hcpcsearch'], navigationExtras);
                }
              }, err => {
                this.isLoadingFN = false;
                this.router.navigate(['/layout/hcpcsearch'], navigationExtras);

              });
            } else  {
              this.isLoadingFN = false;
              this.router.navigate(['/layout/hcpcsearch'], navigationExtras);
            }
            
          } else {
            //
          }
        }, error => {
          this.logger.error(error['error'].message);
        });

    } else if (!this.isFirstTimeLoad && (this.isFromDrugName === 'true' || this.isFromUnitConversionForDrug === 'true')) {
      this.searchByHCPC = false;
      this.ndcSearchService.getNDCCrossWalkWithNDC(this.accountId, this.pagedItems[index].rc_id.toString(),
        this.pagedItems[index].ndc, this.offset, this.limit).subscribe(res => {
          if (Object.keys(res).length > 0) {

            this.codeBillableUnits = res['XWalkViews'][0].bill_units_per_pkg;
            this.ndcUnitOfMeasure = res['XWalkViews'][0].pkg_size_uom;
            this.ndcPackageType = res['XWalkViews'][0].pkg_qty_uom;
            this.innerpack = res['XWalkViews'][0].inner_pack
            if (!this.cdr['destroyed']) this.cdr.detectChanges();
          }
        }, error => {
          this.logger.error(error['error'].message);
        });
      this.selectedNdcCode = this.pagedItems[index].ndc;
      this.ndcManufacturer = this.pagedItems[index].manufacturer;
      this.selectedNdcCodeDesc = this.pagedItems[index].drug_name;
      this.ndcStatus = this.pagedItems[0] ? this.pagedItems[0].indicator === 'T' ? 'I' : 'A' : '';
      if (this.selectedHcpcCodeIsNoc === null || this.selectedHcpcCodeIsNoc === undefined) this.selectedHcpcCodeIsNoc = this.activatedRoute.snapshot.queryParamMap.get('isnoc') === 'Y' || this.activatedRoute.snapshot.queryParamMap.get('isnoc') === 'true';
      // sessionStorage.setItem('navigator', JSON.stringify(this.pagedItems[index]['navigator']))
      let navigationExtras: NavigationExtras = {
        queryParams: {
          selectedHcpcCodefromHcpc: this.selectedHcpcCodeOnHcpcSearch,
          selectedHcpcDescrfromHcpc: this.selectedHcpcDescrOnHcpcSearch,
          ndccode: this.selectedNdcCode,
          ndcManufacturer: this.ndcManufacturer,
          ndcdescr: this.selectedNdcCodeDesc,
          isFromNdcForDrug: true,
          usertypedValueonNdc: this.ndcsCodes[0].ndc,
          userTypedValueOnDrugNameSearch:
            this.userTypedValueOnDrugNameSearchfromSucess !== null ?
              this.userTypedValueOnDrugNameSearchfromSucess : this.userTypedValueOnDrugNameSearch,
          rcId: this.rcId,
          status: this.pagedItems[index].indicator === 'T' ? 'I' : 'A',
          hcpcStatus: this.hcpcStatus,
          inner_pack: this.innerpack,
          isnoc: this.selectedHcpcCodeIsNoc,
        },
      };
      let ndcFormat = this.selectedNdcCode.split('-')
      let selectedNdc = ndcFormat[0] + ndcFormat[1] + ndcFormat[2]
      let jsonBody = { "siteCode": this.config['FNSiteCode'], "drugId": selectedNdc }
      if (this.isFN) {
        this.ndcSearchService.getNDCFNSearch(jsonBody).subscribe(data => {
          if (data && data['coverages']) {
            let navigator = data['coverages'][0]
            this.isLoadingFN = false;
            sessionStorage.setItem('ndcNavigator', JSON.stringify(navigator))
            this.router.navigate(['layout/unit-conversion'], navigationExtras);
          } else {
            this.isLoadingFN = false;
            this.router.navigate(['layout/unit-conversion'], navigationExtras);
          }
        }, err => {
          this.isLoadingFN = false;
          this.router.navigate(['/layout/hcpcsearch'], navigationExtras);

        });
      } else  {
        this.isLoadingFN = false;
        this.router.navigate(['layout/unit-conversion'], navigationExtras);
      }
      
    }
    else {
      this.searchByHCPC = false;
      this.ndcSearchService.getNDCCrossWalkWithNDC(this.accountId, this.pagedItems[index].rc_id.toString(),
        this.pagedItems[index].ndc, this.offset, this.limit).subscribe(response => {
          if (Object.keys(response).length > 0) {

            this.codeBillableUnits = response['XWalkViews'][0].bill_units_per_pkg;
            this.ndcUnitOfMeasure = response['XWalkViews'][0].pkg_size_uom;
            this.ndcPackageType = response['XWalkViews'][0].pkg_qty_uom;
            this.innerpack = response['XWalkViews'][0].inner_pack;
            if (!this.cdr['destroyed']) this.cdr.detectChanges();
          }
        }, error => {
          this.logger.error(error['error'].message);
        });
      this.selectedNdcCode = this.pagedItems[index].ndc;
      this.ndcManufacturer = this.pagedItems[index].manufacturer;
      this.selectedNdcCodeDesc = this.pagedItems[index].drug_name;
      this.ndcStatus = this.pagedItems[index].indicator === 'T' ? 'I' : 'A';
      this.termDate = this.pagedItems[index].term_dt;
      // sessionStorage.setItem('navigator', JSON.stringify(this.pagedItems[index]['navigator']))
      let navigationExtras: NavigationExtras = {
        queryParams: {
          selectedHcpcCodefromHcpc: this.selectedHcpcCodeOnHcpcSearch,
          selectedHcpcDescrfromHcpc: this.selectedHcpcDescrOnHcpcSearch,
          ndccode: this.selectedNdcCode,
          ndcManufacturer: this.ndcManufacturer,
          ndcdescr: this.selectedNdcCodeDesc,
          isFromNdc: true,
          usertypedValueonNdc: this.model.ndccode,
          usertypedValueOnHcpc: this.usertypedValueOnHcpc,
          rcId: this.rcId,
          status: this.pagedItems[index].indicator === 'T' ? 'I' : 'A',
          hcpcStatus: this.hcpcStatus,
          termDate: this.termDate,
          innerpack: this.innerpack,
          isnoc: this.selectedHcpcCodeIsNoc,
        },
      };
      let ndcFormat = this.selectedNdcCode.split('-')
      let selectedNdc = ndcFormat[0] + ndcFormat[1] + ndcFormat[2]
      let jsonBody = { "siteCode": this.config['FNSiteCode'], "drugId": selectedNdc }
      if (this.isFN) {
        this.ndcSearchService.getNDCFNSearch(jsonBody).subscribe(data => {
          if (data && data['coverages']) {
            let navigator = data['coverages'][0]
            this.isLoadingFN = false;
            sessionStorage.setItem('ndcNavigator', JSON.stringify(navigator))
            this.router.navigate(['layout/unit-conversion'], navigationExtras);
          } else {
            this.isLoadingFN = false;
            this.router.navigate(['layout/unit-conversion'], navigationExtras);
          }
        }, err => {
          this.isLoadingFN = false;
          this.router.navigate(['/layout/unit-conversion'], navigationExtras);

        });
      } else  {
        this.isLoadingFN = false;
        this.router.navigate(['layout/unit-conversion'], navigationExtras);
      }
    }
  }


  public setPage(page: number, initialSearch: boolean = false): void {
    if (page < 1) {
      return;
    }
    if (page !== 1 && page > this.pager.totalPages) {
      return;
    }
    // get pager object from service
    this.pageindex = page;


    if (this.searchByHCPC) {
      if (this.ndcsCodes.length > 0) {
        this.totalLength = this.ndcsCodes[0].total_row_count;
        this.pager = this.pagerService.getPager(this.ndcsCodes[0].total_row_count, page);
        // this.isLoadingFN = false
      }
      if (!initialSearch) {
        this.ndcSearchService.getNDCCrossWalk(this.accountId, this.rcId, ((page - 1) * 10), 10).subscribe(data => {
          let ndcPagedData = data['XWalkViews'];
          this.isResultFound = !(ndcPagedData.length > 0);
          this.responseMessage = "No results returned for this search."
          this.pagedItems = ndcPagedData;
          if (!this.cdr['destroyed']) this.cdr.detectChanges();
          this.isLoadingFN = false;
        },
          error => {
            this.logger.error(error['error'].message);
          });
      }
    } else {
      this.totalLength = this.ndcsCodes.length;
      this.pager = this.pagerService.getPager(this.ndcsCodes.length, page);
      this.pagedItems = this.ndcsCodes.slice(this.pager.startIndex, this.pager.endIndex + 1);
      this.isLoadingFN = false
      if (!this.cdr['destroyed']) this.cdr.detectChanges();
    }

  }

  public redirectToHcpcOnPreviousClick(): void {

    let navigationExtras: NavigationExtras = {
      queryParams: {
        userTypedValue: this.usertypedValueOnHcpc,
        isFromNdcOnPreviousClick: 'true',
        isBegningNDC: 'false',
      },
    };    
    if (this.isFN) {
      sessionStorage.removeItem('ndcNavigator')
    }
    this.router.navigate(['/layout/hcpcsearch'], navigationExtras);

  }

  public redirectToDrugNameOnPreviousClick(): void {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        userTypedValue: this.userTypedValueOnDrugNameSearchfromSucess,
        isFromNdcOnPreviousClickThroughSuccess: 'true',
        isBegningNDC: 'false',

      },
    };    
    if (this.isFN) {
      sessionStorage.removeItem('ndcNavigator')
    }
    this.router.navigate(['/layout/drugnamesearch'], navigationExtras);
  }

  public redirectToDrugNameOnPreviousClickFromNDC(): void {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        isFromNdcOnPreviousClick: 'true',
        isBegningNDC: 'false',
        userTypedValueOnDrugNameSearchFromNdc: this.userTypedValueOnDrugNameSearch,
      },
    };    
    if (this.isFN) {
      sessionStorage.removeItem('ndcNavigator')
    }
    this.router.navigate(['/layout/drugnamesearch'], navigationExtras);
  }


  public removeDuplicate(origArr: GlobalSearchViews[]): GlobalSearchViews[] {
    var newArr: GlobalSearchViews[];
    var origLen = origArr.length;
    var found, x, y;

    for (x = 0; x < origLen; x++) {
      found = undefined;
      for (y = 0; y < newArr.length; y++) {
        if (origArr[x].reimb_code === newArr[y].reimb_code && origArr[x].ndc === newArr[y].ndc) {
          found = true;
          break;
        }
      }
      if (!found) {
        if (origArr[x] === undefined) {
          continue;
        }
        newArr.push(origArr[x]);
      }
    }
    return newArr;
  }

  public formatNdcWithHyphen(allpartNdc: string): string {
    var allpartNdcWithHyphen = allpartNdc;
    if (allpartNdc.length <= 5 || allpartNdc.includes('-')) {
      allpartNdcWithHyphen = allpartNdc;
    } else if (allpartNdc.length > 5 && allpartNdc.length <= 9) {
      allpartNdcWithHyphen = allpartNdc.substring(0, 5) + '-' + allpartNdc.substring(5, allpartNdc.length);
    } else if (allpartNdc.length > 9 && allpartNdc.length <= 11) {
      allpartNdcWithHyphen = allpartNdc.substring(0, 5) + '-' + allpartNdc.substring(5, 9) + '-' + allpartNdc.substring(9, allpartNdc.length);
    }

    let ndcFormat = allpartNdc.split('-')
    if (allpartNdcWithHyphen.length < 13 && ndcFormat.length === 3) {
      if (ndcFormat[0] && ndcFormat[0].length < 5) {
        ndcFormat[0] = '0' + ndcFormat[0]
      }
      if (ndcFormat[1] && ndcFormat[1].length < 4) {
        ndcFormat[1] = '0' + ndcFormat[1]
      }
      if (ndcFormat[2] && ndcFormat[2].length < 2) {
        ndcFormat[2] = '0' + ndcFormat[2]
      }
      allpartNdcWithHyphen = ndcFormat[0] + '-' + ndcFormat[1] + '-' + ndcFormat[2]
    }
    return allpartNdcWithHyphen;
  }

  public isSessionActive(): void {
    let sessionId;
    if (window.name) {
      sessionId = window.name ? JSON.parse(window.name)['session_id'] : '';
    } else {
      sessionId = sessionStorage.getItem('sessionId') ? sessionStorage.getItem('sessionId') : '';
    }

    if (sessionId) {
      this.authService.checkSession(sessionId).subscribe(response => {
        if (window.name) {
          if (!response.session_id && !JSON.parse(window.name).multi_logins_allowed) {
            this.openModal();
          }
        } else {
          if (!response.session_id && sessionId !== 'multi_logins_allowed') {
            this.openModal();
          }
        }
      }, (err: Response) => {
        if (window.name) {
          if (err['error'].message === 'Unauthorized - Session Does Not Exist' && !JSON.parse(window.name).multi_logins_allowed) {
            this.openModal();
          }
        } else {
          if (err['error'].message === 'Unauthorized - Session Does Not Exist' && sessionId !== 'multi_logins_allowed') {
            this.openModal();
          }
        }
      });
    }
  }

  public openModal(): void {
    this.display = 'block';
  }

  public onCloseHandled(): void {
    this.display = 'none';
    sessionStorage.removeItem('isValidUser');
    sessionStorage.removeItem('response');
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('encKey');
    location.replace(JSON.parse(sessionStorage['config']).amaApplicationUrl);
    sessionStorage.removeItem('config');
  }

}
