import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import * as LogRocket from 'logrocket';
import { Observable, Subscription, timer } from 'rxjs';
import { AccountDetailsModel } from 'src/app/shared/models/account-details.model';
import { AccountOutput } from 'src/app/shared/models/account-output.data.model';
import { EnvironmentData } from 'src/app/shared/models/environment.data.model';
import { AccountDetails } from 'src/app/shared/services/account-details.prod';
import { AuthorizationServices } from 'src/app/shared/services/authorizationServices';
import { CheckAuthService } from 'src/app/shared/services/checkAuth.service';
import { Logger } from 'src/app/shared/services/logger.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-unauth',
  templateUrl: './unauth.component.html',
})

export class UnauthComponent {

  public error: string = '';
  public config: EnvironmentData;
  public selectedAccountId: string = '';
  // public encKey: string = '';
  public sessionId: string = '';
  public dateTimePassedByAma: string = new Date().toString();
  public showUserDropdown: boolean = false;
  public accountDetails: AccountDetailsModel[] = AccountDetails;
  private subscription: Subscription;
  private refreshTime: Observable<number> = timer(0, 60000);

  constructor(
    private cdr: ChangeDetectorRef,
    private authService: AuthorizationServices,
    private loginService: LoginService,
    private router: Router,
    private logger: Logger,
    private toastService: ToastService,
    private service: CheckAuthService
  ) {
  }
  public ngOnInit(): void {
    console.log("Unauth");
    let accessKey = sessionStorage.getItem("ak") ? sessionStorage.getItem("ak") : null;
		
		if (!accessKey) {
			this.loadConfig()
		} else {
      this.getConfiguration();
    }
    // this.encKey = sessionStorage.getItem('encKey');
    this.sessionId = sessionStorage.getItem('uid');
  }

  loadConfig(): void {
    this.authService.getConfig().subscribe(res => {
      this.config = res;
		this.service.loadConfig(res.CheckUrl).subscribe(resp => {
			let ak = resp.headers.get('Content-Cf-Ak');
			let sk = resp.headers.get('Content-Cf-Sk');
      let gs = resp.headers.get('Content-Cf-Gs');
			let chkAK = atob(ak);
			let chkSK = atob(sk);
      let chkGS = atob(gs);
      if(!sessionStorage.getItem('ak') || sessionStorage.getItem('ak') !==chkAK) {
        sessionStorage.setItem('ak', chkAK);
        sessionStorage.setItem('sk', chkSK);
        sessionStorage.setItem('gs', chkGS);
      }
      this.getConfiguration();
		}, err => {
			console.log("Error");
		});
  });
	}

  private getConfiguration(): void {
    this.authService.getConfig().subscribe(res => {
      this.config = res;
      sessionStorage.setItem('config', JSON.stringify(res));
      this.sessionIdleCheck();
      if (!this.cdr['destroyed']) this.cdr.detectChanges();
      // if (this.encKey && this.encKey !== 'null') {
      //   this.validateRequest();
      // }
      if (this.sessionId && this.sessionId !== 'null') {
        this.validateRequest();
      }
      let key = sessionStorage.getItem('key');
      if (key) {
        this.authService.decrypt(key).subscribe(res => {          
          let decodedMessage = res;
          let splittedText = decodedMessage.split('\\u0000')[0].trim();	
          let accountId = splittedText.split('/')[1]
          if (accountId) {
            this.createSessAndLogin(accountId);
          }
  
        }, (err: Response) => {
          this.logger.error('error in service:' + err['error'].message);
        });
        
      }

    }, (err: Response) => {
      this.logger.error(err['error'].message);
      this.showDanger('error in service:' + err['error'].message);
    });
  }

  private validateRequest(): void {
    this.authService.checkSession(this.sessionId).subscribe(res => {
      console.log("Unauth Validate Request")
      this.selectedAccountId = res['account_id'];
      this.getAccountDetails(this.selectedAccountId);
    }, (err: Response) => {
      this.sessionId = null;
      this.logger.error(err['error'].message);
      this.showDanger('error in service:' + err['error'].message);
    });
  }

  public getAccountDetails(accountId: string): void {
    this.authService.getAccountDetails(accountId).subscribe(res => {
      var loggedInDateTime = new Date(this.dateTimePassedByAma);
      var currentDateTime = new Date(res['current_date_time']);
      loggedInDateTime.setMinutes(loggedInDateTime.getMinutes() + 10);
      if (loggedInDateTime < currentDateTime) {
        this.showDanger('Login token time has expired/Invalid token.');
        return;
      }
      sessionStorage['isValidUser'] = true;
      sessionStorage.setItem('accountDetails', JSON.stringify(res[0]));
      if (!this.cdr['destroyed']) this.cdr.detectChanges();
      this.logRocket(res[0]);
      sessionStorage.setItem('showFirstTimeWelcome', 'true');
      this.validateSite(res[0]['username'])
    }, (err: Response) => {
      this.showDanger('error in service:' + err['error'].message);
      this.logger.error(err['error'].message);
    });

  }

  private createSessAndLogin(accountId) {
    this.authService.createSession(accountId).subscribe(res => {
      this.logger.debug(res);
      sessionStorage.setItem('uid', res["session_id"]);
      this.getAccountDetails(res["account_id"].toString());
    }, (err: Response) => {
      this.showDanger('error in service:' + err['error'].message);
      this.logger.error(err['error'].message);
    });

  }

  public validateSite(username: string): void {
    this.loginService.username_lookup(username).subscribe((data) => {
      let amaDetails = data.body[0];
      console.log(data)
      console.log(amaDetails)
      if (amaDetails['website_product_name'] === 'RCClaimAssist') {
        this.router.navigate(['/layout']);
      } else if (amaDetails['website_product_name'] === 'RCCALite') {
        window.location.replace(this.config.RCCALiteUrl + '/?sid=' + this.sessionId + '&uid=' + this.sessionId)
      } else if (amaDetails['website_product_name'] === 'RC2') {
        window.location.replace(this.config.Rc3Url + '/?sid=' + this.sessionId + '&uid=' + this.sessionId)
      } else if (amaDetails['website_product_name'] === 'RCNDCXWalk') {
        window.location.replace(this.config.RcNdcXwalkUrl + '/?sid=' + this.sessionId + '&uid=' + this.sessionId)
      } else {
        this.sessionId = null;
      }
    });

  }

  public sessionIdleCheck(): void {
    this.subscription = this.refreshTime.subscribe((seconds) => {
      this.authService.getConfig().subscribe(res => {
        if (res['ApplicationVersionNo'] !== (JSON.parse(sessionStorage.getItem('config'))).ApplicationVersionNo) {
          this.config = res;
          sessionStorage.setItem('config', JSON.stringify(res));
          this.logger.debug('Refreshed config');
        }
      }, (err: Response) => {
        this.logger.error(err['error'].message);
      });
    });
  }

  public logRocket(accountDetails: AccountOutput): void {
    if (!this.config) {
      this.authService.getConfig().subscribe(res => {
        sessionStorage.setItem('config', JSON.stringify(res));
        this.identifyLogRocket(accountDetails, res['CurrentEnvironment']);
      }, (err: Response) => {
        this.logger.error(err['error'].message);
        this.showDanger('error in service:' + err['error'].message);
      });
    } else {
      this.identifyLogRocket(accountDetails, this.config['CurrentEnvironment']);
    }
  }

  private identifyLogRocket(accountDetails: AccountOutput, env: string): void {
    // only enable LR for UAT env
    if (env === 'UAT') {
      // filter out automated testing user
      if (accountDetails.username !== 'AutoFSUser1') {
        LogRocket.init('rj-health/rc3');
        LogRocket.identify('rj-health/rc3', {
          name: accountDetails.username,
          email: accountDetails.email,
        });
      }
    }
  }

  public showDanger(dangerTpl: string): void {
    this.toastService.show(dangerTpl, { classname: 'bg-danger text-light', delay: 15000 });
  }

}
