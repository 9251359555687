import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from '../../shared/services/commonServices';
import { ViewEncapsulation } from '@angular/core';

// tslint:disable-next-line: no-any
declare var $: any;
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {

  public isCollapsed: boolean = true;

  constructor(private _router: Router, private _route: ActivatedRoute, private navsvc: NavService) { }

  public ngOnInit(): void {
    this.selectedNavItem(5);
  }

  public selectedNavItem(item: number): void {
    this.navsvc.changeNav(item);
  }

}
