import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';

@Component({
  selector: 'app-sessiontimeout',
  templateUrl: './sessiontimeout.component.html',
  styleUrls: ['./sessiontimeout.component.scss'],
})
export class SessiontimeoutComponent implements OnInit {

  constructor(private _router: Router) { }

  public ngOnInit():void {
    //
  }

}
