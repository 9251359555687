import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from '../../shared/services/commonServices';

// tslint:disable-next-line: no-any
declare var $: any;
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {

  constructor(private _router: Router, private _route: ActivatedRoute, private navsvc: NavService) { }

  public ngOnInit(): void {
    this.selectedNavItem(5);
  }
  public selectedNavItem(item: number): void {
    this.navsvc.changeNav(item);
  }

}
