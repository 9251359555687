import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NPIRegistrationModel } from 'src/app/shared/models/npiRegistration.model';

@Component({
  selector: 'app-blue-cross-register',
  templateUrl: './blue-cross-register.component.html',
  styleUrls: ['./blue-cross-register.component.scss']
})
export class BlueCrossRegisterComponent implements OnInit {
  
  public regDetails: NPIRegistrationModel = {};
  public orgName: string = '';
  public isName: boolean = false;
  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','4480');
    if (this.regDetails.org_name) {
      this.isName = true;
      this.orgName = this.regDetails.org_name;
    }
  }

  navigateLogin(){
    this.router.navigate(['/login-form-white-label']);
  }

}
