import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medicaid-login',
  templateUrl: './medicaid-login.component.html',
  styleUrls: ['./medicaid-login.component.scss']
})
export class MedicaidLoginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','10860');
  }

}
