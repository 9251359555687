import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-aetna-va-register',
  templateUrl: './aetna-va-register.component.html',
  styleUrls: ['./aetna-va-register.component.scss']
})
export class AetnaVARegisterComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','11019');
  }

  navigateLogin(){
    this.router.navigate(['/login-form-rcca-aetnava-white-label']);
  }
}
