import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from '../../shared/services/commonServices';
import { StepwizardComponent } from '../layout/stepwizard/stepwizard.component';
import { HeaderService } from '../layout/header/header.service';
import { config } from 'rxjs/internal/config';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalServiceService } from '../../shared/services/globalServices';
import { Logger } from '../../shared/services/logger.service';
import { UnitConversionService } from '../unit-conversion/unit-conversion.service';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [NgbModalConfig, NgbModal],
})
export class SearchComponent implements OnInit {
  public welcomeTemplate: string;
  public hideNdcOnly: boolean = false;
  public hideHcpcOnly: boolean = false;
  public hideDrugOnly: boolean = false;

  public accountId: number;
  public FREE_TRIAL_ORGID: string = '2820';
  public showFreeTrialAlert: boolean = false;
  public diffDays: number = 0;
  public showHideContactUs: boolean = true;
  public isFN: boolean = false;
  public navigatorLegends: any = [];
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private navsvc: NavService,
    private headerService: HeaderService,
    private logger: Logger,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private unitConversionService: UnitConversionService,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  public ngOnInit(): void {
    this.selectedNavItem(0);

    this.accountId = JSON.parse(sessionStorage.getItem('accountDetails')).account_id;
    this.decideFeature();
    this.showFreeTrialAlert = false;
    this.diffDays = 0;
    this.displayFreeTrial();
    this.getFNLegends();
  }

  public selectedNavItem(item: number): void {

    this.navsvc.changeNav(item);
  }

  public decideFeature(): void {
    var globalFeatures = JSON.parse(sessionStorage.getItem('accountDetails')).features;
    var featureCode = globalFeatures.split(',');
    for (let value of featureCode) {
      if (value === 'OC') {
        this.hideHcpcOnly = true;
      }
      if (value === 'OD') {
        this.hideDrugOnly = true;
      }
      if (value === 'ON') {
        this.hideNdcOnly = true;
      }
      if (value === 'RC') {
        this.showHideContactUs = false;
      }
      if (value === 'FN') {
        this.isFN = true
        sessionStorage.removeItem('codeNavigator')
        sessionStorage.removeItem('ndcNavigator')
      }
    }
  }



  public uitrackingClickLog(featureCode: string): void {
    var requestUITrack = {
      'account_id': JSON.parse(sessionStorage.getItem('accountDetails')).account_id,
      'service_used': 'UITRACK',
      'search_criteria': featureCode,
    };
    this.navsvc.uiTrakcing(requestUITrack).catch(err => {
      this.logger.error(err['error'].message);
    });
  }

  public displayFreeTrial(): void {
    var userAccountDetails = JSON.parse(sessionStorage['accountDetails']);
    if (userAccountDetails.org_id === this.FREE_TRIAL_ORGID) {
      this.showFreeTrialAlert = true;
      var terminatinDate = new Date(userAccountDetails.termination_date);
      var currentDate = new Date();
      var diffTime = Math.abs(terminatinDate.getTime() - currentDate.getTime());
      this.diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
  }

  public goToTop(): void {
    window.scroll({
      top: 0,
    });
  }

  public getFNLegends() {
    if (this.isFN && (!sessionStorage.getItem('navigatorLegends') || sessionStorage.getItem('navigatorLegends') == 'undefined')) {
      let siteCode = JSON.parse(sessionStorage.getItem("config")).FNSiteCode
      let jsonBody = { "siteCode": siteCode }
      this.unitConversionService.getFNDetails(jsonBody).subscribe(res => {
        this.navigatorLegends = []
        res['restrictionFields'].map(data => {       
          if(data['imageFileName'].includes('/')) {
            data['imageFileName'] = data['imageFileName'].split('/')[1]
          }
          this.navigatorLegends.push(data)
        })
        res['tierFields'].map(data => {          
          if(data['imageFileName'].includes('/')) {
            data['imageFileName'] = data['imageFileName'].split('/')[1]
          }
          this.navigatorLegends.push(data)
        })
        sessionStorage.setItem('navigatorLegends', JSON.stringify(this.navigatorLegends))
      })
    }
  }
}
