import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cfhp-forgot-password',
  templateUrl: './cfhp-forgot-password.component.html',
  styleUrls: ['./cfhp-forgot-password.component.scss']
})
export class CfhpForgotPasswordComponent implements OnInit {
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','666');
   }


  navigateForm() {
    this.router.navigate(['/login-form-rc-claim-assist-cfhp']);
  }
}
