import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NPIRegistrationModel } from 'src/app/shared/models/npiRegistration.model';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  selector: 'app-wl-register',
  templateUrl: './wl-register.component.html',
  styleUrls: ['./wl-register.component.scss']
})
export class WlRegisterComponent implements OnInit {
  @Output() returnResponse: EventEmitter<string> = new EventEmitter()
  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;
  @ViewChild('regForm', { static: false }) regForm: NgForm;
  public orgId: string = sessionStorage.getItem('whiteLabel') ? sessionStorage.getItem('whiteLabel') : '';
  public error: boolean = false;
  public errMsg: string = '';
  public errType: string = '';
  public isValid: boolean = false
  public regDetails: NPIRegistrationModel = {};
  public orgName: string = '';
  public isName: boolean = false;
  public siteKey: string = '';

  RegistrationForm = this.fb.group({
    title: [null],
    contact_first: [null, Validators.required],
    contact_last: [null, Validators.required],
    username: [null, Validators.required],
    password: [null, Validators.required],
    confirmPassword: [null, Validators.required],
    email: [null, Validators.required],
    speciality: [null],
    address_1: [null, Validators.required],
    address_2: [null],
    phone: [null, Validators.required],
    fax: [null],
    city: [null, Validators.required],
    state: [null, Validators.required],
    zip: [null, Validators.required],
    hear_about_us: [null],
    other: [null],
    accept_terms_and_conditions: [null, Validators.required],
    company_name: [null],
    npi: [null],
    website_product_name: [null],
    org_id: [null],
    recaptcha: [null, Validators.required]
  })

  constructor(
    private loginService: LoginService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.orgId = sessionStorage.getItem('whiteLabel');
    let config = JSON.parse(sessionStorage.getItem('config'));
    this.siteKey = sessionStorage.getItem('sk');
    if (sessionStorage.getItem('registerDetails'))
      this.populateForm();

  }

  populateForm() {
    this.regDetails = JSON.parse(sessionStorage.getItem('registerDetails'));
    this.RegistrationForm.patchValue({
      title: this.regDetails.provider_name_prefix,
      contact_first: this.regDetails.provider_first_name,
      contact_last: this.regDetails.provider_last_name,
      speciality: this.regDetails.provider_specialty,
      address_1: this.regDetails.provider_address1,
      address_2: this.regDetails.provider_address2,
      phone: this.regDetails.provider_phone,
      city: this.regDetails.provider_city,
      state: this.regDetails.provider_state,
      zip: this.regDetails.provider_zip,
      npi: this.regDetails.npi,
      org_id: this.regDetails.org_id
    })
    if (this.regDetails.org_name) {
      this.isName = true;
      this.orgName = this.regDetails.org_name;
    }
  }

  submitRegistration() {
    let pass: string = this.RegistrationForm.controls.password.value;
    let confirmpass: string = this.RegistrationForm.controls.confirmPassword.value;
    let zip: string = this.RegistrationForm.controls.zip.value;
    let email: string = this.RegistrationForm.controls.email.value;
    let pattern: string = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,9}$"

    if (this.RegistrationForm.invalid || this.RegistrationForm.value.orgType === 'null') {
      window.scrollTo(0, 0)
      this.error = true;
      this.errMsg = "Please fill in all the required fields";
      this.loginService.errorMessage(this.errMsg);
    } else if (this.RegistrationForm.controls.phone.value.length !== 10) {
      this.error = true;
      this.errMsg = "Phone number format is incorrect.";
      this.loginService.errorMessage(this.errMsg);
    } else if (!(zip.length === 5 || zip.length === 9)) {
      this.errMsg = 'Please verify Zipcode';
      this.loginService.errorMessage(this.errMsg);
    } else if (email && !email.match(pattern)) {
      window.scrollTo(0, 0)
      this.error = true;
      this.errMsg = "Email format is incorrect";
      this.loginService.errorMessage(this.errMsg);
    } else if ((pass.length !== confirmpass.length) && !(pass === confirmpass || pass.localeCompare(confirmpass) === 0)) {
      this.error = true;
      this.errMsg = 'Passwords do not match';
      this.loginService.errorMessage(this.errMsg);
    } else {
      this.checkUserRegister();
    }

  }

  checkUserRegister() {

    let pass: string = this.RegistrationForm.controls.password.value;
    let username: string = this.RegistrationForm.controls.username.value;
    let email: string = this.RegistrationForm.controls.email.value;
    this.errMsg = '';
    let emailJsonData = {
      'email': email,
      'site': "2",
      "category": null
    }

    this.loginService.validateEmail(emailJsonData).subscribe(response => {
      let emailstatus = response['status'];

      if (emailstatus === 0 || emailstatus === '0') {
        let userJsonData = {
          'username': username,
          'site': "2",
          "category": null
        }
        this.loginService.validateUsername(userJsonData).subscribe(resp => {
          let userstatus = resp['status'];

          if (userstatus === 0 || userstatus === '0') {
            let jsonData = {
              'username': username,
              'password': pass,
              'site': "2",
              "category": null
            }
            this.loginService.validatePassword(jsonData).subscribe(res => {
              let status = res['status'];

              if (status === 0 || status === '0') {
                this.registerUser();
              } else {
                this.errMsg = 'Please verify Password';
                this.loginService.errorMessage(this.errMsg);
              }
            });
          } else {
            this.errMsg = 'Please verify Username';
            this.loginService.errorMessage(this.errMsg);
          }
        });
      } else {
        this.errMsg = 'Please verify email';
        this.loginService.errorMessage(this.errMsg);
      }
    }, () => {
      this.errMsg = 'Error';
      this.loginService.errorMessage(this.errMsg);
    });
  }

  public registerUser() {
    let jsonData = {
      'title': this.RegistrationForm.controls.title.value,
      'contact_first': this.RegistrationForm.controls.contact_first.value,
      'contact_last': this.RegistrationForm.controls.contact_last.value,
      'username': this.RegistrationForm.controls.username.value,
      'password': this.RegistrationForm.controls.password.value,
      'email': this.RegistrationForm.controls.email.value,
      'specialty': this.RegistrationForm.controls.speciality.value,
      'address_1': this.RegistrationForm.controls.address_1.value,
      'address_2': this.RegistrationForm.controls.address_2.value,
      'phone': this.RegistrationForm.controls.phone.value,
      'fax': this.RegistrationForm.controls.fax.value,
      'city': this.RegistrationForm.controls.city.value,
      'state': this.RegistrationForm.controls.state.value,
      'zip': this.RegistrationForm.controls.zip.value,
      'hear_about_us': this.RegistrationForm.controls.hear_about_us.value,
      'npi': this.RegistrationForm.controls.npi.value,
      'other': "VRFY",
      'orgid': this.RegistrationForm.controls.org_id.value,
      'department': ''
    }
     this.loginService.registerUser(jsonData).subscribe(res => {
        let jsonEmail =
        {
          'email': this.RegistrationForm.controls.email.value,
          'orgId': this.RegistrationForm.controls.org_id.value,
          'verification_aa_id': res[0]['verfication_aa_id'],
          'category': "FT",
          'site': "3",
          'first_name': this.RegistrationForm.controls.contact_first.value,
          'last_name': this.RegistrationForm.controls.contact_last.value,
          "user_name": this.RegistrationForm.controls.username.value,
          'password': this.RegistrationForm.controls.password.value,
        }
        sessionStorage.setItem('verificationEmail', JSON.stringify(jsonEmail));
        this.loginService.sendVerificationEmail(jsonEmail).subscribe(data => {
          let status = data['status'];
          if (status === 0 || status === '0') {
            alert('Email sent to your account successfully.');
          } else {
            alert('Error in sending email');
          }          
          this.loginNavigate();
        }, () => {
          this.errMsg = 'Error in sending email';
          alert(this.errMsg);
          this.loginNavigate();
        });
      }) 

  }

  loginNavigate() {
    this.returnResponse.emit();
  }

  public checkEmail() {
    // this.error = false;
    // this.errType = '';
    // this.errMsg = '';
    let email: string = this.RegistrationForm.controls.email.value;

    let jsonData = {
      'email': email,
      'site': "2",
      "category": null
    }

    if (email) {
      this.loginService.validateEmail(jsonData).subscribe(response => {
        let status = response['status'];
        if (status === 0 || status === '0') {
          this.error = false;
          this.errType = '';
          this.isValid = true;
        } else if (status === '1' || status === 1) {
          this.errType = '';
          this.errMsg = '';
          this.isValid = false;
        } else if (status === '2' || status === 2) {
          this.error = true;
          this.errType = 'email';
          this.errMsg = 'Email format is invalid';
          this.isValid = false;
          this.loginService.errorMessage(this.errMsg);
        } else if (status === '3' || status === 3) {
          this.error = true;
          this.errType = 'email';
          this.errMsg = 'Email already registered';
          this.isValid = false;
          this.loginService.errorMessage(this.errMsg);
        } else {
          this.error = true;
          this.errType = 'email';
          this.errMsg = 'Error';
          this.loginService.errorMessage(this.errMsg);
          this.isValid = false;
        }
      }, () => {
        this.error = true;
        this.errType = 'email';
        this.errMsg = 'Error';
        this.isValid = false;
        this.loginService.errorMessage(this.errMsg);
      });
    } else {
      this.error = true;
      this.errType = 'email';
      this.errMsg = 'Email is required';
      this.isValid = false;
      this.loginService.errorMessage(this.errMsg);
    }
    return this.isValid;
  }

  public checkUsername() {
    // this.error = false;
    // this.errType = '';
    // this.errMsg = '';
    let username: string = this.RegistrationForm.controls.username.value;
    let jsonData = {
      'username': username,
      'site': "2",
      "category": null
    }
    if (username) {
      this.loginService.validateUsername(jsonData).subscribe(response => {
        let status = response['status'];

        if (status === 0 || status === '0') {
          this.error = false;
          this.errType = '';
          this.isValid = true;
        } else if (status === '1' || status === 1) {
          this.error = true;
          this.errType = 'username';
          this.errMsg = 'Username already in use';
          this.isValid = false;
          this.loginService.errorMessage(this.errMsg);
        } else if (status === '2' || status === 2) {
          this.error = true;
          this.errType = 'username';
          this.errMsg = 'Username format is invalid';
          this.isValid = false;
          this.loginService.errorMessage(this.errMsg);
        } else {
          this.error = true;
          this.errType = 'username';
          this.errMsg = 'Error';
          this.isValid = false;
          this.loginService.errorMessage(this.errMsg);
        }
      }, () => {
        this.error = true;
        this.isValid = false;
        this.errType = 'username';
        this.errMsg = 'Error';
        this.loginService.errorMessage(this.errMsg);
      });
    } else {
      this.error = true;
      this.errType = 'username';
      this.errMsg = 'Username is required';
      this.isValid = false;
      this.loginService.errorMessage(this.errMsg);
    }
    return this.isValid;
  }

  public checkPassword() {
    // this.error = false;
    // this.errType = '';
    // this.errMsg = '';
    let password: string = this.RegistrationForm.controls.password.value;

    let jsonData = {
      'username': this.RegistrationForm.controls.username.value,
      'password': this.RegistrationForm.controls.password.value,
      'site': "2",
      "category": null
    }
    if (password) {
      this.loginService.validatePassword(jsonData).subscribe(response => {
        let status = response['status'];

        if (status === 0 || status === '0') {
          this.error = false;
          this.errType = '';
          this.isValid = true;
        } else if (status === '1' || status === 1) {
          this.error = true;
          this.isValid = false;
          this.errType = 'password';
          this.errMsg = 'Password too short/not enough characters.';
          this.loginService.errorMessage(this.errMsg);
        } else if (status === '2' || status === 2) {
          this.error = true;
          this.isValid = false;
          this.errType = 'password';
          this.errMsg = 'Password format is invalid';
          this.loginService.errorMessage(this.errMsg);
        } else {
          this.error = true;
          this.isValid = false;
          this.errType = 'password';
          this.errMsg = 'Error';
          this.loginService.errorMessage(this.errMsg);
        }
      }, () => {
        this.error = true;
        this.isValid = false;
        this.errType = 'password';
        this.errMsg = 'Error';
        this.loginService.errorMessage(this.errMsg);
      });
    } else {
      this.error = true;
      this.errType = 'password';
      this.errMsg = 'Password is required';
      this.isValid = false;
      this.loginService.errorMessage(this.errMsg);
    }
    return this.isValid;
  }

  public checkConfirmPassword() {
    let pass: string = this.RegistrationForm.controls.password.value;
    let confirmpass: string = this.RegistrationForm.controls.confirmPassword.value;

    console.log(pass + " " + confirmpass);
    console.log(pass === confirmpass);
    console.log(pass.localeCompare(confirmpass));
    console.log(pass.match(confirmpass));
    if (pass.match(confirmpass) || (pass === confirmpass)) {
      this.error = false;
      this.errType = '';
      this.isValid = true;
    } else {
      this.error = true;
      this.errType = 'confirmPassword'
      this.isValid = false
      this.errMsg = 'Passwords do not match';
      this.loginService.errorMessage(this.errMsg);
    }
    return this.isValid;
  }
}
