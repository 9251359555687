import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { EnvironmentData } from '../../shared/models/environment.data.model';
import { PricingOutput } from '../../shared/models/pricing-output.data.model';
import { MinMaxOutPut } from '../../shared/models/min-max-output.data.model';
import { NdcConversion } from '../../shared/models/ndc-conversion.data.model';
import { NdcCrosswalk } from '../../shared/models/ndc-crosswalk.data.model';

@Injectable({
  providedIn: 'root',
})
export class UnitConversionService {
  public config: EnvironmentData;
  public url: string = '';
  public headers: HttpHeaders = null;

  constructor(private http: HttpClient) { }

  private setHeaders(): HttpHeaders {
    this.config = JSON.parse(sessionStorage.getItem('config'));
    let apiKey = sessionStorage.getItem('ak')
    return this.headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('x-api-key', apiKey)
        .set('Authorization', sessionStorage.getItem('uid') ? sessionStorage.getItem('uid') :
      JSON.parse(sessionStorage.getItem('config')).Authorization);
  }

  public getNDCCrosswalkByNDC(accountId: number, rcId: string, ndc: string, offset: number, limit: number): Observable<NdcCrosswalk> {
    let headers = this.setHeaders();
    var url = this.config.ServiceUrl + '/xwalk/' + rcId + '/' + ndc + '/' + offset + '/' + limit;
    this.url = url;
    return this.http.get(url, { headers: headers });
  }

  public getJcardPricingData(accountId: number, rcId: number, priceType: string): Observable<PricingOutput> {
    let headers = this.setHeaders();
    return this.http.get(this.config.ServiceUrl + '/rc_price/' + rcId + '/' + priceType, { headers: headers });
  }

  public getMinMaxData(accountId: number, rcId: string): Observable<MinMaxOutPut> {
    let headers = this.setHeaders();
    return this.http.get(this.config.ServiceUrl + '/min_max/' + rcId, { headers: headers });
  }

  public getJcardAdminCodeData(accountId: number, rcId: number): Observable<Object> {
    let headers = this.setHeaders();
    return this.http.get(this.config.ServiceUrl + '/admin_codes/' + rcId, { headers: headers });
  }

  public getJcardAgeGenderData(accountId: number, rcId: number): Observable<Object> {
    let headers = this.setHeaders();
    return this.http.get(this.config.ServiceUrl + '/age_gender/' + rcId, { headers: headers });
  }

  public calculateNDCConv(accountId: number, rcId: string, ndc: string, currentDate: string, unit: number): Observable<NdcConversion> {
    let headers = this.setHeaders();
    return this.http.get(this.config.ServiceUrl + '/ndc_conv/' + rcId + '/' + ndc + '/' + currentDate + '/' + unit,
      { headers: headers });
  }

  public getFSPricing(jsonData: object): Observable<object> {
    let headers = this.setHeaders();
    let orgId = JSON.parse(sessionStorage.getItem('accountDetails')).org_id
    jsonData['org_id'] = orgId;
    return this.http.post(this.config.ServiceUrl + '/fs/GetFSPricing', jsonData, { headers: headers });
  }

  public getFNDetails (data) {
    const headers = this.setHeaders();
    return this.http.post(this.config.ServiceUrl + '/navigator', data, { headers: headers });
  }

}
