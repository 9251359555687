import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './main/about/about.component';
import { ContactComponent } from './main/contact/contact.component';
import { DrugNameSearchComponent } from './main/drug-name-search/drug-name-search.component';
import { FaqComponent } from './main/faq/faq.component';
import { HcpcSearchComponent } from './main/hcpc-search/hcpc-search.component';
import { DrugInfoComponent } from './main/layout/drug-info/drug-info.component';
import { FooterComponent } from './main/layout/footer/footer.component';
import { HeaderComponent } from './main/layout/header/header.component';
import { LayoutComponent } from './main/layout/layout.component';
import { SessiontimeoutComponent } from './main/layout/sessiontimeout/sessiontimeout.component';
import { StepwizardComponent } from './main/layout/stepwizard/stepwizard.component';
import { NdcSearchComponent } from './main/ndc-search/ndc-search.component';
import { PasswordComponent } from './main/password/password.component';
import { SearchComponent } from './main/search/search.component';
import { UnauthFaqComponent } from './main/unauth/unauth-faq/unauth-faq.component';
import { ForgotPasswordComponent } from './main/unauth/forgot-password/forgot-password.component';
import { LoginComponent } from './main/unauth/login/login.component';
import { NpiValidateComponent } from './main/unauth/npi-validate/npi-validate.component';
import { PrivacyPolicyComponent } from './main/unauth/privacy-policy/privacy-policy.component';
import { RegisterComponent } from './main/unauth/register/register.component';
import { TermsAndConditionsComponent } from './main/unauth/terms-and-conditions/terms-and-conditions.component';
import { UnauthAboutComponent } from './main/unauth/unauth-about/unauth-about.component';
import { UnauthContactComponent } from './main/unauth/unauth-contact/unauth-contact.component';
import { UnauthFooterComponent } from './main/unauth/unauth-footer/unauth-footer.component';
import { UnauthHeaderComponent } from './main/unauth/unauth-header/unauth-header.component';
import { UnauthComponent } from './main/unauth/unauth.component';
import { UnitConversionComponent } from './main/unit-conversion/unit-conversion.component';
import { AetnaVaContactComponent } from './main/wlunauth/aetnaVA/aetna-va-contact/aetna-va-contact.component';
import { AetnaVALoginComponent } from './main/wlunauth/aetnaVA/aetna-va-login/aetna-va-login.component';
import { AetnaVAPreRegisterComponent } from './main/wlunauth/aetnaVA/aetna-va-pre-register/aetna-va-pre-register.component';
import { AetnaVARegisterComponent } from './main/wlunauth/aetnaVA/aetna-va-register/aetna-va-register.component';
import { BcbstnContactComponent } from './main/wlunauth/bcbstn/bcbstn-contact/bcbstn-contact.component';
import { BcbstnForgotPasswordComponent } from './main/wlunauth/bcbstn/bcbstn-forgot-password/bcbstn-forgot-password.component';
import { BcbstnLoginComponent } from './main/wlunauth/bcbstn/bcbstn-login/bcbstn-login.component';
import { BcbstnPreRegisterComponent } from './main/wlunauth/bcbstn/bcbstn-pre-register/bcbstn-pre-register.component';
import { BcbstnRegisterComponent } from './main/wlunauth/bcbstn/bcbstn-register/bcbstn-register.component';
import { BlueCrossContactComponent } from './main/wlunauth/blue-cross/blue-cross-contact/blue-cross-contact.component';
import { BlueCrossForgotPasswordComponent } from './main/wlunauth/blue-cross/blue-cross-forgot-password/blue-cross-forgot-password.component';
import { BlueCrossLoginComponent } from './main/wlunauth/blue-cross/blue-cross-login/blue-cross-login.component';
import { BlueCrossPreRegisterComponent } from './main/wlunauth/blue-cross/blue-cross-pre-register/blue-cross-pre-register.component';
import { BlueCrossRegisterComponent } from './main/wlunauth/blue-cross/blue-cross-register/blue-cross-register.component';
import { CfhpContactComponent } from './main/wlunauth/cfhp/cfhp-contact/cfhp-contact.component';
import { CfhpForgotPasswordComponent } from './main/wlunauth/cfhp/cfhp-forgot-password/cfhp-forgot-password.component';
import { CfhpLoginComponent } from './main/wlunauth/cfhp/cfhp-login/cfhp-login.component';
import { CfhpPreRegisterComponent } from './main/wlunauth/cfhp/cfhp-pre-register/cfhp-pre-register.component';
import { CfhpRegisterComponent } from './main/wlunauth/cfhp/cfhp-register/cfhp-register.component';
import { MedicaidContactComponent } from './main/wlunauth/north-dakota-medicaid/medicaid-contact/medicaid-contact.component';
import { MedicaidForgotPasswordComponent } from './main/wlunauth/north-dakota-medicaid/medicaid-forgot-password/medicaid-forgot-password.component';
import { MedicaidLoginComponent } from './main/wlunauth/north-dakota-medicaid/medicaid-login/medicaid-login.component';
import { MedicaidPreRegisterComponent } from './main/wlunauth/north-dakota-medicaid/medicaid-pre-register/medicaid-pre-register.component';
import { MedicaidRegisterComponent } from './main/wlunauth/north-dakota-medicaid/medicaid-register/medicaid-register.component';
import { WlForgotPasswordComponent } from './main/wlunauth/shared/wl-forgot-password/wl-forgot-password.component';
import { WlLoginComponent } from './main/wlunauth/shared/wl-login/wl-login.component';
import { WlPreRegisterComponent } from './main/wlunauth/shared/wl-pre-register/wl-pre-register.component';
import { WlRegisterComponent } from './main/wlunauth/shared/wl-register/wl-register.component';
import { Safe } from './shared/common/dom-sanitizer.pipe';
import { NumericDirective } from './shared/common/numeric';
import { GlobalHttpInterceptorService } from './shared/interceptor/globalHttpInterceptorService';
import { PagerService } from './shared/pagination/pager.service';
import { CommonService } from './shared/services/common.service';
import { NavService } from './shared/services/commonServices';
import { GlobalServiceService } from './shared/services/globalServices';
import { Logger } from './shared/services/logger.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material';
import { AetnaVaForgotPasswordComponent } from './main/wlunauth/aetnaVA/aetna-va-forgot-password/aetna-va-forgot-password.component';
import { AccessDeniedComponent } from './main/unauth/access-denied/access-denied.component';
import { InvalidSignatureComponent } from './main/unauth/invalid-signature/invalid-signature.component';

@NgModule({
  declarations: [
    AppComponent,
    UnauthComponent,
    UnauthAboutComponent,
    UnauthHeaderComponent,
    UnauthFooterComponent,
    UnauthFaqComponent,
    UnauthContactComponent,
    LoginComponent,
    RegisterComponent,
    NpiValidateComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    ForgotPasswordComponent,
    WlForgotPasswordComponent,
    WlLoginComponent,
    WlPreRegisterComponent,
    WlRegisterComponent,
    AetnaVALoginComponent,
    AetnaVARegisterComponent,
    AetnaVAPreRegisterComponent,
    AetnaVaContactComponent,
    BlueCrossLoginComponent,
    BlueCrossForgotPasswordComponent,
    BlueCrossPreRegisterComponent,
    BlueCrossRegisterComponent,
    BcbstnLoginComponent,
    BcbstnForgotPasswordComponent,
    BcbstnPreRegisterComponent,
    BcbstnRegisterComponent,
    BcbstnContactComponent,
    BlueCrossContactComponent,
    CfhpLoginComponent,
    CfhpForgotPasswordComponent,
    CfhpPreRegisterComponent,
    CfhpRegisterComponent,
    CfhpContactComponent,
    MedicaidLoginComponent,
    MedicaidForgotPasswordComponent,
    MedicaidPreRegisterComponent,
    MedicaidRegisterComponent,
    MedicaidContactComponent,
    LayoutComponent,
    AboutComponent,
    HeaderComponent,
    FooterComponent,
    FaqComponent,
    ContactComponent,
    SearchComponent,
    HcpcSearchComponent,
    NdcSearchComponent,
    UnauthComponent,
    DrugNameSearchComponent,
    SessiontimeoutComponent,
    StepwizardComponent,
    DrugInfoComponent,
    UnitConversionComponent,
    Safe,
    NumericDirective,
    PasswordComponent,
    AetnaVaForgotPasswordComponent,
    AccessDeniedComponent,
    InvalidSignatureComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    MatSnackBarModule,
    BrowserAnimationsModule
  ],
  providers: [
    PagerService,
    GlobalServiceService,
    NavService,
    Logger,
    CommonService,
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true},
  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
