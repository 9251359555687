import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
})

export class PrivacyPolicyComponent implements OnInit {

 constructor() { }

  public ngOnInit():void {
  }
  public closeWindow(): void {
    window.close();
  }
}
