import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from 'src/app/shared/models/UserModel';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentData } from '../models/environment.data.model';
import { AccountOutput } from '../models/account-output.data.model';
import { CheckSessionOutput } from '../models/checksession-output.data.model';

@Injectable({
  providedIn: 'root',
})

export class AuthorizationServices {

  private currentUserSubject: BehaviorSubject<UserModel>;
  public currentUser: Observable<UserModel>;
  public headers: HttpHeaders = null;
  public configuration: EnvironmentData;

  constructor(private _http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<UserModel>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

  }

  public get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  public getConfig(): Observable<EnvironmentData> {
    return this._http.get('./assets/config/config.json') as Observable<EnvironmentData>;
  }

  public logout(): void {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  public getAmaLoginDetails(jsonData: object): Observable<AccountOutput> {
    let headers = this.setHeaders();
    return this._http.post(this.configuration.ServiceUrl + '/amalogin', jsonData, { headers });
  }

  private setHeaders(): HttpHeaders {
    this.configuration = JSON.parse(sessionStorage.getItem('config'));
    let apiKey = sessionStorage.getItem('ak')
    return this.headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('x-api-key', apiKey)
        .set('Authorization', sessionStorage.getItem('uid') ? sessionStorage.getItem('uid') :
      JSON.parse(sessionStorage.getItem('config')).Authorization);
  }

  public getAccountDetails(accountId: string): Observable<AccountOutput> {
    let headers = this.setHeaders();
    return this._http.post(this.configuration.ServiceUrl + '/account', { 'accountid': accountId }, { headers });
  }
 /*  public decryptMessage(jsonData: object): Observable<string> {
    this.setHeaders();
    return this._http.post(this.configuration.LocalServiceUrl + 'rest/Coding/demessage', jsonData, { responseType: 'text' });
  } */
  public deleteSession(sessionId: string): Observable<AccountOutput> {
    let headers = this.setHeaders();
    return this._http.post(this.configuration.ServiceUrl + '/deleteSession', { 'session_id': sessionId }, { headers });
  }
  public checkSession(sessionId: string): Observable<CheckSessionOutput> {
    let headers = this.setHeaders();
    return this._http.post(this.configuration.ServiceUrl + '/checkSession', { 'session_id': sessionId }, { headers });
  }

  public createSession(accountId: string): Observable<AccountOutput> {
    let headers = this.setHeadersNoSession();
    return this._http.post(this.configuration.ServiceUrl + '/createSession', { 'account_id': accountId }, { headers });
  }

  private setHeadersNoSession(): HttpHeaders {
    this.configuration = JSON.parse(sessionStorage.getItem('config'));
    let apiKey = sessionStorage.getItem('ak')
    return  new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', apiKey)
      .set('Authorization', JSON.parse(sessionStorage.getItem('config')).Authorization);
  }

  public decrypt(encodedData: string) : Observable<string> {
    this.setHeaders();
    return this._http.post(this.configuration.CryptoWebService + "demessage" ,	{	'key': encodedData},{responseType: 'text'});
  }

}
