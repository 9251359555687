import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-aetna-va-forgot-password',
  templateUrl: './aetna-va-forgot-password.component.html',
  styleUrls: ['./aetna-va-forgot-password.component.scss']
})
export class AetnaVaForgotPasswordComponent implements OnInit {
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','11019');
   }

  navigateForm() {
    this.router.navigate(['/login-form-rcca-aetnava-white-label']);
  }
}
