import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalServiceService } from '../../../shared/services/globalServices';
import { Subscription } from 'rxjs';
import { NavService } from '../../../shared/services/commonServices';
import { BrowserDetailModel } from '../../../shared/models/browserDetails.data.model';
// tslint:disable-next-line: no-any
declare var $: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public showNdcDrugNameSection: boolean = false;
  public showHcpcsButton: boolean = true;
  public showNdcButton: boolean = true;
  public showDrugButton: boolean = true;
  public item: number;
  public currentYear: number;
  public subscription: Subscription;
  public showFooterContact: boolean = false;
  public showFooterFaq: boolean = false;
  public objBrMajorVersion: number;
  public objOffsetVersion: number;
  public browserDetail: BrowserDetailModel = { Browser_Name: null, Full_Version: 0, Major_Version: 0 };
  public currentVersion: string;
  constructor(private navService: NavService, private router: Router) {

  }
  public ngOnInit(): void {
    this.subscription = this.navService.navItem$.subscribe(
      item => this.setFooterFlag(item));
    this.currentYear = (new Date()).getFullYear();
    this.currentVersion = JSON.parse(sessionStorage['config']).currentVersion;
  }
  public OnDestroy(): void {

    this.subscription.unsubscribe();
  }

  public setFooterFlag(item: number): void {
    if (item === 0) {
      this.showNdcDrugNameSection = false;
      this.showNdcButton = true;
      this.showHcpcsButton = true;
      this.showDrugButton = true;
      this.showFooterContact = false;
      this.showFooterFaq = false;
    } else if (item === 1) {
      this.showNdcDrugNameSection = true;
      this.showHcpcsButton = false;
    } else if (item === 2) {
      this.showNdcDrugNameSection = true;
      this.showNdcButton = false;
    } else if (item === 3) {
      this.showNdcDrugNameSection = true;
      this.showDrugButton = false;
    }
    else if (item === 4) {
      this.showNdcDrugNameSection = false;
      this.showFooterContact = false;
      this.showFooterFaq = true;
      this.browserDetail = this.getBrowserDetail();
      if (this.browserDetail.Major_Version < 11 && this.browserDetail.Browser_Name === 'Netscape') {
        setTimeout(
          () => {
            $('div').removeClass('ieft6');
          }, 100
        );
      }
    }
    else if (item === 5) {
      this.showNdcDrugNameSection = false;
      this.showFooterContact = true;
      this.showFooterFaq = false;
      this.browserDetail = this.getBrowserDetail();
      if (this.browserDetail.Major_Version < 11 && this.browserDetail.Browser_Name === 'Netscape') {
        setTimeout(
          () => {
            $('div').removeClass('ieft4');
          }, 100
        );
      }
    }
    else if (item === 6) {
      this.showNdcDrugNameSection = false;
      this.showFooterContact = false;
      this.showFooterFaq = false;
    }
  }
  public close(): void {
    this.showNdcDrugNameSection = false;
  }

  public redirectToHcpcs(): void {
    sessionStorage.removeItem('codeNavigator')
    sessionStorage.removeItem('ndcNavigator')
    this.router.navigate(['/layout/hcpcsearch']);
    this.showNdcDrugNameSection = false;
    this.showNdcButton = true;
    this.showHcpcsButton = false;
    this.showDrugButton = true;
    if (location.hash.toString().indexOf('hcpcsearch') > 0) {
      setTimeout(
        () => {
          location.reload(true);
        }, 100
      );
    }
  }

  public redirectToNdc(): void {
    sessionStorage.removeItem('codeNavigator')
    sessionStorage.removeItem('ndcNavigator')
    this.router.navigate(['/layout/ndcsearch']);
    this.showNdcDrugNameSection = false;
    this.showNdcButton = false;
    this.showHcpcsButton = true;
    this.showDrugButton = true;
    if (String(location.hash).indexOf('ndcsearch') > 0) {
      setTimeout(
        () => {
          location.reload(true);
        }, 100
      );
    }
  }

  public redirectToDrugName(): void {
    sessionStorage.removeItem('codeNavigator')
    sessionStorage.removeItem('ndcNavigator')
    this.router.navigate(['/layout/drugnamesearch']);
    this.showNdcDrugNameSection = false;
    this.showNdcButton = true;
    this.showHcpcsButton = true;
    this.showDrugButton = false;
    if (String(location.hash).indexOf('drugnamesearch') > 0) {
      setTimeout(
        () => {
          location.reload(true);
        }, 100
      );
    }
  }
  public getBrowserDetail(): BrowserDetailModel {

    var objAgent = navigator.userAgent;
    var objbrowserName = navigator.appName;
    var objfullVersion = '' + parseFloat(navigator.appVersion);
    this.objBrMajorVersion = parseInt(navigator.appVersion, 10);
    var objOffsetName, ix; // In Chrome 
    if ((this.objOffsetVersion = objAgent.indexOf('Chrome')) !== -1) {
      objbrowserName = 'Chrome';
      objfullVersion = objAgent.substring(this.objOffsetVersion + 7);
    } // In Microsoft internet explorer 
    else if ((this.objOffsetVersion = objAgent.indexOf('MSIE')) !== -1) {
      objbrowserName = 'Microsoft Internet Explorer';
      objfullVersion = objAgent.substring(this.objOffsetVersion + 5);
    } // In Firefox 
    else if ((this.objOffsetVersion = objAgent.indexOf('Firefox')) !== -1) {
      objbrowserName = 'Firefox';
    } // In Safari 
    else if ((this.objOffsetVersion = objAgent.indexOf('Safari')) !== -1) {
      objbrowserName = 'Safari';
      objfullVersion = objAgent.substring(this.objOffsetVersion + 7);
      if ((this.objOffsetVersion = objAgent.indexOf('Version')) !== -1) objfullVersion = objAgent.substring(this.objOffsetVersion + 8);
    }
    // For other browser 'name/version' is at the end of userAgent 
    else if ((objOffsetName = objAgent.lastIndexOf(' ') + 1) < (this.objOffsetVersion = objAgent.lastIndexOf('/'))) {
      objbrowserName = objAgent.substring(objOffsetName, this.objOffsetVersion);
      objfullVersion = objAgent.substring(this.objOffsetVersion + 1);
      if (objbrowserName.toLowerCase() === objbrowserName.toUpperCase()) {
        objbrowserName = navigator.appName;
      }
    } // trimming the fullVersion string at semicolon/space if present 
    if ((ix = objfullVersion.indexOf(';')) !== -1) objfullVersion = objfullVersion.substring(0, ix);
    if ((ix = objfullVersion.indexOf(' ')) !== -1) objfullVersion = objfullVersion.substring(0, ix);
    this.objBrMajorVersion = parseInt('' + objfullVersion, 10);
    if (isNaN(this.objBrMajorVersion)) {
      objfullVersion = '' + parseFloat(navigator.appVersion);
      this.objBrMajorVersion = parseInt(navigator.appVersion, 10);
    }
    sessionStorage.setItem(
      'BROWSER', JSON.stringify({
        'Browser_Name': objbrowserName,
        'Full_Version': objfullVersion,
        'Major_Version': this.objBrMajorVersion,
      }));
    return {
      'Browser_Name': objbrowserName,
      'Full_Version': objfullVersion,
      'Major_Version': this.objBrMajorVersion,
    };
  }
}
