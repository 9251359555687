import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauth-contact',
  templateUrl: './unauth-contact.component.html',
  styleUrls: ['./unauth-contact.component.scss']
})
export class UnauthContactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    sessionStorage.getItem('whiteLabel') ? sessionStorage.removeItem('whiteLabel') : ''
  }

}
