import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentData } from '../../shared/models/environment.data.model';

@Injectable()
export class NavService {

  public headers: HttpHeaders = null;
  public config: EnvironmentData;
  constructor(private http: HttpClient) { }
  // Observable navItem source
  private _navItemSource:BehaviorSubject<number> = new BehaviorSubject<number>(0);
  // Observable navItem stream
  public navItem$:Observable<number> = this._navItemSource.asObservable();
  // service command
  public changeNav(num: number): void {
    this._navItemSource.next(num);
  }

  private setHeaders(): HttpHeaders {
    this.config = JSON.parse(sessionStorage.getItem('config'));
    let apiKey = sessionStorage.getItem('ak')
    return this.headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('x-api-key', apiKey)
        .set('Authorization', sessionStorage.getItem('uid') ? sessionStorage.getItem('uid') : this.config.Authorization);
  }

  public uiTrakcing(uiTrackingData: object): Promise<object> {
    let headers = this.setHeaders();
    return this.http.post(this.config.ServiceUrl + '/user_history/add_history', uiTrackingData, { headers }).toPromise();
  }

  public getBrowserDetails() {
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = '' + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf("Opera")) != -1) {
        browserName = "Opera";
        fullVersion = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf("Version")) != -1)
            fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in useAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
        browserName = "Microsoft Internet Explorer";
        fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
        browserName = "Chrome";
        fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
        browserName = "Safari";
        fullVersion = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf("Version")) != -1)
            fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
        browserName = "Firefox";
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
        (verOffset = nAgt.lastIndexOf('/'))) {
        browserName = nAgt.substring(nameOffset, verOffset);
        fullVersion = nAgt.substring(verOffset + 1);
        if (browserName.toLowerCase() == browserName.toUpperCase()) {
            browserName = navigator.appName;
        }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
        fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
        fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
        fullVersion = '' + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
    }

    //$window.sessionStorage[BROWSER] = browserName;
    var osName = "Not known"; 
    if (navigator.appVersion.indexOf("Win") != -1) { 
        osName = "Windows"; 
    } else if (navigator.appVersion.indexOf("Mac") != -1) {
        osName = "Mac"; 
    } else if (navigator.appVersion.indexOf("X11") != -1) {  
        osName = "UNIX"; 
    } else if (navigator.appVersion.indexOf("Linux") != -1) {  
        osName = "Linux"; 
    }
    
    return {
        "Browser_Name": browserName,
        "Full_Version": fullVersion,
        "Major_Version": majorVersion,
        "OS_Name": osName
    };
  }
  public accountActivities(accountActivityRequest: object): Observable<object> {
    let headers = this.setHeaders();
    return this.http.post(this.config.ServiceUrl + '/account_activities', accountActivityRequest, { headers });
  }

}
