import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aetna-va-login',
  templateUrl: './aetna-va-login.component.html',
  styleUrls: ['./aetna-va-login.component.scss']
})
export class AetnaVALoginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','11019');
  }

}
