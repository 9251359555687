import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
    // tslint:disable:no-any
  public toasts: any[] = [];

  public show(textOrTpl: string | TemplateRef<any>, options: any = {}):void {
    this.toasts.push({ textOrTpl, ...options });
  }

  public remove(toast:any):void {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
  // tslint:enable:no-any
}
