import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blue-cross-forgot-password',
  templateUrl: './blue-cross-forgot-password.component.html',
  styleUrls: ['./blue-cross-forgot-password.component.scss']
})
export class BlueCrossForgotPasswordComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','4480');
   }

  navigateForm() {
    this.router.navigate(['/login-form-white-label']);
  }
}
