import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-unauth-faq',
  templateUrl: './unauth-faq.component.html',
  styleUrls: ['./unauth-faq.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class UnauthFaqComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
