import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-medicaid-forgot-password',
  templateUrl: './medicaid-forgot-password.component.html',
  styleUrls: ['./medicaid-forgot-password.component.scss']
})
export class MedicaidForgotPasswordComponent implements OnInit {
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','10860');
   }

  navigateForm() {
    this.router.navigate(['/login-form-rcca-lite-white-label']);
  }
}
