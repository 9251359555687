import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentData } from '../../../shared/models/environment.data.model';
import { CheckSessionOutput } from '../../../shared/models/checksession-output.data.model';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  public headers: HttpHeaders = null;
  public config: EnvironmentData;

  constructor(private http: HttpClient) { }

  private setHeaders(): HttpHeaders {
    this.config = JSON.parse(sessionStorage.getItem('config'));
    return this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
  }

  public deleteSession(sessionId: string): Observable<CheckSessionOutput> {
    let headers = this.setHeaders();
    return this.http.post(this.config.ServiceUrl + '/deleteSession', { 'session_id': sessionId }, { headers });
  }
  public checkSession(sessionId: string): Observable<CheckSessionOutput> {
    let headers = this.setHeaders();
    return this.http.post(this.config.ServiceUrl + '/checkSession', { 'session_id': sessionId }, { headers });
  }

}
