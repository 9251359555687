import { Component, OnInit, Output, EventEmitter, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { GlobalServiceService } from '../../shared/services/globalServices';
import { Safe } from '../../shared/common/dom-sanitizer.pipe';
import { Logger } from '../../shared/services/logger.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavService } from '../../shared/services/commonServices';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  public isPageLoadedFirstTime: boolean = false;
  public welcomeTemplate: string;
  public showCloseButton: boolean = true;

  constructor(private _router: Router,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private logger: Logger,
    private router: Router,
    private GlobalServiceService: GlobalServiceService,
    private commonService: NavService) {

  }

  @ViewChild('content', { static: true }) public content: string;
  @ViewChild('resetPasswordPopup', { static: true }) public resetPasswordPopup: string;

  public openModal(): void {
    this.modalService.open(this.content, { windowClass: 'welcomeRCCA', centered: true, backdrop: 'static', keyboard: false, size: 'lg' });
  }

  public ngOnInit():void {
    var firstTimeLogin = sessionStorage.getItem('firstTimeLogin');
    if (firstTimeLogin === null) {
      sessionStorage.setItem('firstTimeLogin', 'false');
      var browserDetails = this.commonService.getBrowserDetails();
      var criteria = browserDetails.OS_Name + ', ' + browserDetails.Browser_Name + ', ' + browserDetails.Full_Version;
      var unilogTrack = {
          //'account_id':this.accountDetails.account_id,
          'service_used': 'UNILOG',
          'search_criteria': criteria,
          'r_id':'',
      };
      this.commonService.uiTrakcing(unilogTrack);
    }

    this.isPageLoadedFirstTime = sessionStorage['isPageLoadedFirstTime'];
    if (this.isPageLoadedFirstTime) {
      this.openModal();
      this.getWelcomePageFromS3();
    }
    sessionStorage.removeItem('isPageLoadedFirstTime');
    var accountActivityRequest = {
      'account_id': JSON.parse(sessionStorage.getItem('accountDetails')).account_id,
      'aa_type': 'FLD',
    };
    this.commonService.accountActivities(accountActivityRequest).subscribe(res => {
        
        for (var i = 0; i < res['length']; i++) {
            let aaCode = res[i].aa_code;
            var newVal = res[i].new_val;
            if ( aaCode === 'PWDR' && newVal.length > 0) {
                let date: Date = new Date(newVal);
                let currentDate = new Date();
                currentDate.setHours(0,0,0,0);
                if (date.getTime() < (currentDate).getTime()) {
                    this.showCloseButton = false;
                }
                this.openPasswordResetPopup();
                break;
            }
        }
    });
}

  public getWelcomePageFromS3(): void {
    this.GlobalServiceService.getWelcomePageFromS3().subscribe(response => {
      this.welcomeTemplate = response;
    },
    (err:Response) => {
      this.logger.error(err['error'].message);
    });
  }

  public getBrowserDetails() {
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = '' + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf("Opera")) != -1) {
        browserName = "Opera";
        fullVersion = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf("Version")) != -1)
            fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in useAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
        browserName = "Microsoft Internet Explorer";
        fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
        browserName = "Chrome";
        fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
        browserName = "Safari";
        fullVersion = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf("Version")) != -1)
            fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
        browserName = "Firefox";
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
        (verOffset = nAgt.lastIndexOf('/'))) {
        browserName = nAgt.substring(nameOffset, verOffset);
        fullVersion = nAgt.substring(verOffset + 1);
        if (browserName.toLowerCase() == browserName.toUpperCase()) {
            browserName = navigator.appName;
        }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
        fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
        fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
        fullVersion = '' + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
    }

    //$window.sessionStorage[BROWSER] = browserName;
    var osName = "Not known"; 
    if (navigator.appVersion.indexOf("Win") != -1) { 
        osName = "Windows"; 
    } else if (navigator.appVersion.indexOf("Mac") != -1) {
        osName = "Mac"; 
    } else if (navigator.appVersion.indexOf("X11") != -1) {  
        osName = "UNIX"; 
    } else if (navigator.appVersion.indexOf("Linux") != -1) {  
        osName = "Linux"; 
    }
    
    return {
        "Browser_Name": browserName,
        "Full_Version": fullVersion,
        "Major_Version": majorVersion,
        "OS_Name": osName
    };
  }
  public openPasswordResetPopup(): void {
		if (sessionStorage.getItem('showPwdResetModal') !== 'false') {
			this.modalService.open(this.resetPasswordPopup, { windowClass: 'welcomeRCCA', centered: true, backdrop: 'static', keyboard: false, size: 'md' });
		}
  }

  public openResetPage(): void {
    this.modalService.dismissAll();
    this.router.navigate(['/layout/password']);
	}
	
	public hidePwdResetModal(): void {
		sessionStorage.setItem('showPwdResetModal', 'false');
	}

}
