import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NPIRegistrationModel } from 'src/app/shared/models/npiRegistration.model';

@Component({
  selector: 'app-cfhp-register',
  templateUrl: './cfhp-register.component.html',
  styleUrls: ['./cfhp-register.component.scss']
})
export class CfhpRegisterComponent implements OnInit {
  
  public regDetails: NPIRegistrationModel = {};
  public orgName: string = '';
  public isName: boolean = false;
  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','666');
    if (this.regDetails.org_name) {
      this.isName = true;
      this.orgName = this.regDetails.org_name;
    }
  }

  navigateLogin(){
    this.router.navigate(['/login-form-rc-claim-assist-cfhp']);
  }

}
