import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  selector: 'app-aetna-va-pre-register',
  templateUrl: './aetna-va-pre-register.component.html',
  styleUrls: ['./aetna-va-pre-register.component.scss']
})
export class AetnaVAPreRegisterComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','11019');
   }


  navigateForm() {
    this.router.navigate(['/registration-rcca-aetnava-white-label']);
  }
}
