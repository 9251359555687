import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-medicaid-pre-register',
  templateUrl: './medicaid-pre-register.component.html',
  styleUrls: ['./medicaid-pre-register.component.scss']
})
export class MedicaidPreRegisterComponent implements OnInit {
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','10860');
   }


  navigateForm() {
    this.router.navigate(['/registration-rcca-lite-white-label']);
  }
}
