import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  TemplateRef,
} from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NavService } from '../../shared/services/commonServices';
import { UnitConversionService } from './unit-conversion.service';
import { HcpcSearchService } from '../hcpc-search/hcpc-search.service';
import { DrugInfoComponent } from '../../../app/main/layout/drug-info/drug-info.component';
import * as moment from 'moment';
import { NumericDirective } from '../../shared/common/numeric';
import { AccountOutput } from '../../shared/models/account-output.data.model';
import { AgeGenderOutPut } from '../../shared/models/age-gender.data.model';
import { AdminCodesOutPut } from '../../shared/models/admin-codes.data.model';
import { NdcCrosswalk } from '../../shared/models/ndc-crosswalk.data.model';
import { NdcConversion } from '../../shared/models/ndc-conversion.data.model';
import { AuthorizationServices } from '../../shared/services/authorizationServices';
import { Logger } from '../../shared/services/logger.service';

@Component({
  selector: 'app-unit-conversion',
  templateUrl: './unit-conversion.component.html',
  styleUrls: ['./unit-conversion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitConversionComponent implements OnInit {
  public ndcManufacturer: string;
  public isLoadingFromHcpc: string = '';
  public isLoadingFromNdc: string = '';
  public selectedNdcCode: string;
  public selectedNdcCodeDesc: string;
  public selectedHcpcCodefromHcpc: string;
  public selectedHcpcDescrfromHcpc: string;
  public UserTypedValueOnNdc: string;
  public UsertypedValueOnHcpc: string = '';
  public selectedNdcCodeDescr: string = null;
  public selectedHcpcCodeOnHcpcSearch: string;
  public isFromNdc: boolean = false;
  public selectedDrugCodesDescr: string;
  public selectedDrugCodes: string;
  public rcId: string = null;
  public reImbCode: string = null;
  public unitdec: string = null;
  public rcdescr: string = null;
  public selectedHcpcDescrOnHcpcSearch: string;
  public isLoadingFromNdcForDrug: string;
  public userTypedValueOnDrugNameSearch: string;
  public ndcStatus: string = null;
  public hcpcStatus: string = null;
  public hideNdcOnly: boolean;
  public hideHcpcOnly: boolean;
  public hideDrugOnly: boolean;
  public isFromHCPCS: string;
  public firstTimeLoadOnHCPC: boolean = true;
  public showAdminCodes: boolean = false;
  public showAgeGender: boolean = false;
  public adminCodeBox: boolean = false;
  public hcpcCodeUnitConvertion: string;
  public ndcPackageSizeConvertion: string;
  public ndcPriceUniteConvertion: string;
  public administereDeseConvertion: string;
  public values: string = '';
  public hcpcCodeUnitContainer: boolean = true;
  public administereDeseContainer: boolean = false;
  public ndcPriceUniteContainer: boolean = false;
  public ndcPackageSizeContainer: boolean = false;
  public showConversionSummary: boolean = false;
  public showDosesButtonsSection: boolean = true;
  public unitCalculationResult: boolean = false;
  public unitCalContainer: boolean = false;
  public unitCalculationContainer: boolean = true;
  public unitForCalculation: number = undefined;
  public dateOfService: string = undefined;
  public closeModal: string;
  public billedUnit: NdcConversion;
  public featureCodeUnitConversionFoundForBillUnit: boolean = false;
  public pageName: string;
  public accountId: number;
  public isCC: boolean = false;
  public isCE: boolean = false;
  public isCG: boolean = false;
  public isCM: boolean = false;
  public isCU: boolean = false;
  public isPT: boolean = false;
  public isFP: boolean = false;
  public isFN: boolean = false;
  public DO: boolean = false;
  public DW: boolean = false;
  public selectedMeasureType: string = 'HCPCS/CPT®';
  public isMeasureTypeMLOrVial: boolean = false;
  public dosesInMg: number = undefined;
  public ndcUnits: number = undefined;
  public packageSize: number = undefined;
  public ndcResponse: NdcCrosswalk[] = [];
  public ndcData: NdcCrosswalk[] = [];
  public jcodeMGValue: number = 0;
  public administeredDoseValue: number = 0;
  public codeUnits: number = 0;
  public flagMinMaxValue: boolean = false;
  public minMaxTooltipValue: string = '';
  public withoutTermedAdminCode: AdminCodesOutPut[] = [];
  public termedAdminCode: AdminCodesOutPut[] = [];
  public ageAndGenders: AgeGenderOutPut;
  public ndcPricingUnitValue: string;
  public ndcPackageSizeValue: string;
  public featureCodeUnitConversionFound: boolean = false;
  public calculatedMedicareAllowablePrice: number = undefined;
  public packageSizeRoundedValue: number = undefined;
  public codeUnitsRoundedValue: number = undefined;
  public dosesInMgRoundedValue: number = undefined;
  public ndcUnitsRoundedValue: number = undefined;
  public packageSizeJwvalue: number = undefined;
  public ndcUnitsJwvalue: number = undefined;
  public codeUnitsJwvalue: number = undefined;
  public dosesInMgJwvalue: number = undefined;
  public showDosingFeature: boolean = false;
  public showDosingAndWastageFeature: boolean = false;
  public dosingFeature: boolean = false;
  public conversionSummaryReport: boolean = false;
  public openDrugWastageModal: boolean = false;
  public showBillableUnitSection: boolean = false;
  public showPricingSection: boolean = false;
  public showMultidoseVial: boolean = false;
  public showMultiAndSingleDoses: boolean = false;
  public display: string = 'none';
	public termDate: string = '';
	public innerpack: string = '';
  public openPopover: string;
  public screenWidth: number;
  public selectedHcpcCodeIsNoc: boolean | string;
  public fsPrices = null;  
  public ndcNavigator: any = [];
  public codeNavigator: any = [];
  public navigatorLegends: any = [];
  public iconDetails: any = [];
  public fsPricesStatus: boolean = false;

  @ViewChild(DrugInfoComponent, { static: false }) public appDrugInfo: DrugInfoComponent;
  @ViewChild('wastageNoMultiAndSingleDoses', { static: true }) public wastageNoMultiAndSingleDoses: string;
  @ViewChild('coverageLegend', { static: true }) public coverageLegend: string;
  @ViewChild('navIconDetails', { static: true }) public navIconDetails: string;
  constructor(
    private HcpcSearchService: HcpcSearchService,
    private _router: Router,
    private _route: ActivatedRoute,
    private actvatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthorizationServices,
    private navsvc: NavService,
    private config: NgbModalConfig,
    private logger: Logger,
    private modalService: NgbModal,
    private unitConversionService: UnitConversionService,
    private cdr: ChangeDetectorRef,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.unitdec = JSON.parse(sessionStorage.getItem("accountDetails")).unitdec
  }

  public opneWastage(opneWastageModal: string): void {
    this.modalService.open(
      opneWastageModal,
      { windowClass: 'WastageModalContainer', size: 'lg' }
    );

  }

  public openWastagePopup(id: TemplateRef<number>): void {

    if (this.ndcResponse[0]['sdmd_per_each'] === 'MD') {
      this.showMultidoseVial = true;
      this.modalService.open(
        this.wastageNoMultiAndSingleDoses,
        { windowClass: 'wastageNoMultiAndSingleDoses', size: 'lg' }
      );
    }
    else if (!this.ndcResponse[0]['sdmd_per_each']) {
      this.showMultiAndSingleDoses = true;
      this.modalService.open(
        this.wastageNoMultiAndSingleDoses,
        { windowClass: 'wastageNoMultiAndSingleDoses', size: 'lg' }
      );
    } else if (this.ndcResponse[0]['sdmd_per_each'] === 'SD') {
      this.modalService.open(
        id,
        { windowClass: 'WastageModalContainer', size: 'lg' }
      );
    }
  }

  public openLegend() {
    this.modalService.open(
      this.coverageLegend,
      { windowClass: 'coverageLegend', size: 'lg' }
    );
  }

  public ngOnInit(): void {
    this.accountId = JSON.parse(sessionStorage.getItem('accountDetails')).account_id;
    this.decideFeature();
    this.getFNLegends()
    if (this.isFromHCPCS) {
      this.firstTimeLoadOnHCPC = false;
    }
    this.ndcManufacturer = this.actvatedRoute.snapshot.queryParamMap.get('ndcManufacturer');
    this.isLoadingFromNdc = this.actvatedRoute.snapshot.queryParamMap.get('isFromNdc');
    this.isLoadingFromHcpc = this.actvatedRoute.snapshot.queryParamMap.get('isFromHCpc');
    this.reImbCode = this.actvatedRoute.snapshot.queryParamMap.get('hcpccode');
    this.rcdescr = this.actvatedRoute.snapshot.queryParamMap.get('hcpcdescr');
    this.selectedNdcCode = this.actvatedRoute.snapshot.queryParamMap.get('ndccode');
    this.selectedNdcCodeDesc = this.actvatedRoute.snapshot.queryParamMap.get('ndcdescr');
    this.selectedHcpcCodefromHcpc = this.actvatedRoute.snapshot.queryParamMap.get('selectedHcpcCodefromHcpc');
    this.selectedHcpcDescrfromHcpc = this.actvatedRoute.snapshot.queryParamMap.get('selectedHcpcDescrfromHcpc');
    this.UserTypedValueOnNdc = this.actvatedRoute.snapshot.queryParamMap.get('usertypedValueonNdc');
    this.UsertypedValueOnHcpc = this.actvatedRoute.snapshot.queryParamMap.get('usertypedValueOnHcpc');
    this.rcId = this.actvatedRoute.snapshot.queryParamMap.get('rcId');
    this.selectedNdcCodeDescr = this.actvatedRoute.snapshot.queryParamMap.get('selectedNdcCodeDescr');
    this.isFromHCPCS = this.actvatedRoute.snapshot.queryParamMap.get('isFromHCpc');
    this.selectedHcpcCodeOnHcpcSearch = this.actvatedRoute.snapshot.queryParamMap.get('hcpccode');
    this.selectedHcpcDescrOnHcpcSearch = this.actvatedRoute.snapshot.queryParamMap.get('hcpcdescr');
    this.isLoadingFromNdcForDrug = this.actvatedRoute.snapshot.queryParamMap.get('isFromNdcForDrug');
    this.ndcStatus = this.actvatedRoute.snapshot.queryParamMap.get('status');
    this.hcpcStatus = this.actvatedRoute.snapshot.queryParamMap.get('hcpcStatus');
    this.termDate = this.actvatedRoute.snapshot.queryParamMap.get('termDate');
    this.innerpack = this.actvatedRoute.snapshot.queryParamMap.get('inner_pack');
		this.screenWidth = window.innerWidth;
    this.selectedHcpcCodeIsNoc = this.actvatedRoute.snapshot.queryParamMap.get('isnoc');
    if ( this.selectedHcpcCodeIsNoc !== null && !(this.selectedHcpcCodeIsNoc === 'false') ) {
      this.selectedMeasureType = 'ML units';
    }
    if (this.screenWidth < 1199) {
      this.openPopover = 'click';
    }
    else {
      this.openPopover = 'mouseenter:mouseleave, click';
    }
    if (this.isLoadingFromNdc === 'true') {
      this.pageName = 'unitConversionFromNdcFromHcpc';
      this.isFromNdc = true;
    }
    else if (this.isLoadingFromHcpc === 'true') {
      this.pageName = 'unitConversionFromHcpcFromNdc';
      this.selectedHcpcCodefromHcpc = this.actvatedRoute.snapshot.queryParamMap.get('hcpccode');
      this.selectedHcpcDescrfromHcpc = this.actvatedRoute.snapshot.queryParamMap.get('hcpcdescr');
      this.selectedNdcCode = this.actvatedRoute.snapshot.queryParamMap.get('selectedNdcCode');
      this.selectedNdcCodeDesc = this.actvatedRoute.snapshot.queryParamMap.get('selectedNdcCodeDescr');
    } else if (this.isLoadingFromNdcForDrug === 'true') {
      this.pageName = 'unitConversionFromNdcFromDrug';
      this.userTypedValueOnDrugNameSearch = this.actvatedRoute.snapshot.queryParamMap.get('userTypedValueOnDrugNameSearch');
    }
    this.isSessionActive();    
    this.getFNDetails();
    this.fetchNdc(this.accountId, this.rcId, this.selectedNdcCode);
    this.getJcardPricingData();
    this.fetchHcpcsDetails(this.selectedHcpcCodeOnHcpcSearch ? this.selectedHcpcCodeOnHcpcSearch : this.selectedHcpcCodefromHcpc);

  }

  private decideFeature(): void {
    var globalFeatures = JSON.parse(sessionStorage.getItem('accountDetails')).features;
    var featureCode = globalFeatures.split(',');
    for (let value of featureCode) {
      if (value === 'CC') {
        this.isCC = true;
      }
      if (value === 'CE') {
        this.isCE = true;
      }
      if (value === 'CG') {
        this.isCG = true;
      }
      if (value === 'CM') {
        this.isCM = true;
      }
      if (value === 'CU') {
        this.isCU = true;
      }
      if (value === 'PT') {
        this.isPT = true;
      }
      if (value === 'FP') {
        this.isFP = true;
      }
      if (value === 'DO') {
        this.showDosingFeature = true;
      }
      if (value === 'DW') {
        this.showDosingAndWastageFeature = true;
      }
      if (value === 'DA') {
        this.showAdminCodes = true;
      }
      if (value === 'DG') {
        this.showAgeGender = true;
      }
      if (value === 'DA' || value === 'DG') {
        this.adminCodeBox = true;
      }
      if (value === 'FN') {
        this.isFN = true;
      }
    }
  }

  public calculateUnitOfMeasure(selectedMeasureTypeValue: string): void {
    this.selectedMeasureType = selectedMeasureTypeValue;
  }

  public isSessionActive(): void {
    let sessionId;
    if (window.name) {
      sessionId = window.name ? JSON.parse(window.name)['session_id'] : '';
    } else {
      sessionId = sessionStorage.getItem('sessionId') ? sessionStorage.getItem('sessionId') : '';
    }

    if (sessionId) {
      this.authService.checkSession(sessionId).subscribe(response => {
        if (window.name) {
          if (!response.session_id && !JSON.parse(window.name).multi_logins_allowed) {
            this.openModal();
          }
        } else {
          if (!response.session_id && sessionId !== 'multi_logins_allowed') {
            this.openModal();
          }
        }
      }, (err: Response) => {
        if (window.name) {
          if (err['error'].message === 'Unauthorized - Session Does Not Exist' && !JSON.parse(window.name).multi_logins_allowed) {
            this.openModal();
          }
        } else {
          if (err['error'].message === 'Unauthorized - Session Does Not Exist' && sessionId !== 'multi_logins_allowed') {
            this.openModal();
          }
        }
      });
    }
  }

  public openModal(): void {
    this.display = 'block';

  }


  private fetchHcpcsDetails(hcpcsCode: string): void {
    this.HcpcSearchService.getHcpcsDetails(this.accountId, parseInt(this.rcId), 'R', hcpcsCode).subscribe(response => {
      this.jcodeMGValue = response['selectedCodeViews'].code_strength;
      this.administeredDoseValue = response['selectedCodeViews'].code_uom;
      if (!this.cdr['destroyed']) this.cdr.detectChanges();
    },
      error => {
        this.logger.error(error['error'].message);
      });
  }

  public calculateNdcBillableUnits(): void {
    this.dateOfService = moment().format('MM-DD-YYYY');
    this.fetchBilledUnit(this.accountId, this.rcId, this.selectedNdcCode, this.dateOfService, this.unitForCalculation);
    this.unitConversionService.calculateNDCConv(this.accountId, this.rcId, this.selectedNdcCode,
      this.dateOfService, this.unitForCalculation).subscribe(response => {
        this.billedUnit = response[0];
        if (!this.cdr['destroyed']) this.cdr.detectChanges();
        this.appDrugInfo.setData(response[0]);
      },
        error => {
          this.logger.error(error['error'].message);
        });
  }

  private checkDecimal(decimalString: string):boolean {
    if(decimalString.indexOf('.') > -1 && parseInt(decimalString.substring(decimalString.indexOf('.')+1)) > 0){
      return true;
    }else {
      return false;
    }
  }

  private fetchBilledUnit(accountId: number, rcId: string, ndc: string, dateOfService: string, unitForCalculation: number): void {
    this.unitConversionService.calculateNDCConv(this.accountId, this.rcId, this.selectedNdcCode,
      this.dateOfService, this.codeUnits).subscribe(response => {
        this.billedUnit = response[0];
        if (this.selectedMeasureType === 'HCPCS/CPT®' || this.selectedMeasureType === 'MG units') {
          this.openDrugWastageModal = this.billedUnit['numpkgsizeunits'] ? this.checkDecimal(this.billedUnit['numpkgsizeunits'].toString()) : false;
          if (!this.cdr['destroyed']) this.cdr.detectChanges();
        }
        if (this.selectedMeasureType === 'ML units' || this.selectedMeasureType === 'Vial units') {
          this.openDrugWastageModal = this.packageSize ? this.checkDecimal(this.packageSize.toString()) : false;
          if (!this.cdr['destroyed']) this.cdr.detectChanges();
        }
        if (this.isCC && this.billedUnit['pkg_size_uom'] === 'MC' || this.isCE && this.billedUnit['pkg_size_uom'] === 'EA' ||
          this.isCG && this.billedUnit['pkg_size_uom'] === 'MG' || this.isCM && this.billedUnit['pkg_size_uom'] === 'ME' ||
          this.isCU && this.billedUnit['pkg_size_uom'] === 'UN') {
          this.featureCodeUnitConversionFoundForBillUnit = true;
        }
        if (this.unitForCalculation && this.billedUnit) {
          if (this.unitForCalculation !== 0) {

            this.showPricingSection = true;
            if (!this.cdr['destroyed']) this.cdr.detectChanges();
            this.showBillableUnitSection = true;
            if (!this.cdr['destroyed']) this.cdr.detectChanges();
          } else {

            this.showPricingSection = false;
            this.showBillableUnitSection = false;
          }
        }
        else {

          this.showPricingSection = false;
          this.showBillableUnitSection = false;
        }
      },
        error => {
          this.logger.error(error['error'].message);
        });

  }

  private fetchNdc(accountId: number, rcId: string, ndc: string): void {
    this.unitConversionService.getNDCCrosswalkByNDC(this.accountId, this.rcId, this.selectedNdcCode, 0, 50).subscribe(res => {
      if (res['XWalkViews'] && res['XWalkViews'].length > 0) {
        res['XWalkViews'].forEach(element => {
          element.indicatorFilter = element.indicator === 'T' ? 'T' : '*';
          element.cms_rebateableFilter = element.cms_rebateable === 'Y' ? 'Y' : 'N';
          this.ndcData.push(Object.assign({}, element));
        });
				this.ndcResponse = Object.assign([], this.ndcData);
				this.innerpack = this.ndcResponse[0].inner_pack;
				// this.innerpack = 'N'
        this.ndcPricingUnitValue = this.ndcResponse[0].pkg_size_uom;
        this.ndcPackageSizeValue = this.ndcResponse[0].pkg_qty_uom;
        this.featureCodeUnitConversionFound = false;
        if (!this.cdr['destroyed']) this.cdr.detectChanges();
      }
    },
      error => {
        this.logger.error(error['error'].message);
      });
  }

  private getJcardPricingData(): void {
    this.unitConversionService.getJcardPricingData(this.accountId, parseInt(this.rcId), 'ASP').subscribe(response => {
      if (response && response.rcPricesViews) {
        response.rcPricesViews.sort((a, b) => {
          return new Date(a.price_eff_dt).getTime() - new Date(b.price_eff_dt).getTime();
        });
        this.calculatedMedicareAllowablePrice = this.codeUnits * response.rcPricesViews[response.rcPricesViews.length - 1].price;
        if (!this.cdr['destroyed']) this.cdr.detectChanges();
      }

    },
      error => {
        this.logger.error(error['error'].message);
      });
  }

  public calculateDoses(): void {
    this.getFSPricing();
    this.getFNDetails();
    if (this.unitForCalculation) {
      this.dateOfService = moment().format('MM-DD-YYYY');
    }
    this.showConversionSummary = true;
    this.showDosesButtonsSection = false;
    if (this.selectedMeasureType === 'HCPCS/CPT®') {
      this.isMeasureTypeMLOrVial = false;
      this.codeUnits = Math.round((this.unitForCalculation) * 100) / 100;
      this.dosesInMg = Math.round((this.unitForCalculation * this.jcodeMGValue) * 100) / 100;
      this.fetchBilledUnit(this.accountId, this.rcId, this.selectedNdcCode, this.dateOfService, this.codeUnits);
    }

    if (this.selectedMeasureType === 'MG units') {
      this.isMeasureTypeMLOrVial = false;
      this.dosesInMg = Math.round((this.unitForCalculation) * 100) / 100;
      this.codeUnits = Math.round((this.unitForCalculation / (this.jcodeMGValue)) * 100) / 100;
      this.fetchBilledUnit(this.accountId, this.rcId, this.selectedNdcCode, this.dateOfService, this.codeUnits);
    }
    if (this.selectedMeasureType === 'ML units') {
      this.isMeasureTypeMLOrVial = true;
      this.ndcUnits = Math.round((this.unitForCalculation) * 100) / 100;
      this.codeUnits = Math.round(((this.unitForCalculation / this.ndcResponse[0]['pkg_size']) *
        this.ndcResponse[0]['bill_units_per_pkg']) * 100) / 100;
      this.dosesInMg = Math.round((this.codeUnits * this.jcodeMGValue) * 100) / 100;
      this.packageSize = Math.round(((this.codeUnits / this.ndcResponse[0]['bill_units_per_pkg_qty']) * this.ndcResponse[0]['pkg_qty']) * 100) / 100;
      this.fetchBilledUnit(this.accountId, this.rcId, this.selectedNdcCode, this.dateOfService, this.codeUnits);
    }
    if (this.selectedMeasureType === 'Vial units') {
      this.isMeasureTypeMLOrVial = true;
      this.packageSize = Math.round((this.unitForCalculation) * 100) / 100;
      this.codeUnits = Math.round(((this.unitForCalculation) * this.ndcResponse[0]['bill_units_per_pkg']) * 100) / 100;
      this.dosesInMg = Math.round((this.codeUnits * this.jcodeMGValue) * 100) / 100;
      this.ndcUnits = Math.round(((this.codeUnits / this.ndcResponse[0]['bill_units_per_pkg']) * this.ndcResponse[0]['pkg_size']) * 100) / 100;
      this.fetchBilledUnit(this.accountId, this.rcId, this.selectedNdcCode, this.dateOfService, this.codeUnits);
    }
    this.getMinMaxData();
    this.getJcardPricingData();
    if ( this.selectedHcpcCodeIsNoc !== null && !(this.selectedHcpcCodeIsNoc === 'false') ) {
      this.codeUnits = 1;
    }
  }

  public calculateDosesForDrugWastage(): void {

    if (this.selectedMeasureType === 'HCPCS/CPT®' || this.selectedMeasureType === 'MG units') {
      this.packageSizeRoundedValue = Math.round((Math.ceil(this.billedUnit['numpkgsizeunits'])) * 100) / 100;
    }
    if (this.selectedMeasureType === 'ML units' || this.selectedMeasureType === 'Vial units') {
      this.packageSizeRoundedValue = Math.round((Math.ceil(this.packageSize)) * 100) / 100;
    }

    this.codeUnitsRoundedValue = Math.round((this.ndcResponse[0]['bill_units_per_pkg'] *
      this.packageSizeRoundedValue) * 100) / 100;
    this.dosesInMgRoundedValue = Math.round((this.codeUnitsRoundedValue * this.jcodeMGValue) * 100) / 100;
    this.ndcUnitsRoundedValue = Math.round(((this.codeUnitsRoundedValue / this.ndcResponse[0]['bill_units_per_pkg']) *
      this.ndcResponse[0]['pkg_size']) * 100) / 100;
    if (!this.isMeasureTypeMLOrVial) {
      this.packageSizeJwvalue = Math.round(this.packageSizeRoundedValue * 1000000 -
        this.billedUnit['numpkgsizeunits'] * 1000000) / 1000000;
      this.ndcUnitsJwvalue = (this.ndcUnitsRoundedValue * 1000000 -
        this.billedUnit['numhcpcsunits'] * 1000000) / 1000000;
    } else {
      this.packageSizeJwvalue = Math.round(this.packageSizeRoundedValue * 1000000 -
        this.packageSize * 1000000) / 1000000;
      this.ndcUnitsJwvalue = (this.ndcUnitsRoundedValue * 1000000 - this.ndcUnits * 1000000) / 1000000;
    }

    this.codeUnitsJwvalue = Math.round(this.codeUnitsRoundedValue * 1000000 -
      this.codeUnits * 1000000) / 1000000;
    this.dosesInMgJwvalue = (this.dosesInMgRoundedValue * 1000000 - this.dosesInMg * 1000000) / 1000000;
    if (!this.cdr['destroyed']) this.cdr.detectChanges();
    if ( this.selectedHcpcCodeIsNoc !== null && !(this.selectedHcpcCodeIsNoc === 'false') ) {
      this.codeUnitsRoundedValue = 1;
      this.codeUnitsJwvalue = 1;
    }
  }

  public redirectToNdc(): void {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        UsertypedValueOnHcpc: this.UsertypedValueOnHcpc,
        UserTypedValueOnNdc: this.UserTypedValueOnNdc,
        selectedHcpcCodefromHcpc: this.selectedHcpcCodefromHcpc,
        selectedHcpcDescrfromHcpc: this.selectedHcpcDescrfromHcpc,
        rcId: this.rcId,
        hcpcStatus: this.hcpcStatus,
        isFromUnitConversion: 'true',
        isnoc: this.selectedHcpcCodeIsNoc,
      },
    };
    if (this.showConversionSummary) {
      this.showDosesButtonsSection = true;
      this.showConversionSummary = false;
      this.showBillableUnitSection = false;
    } else {
      this.router.navigate(['/layout/ndcsearch'], navigationExtras);
    }

  }

  public redirectToHcpc(): void {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        reImbCode: this.reImbCode,
        rcdescr: this.rcdescr,
        ndccode: this.selectedNdcCode,
        ndcdescr: this.selectedNdcCodeDescr,
        isFromUnitConversion: 'true',
        status: this.ndcStatus,
        hcpcStatus: this.hcpcStatus,
        userTypedValue: this.UserTypedValueOnNdc,
        ndcManufacturer: this.ndcManufacturer,
        rcId: this.rcId,
        isnoc: this.selectedHcpcCodeIsNoc,
        innerpack: this.innerpack
      },
    };
    if (this.showConversionSummary) {
      this.showDosesButtonsSection = true;
      this.showConversionSummary = false;
      this.showBillableUnitSection = false;
    } else {
      this.router.navigate(['/layout/hcpcsearch'], navigationExtras);
    }

  }

  public redirectToNdcForDrug(): void {

    let navigationExtras: NavigationExtras = {
      queryParams: {
        selectedHcpcCodefromDrug: this.selectedHcpcCodefromHcpc,
        selectedHcpcDescrfromDrug: this.selectedHcpcDescrfromHcpc,
        isFromUnitConversionForDrug: 'true',
        rcId: this.rcId,
        userTypedValueOnDrugNameSearchfromSucess: this.userTypedValueOnDrugNameSearch,
        hcpcStatus: this.hcpcStatus,
        status: this.ndcStatus,
        isnoc: this.selectedHcpcCodeIsNoc,
      },
    };
    if (this.showConversionSummary) {
      this.showDosesButtonsSection = true;
      this.showConversionSummary = false;
      this.showBillableUnitSection = false;
    } else {
      this.router.navigate(['/layout/ndcsearch'], navigationExtras);
    }
  }

  // tslint:disable-next-line:no-any
  public clearInput(event: any): void {
    setTimeout(() => {
      event.srcElement.classList.toggle('rotate');
    }, 80);
    this.unitForCalculation = null;
  }

  public openPopup(modalName: TemplateRef<string>, isScrollable: boolean): void {
    this.modalService.open(
      modalName,
      { windowClass: 'is-brand-modal-container', scrollable: isScrollable, size: 'lg' }
    );
  }

  private getMinMaxData(): void {
    const rcId = this.rcId;
    this.unitConversionService.getMinMaxData(this.accountId, rcId).subscribe(response => {
      // this.flagMinMaxValue = false;
      this.minMaxTooltipValue = '';
      if(response && response['minMaxViews']) {
      const maxAbsCodeUnits = response['minMaxViews'].overall_max;
      const minAbsCodeUnits = response['minMaxViews'].overall_min;
      if (maxAbsCodeUnits && this.codeUnits > maxAbsCodeUnits) {
        this.minMaxTooltipValue = 'Code Units Above Max Dosing Range';
        this.flagMinMaxValue = true;
      } else if (minAbsCodeUnits && this.codeUnits < minAbsCodeUnits) {
        this.minMaxTooltipValue = 'Code Units Below Min Dosing Range';
        this.flagMinMaxValue = true;
      } else {
        this.flagMinMaxValue = false
      }
    }

      this.cdr.detectChanges()
    },
      error => {
        this.logger.error(error['error'].message);
      });
  }
  public getJcardAdminCodeData(): void {
    const rcId = parseInt(this.rcId);
    this.unitConversionService.getJcardAdminCodeData(this.accountId, rcId).subscribe(response => {
      if (response['adminCodesViews']) {
        this.withoutTermedAdminCode = response['adminCodesViews'].filter(obj => obj.is_termed === 'N');
        this.termedAdminCode = response['adminCodesViews'].filter(obj => obj.is_termed === 'Y');
        this.withoutTermedAdminCode.sort(this.compare);
        this.termedAdminCode.sort(this.compare);
      }
    },
      error => {
        this.logger.error(error['error'].message);
      });
  }

  public compare(a: AdminCodesOutPut, b: AdminCodesOutPut): number {
    const bandA = a.admin_code;
    const bandB = b.admin_code;
    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  public getJcardAgeGenderData(): void {
    const rcId = parseInt(this.rcId);
    this.unitConversionService.getJcardAgeGenderData(this.accountId, rcId).subscribe(response => {
      if (response['ageGenderViews'] && response['ageGenderViews'].length > 0) {
        this.ageAndGenders = response['ageGenderViews'][0];
        this.ageAndGenders.max_male = this.calculateAge(response['ageGenderViews'][0].max_male);
        this.ageAndGenders.min_male = this.calculateAge(response['ageGenderViews'][0].min_male);
        this.ageAndGenders.max_female = this.calculateAge(response['ageGenderViews'][0].max_female);
        this.ageAndGenders.min_female = this.calculateAge(response['ageGenderViews'][0].min_female);
      }
    },
      error => {
        this.logger.error(error['error'].message);
      });
  }

  private calculateAge(inputValue: number): string {
    let value: string;
    switch (inputValue) {
      case 0: value = 'Birth'; break;
      case 0.0384: value = '2 Weeks'; break;
      case 0.0833: value = '1 Month'; break;
      case 0.1153: value = '6 Weeks'; break;
      case 0.1666: value = '2 Months'; break;
      case 0.25: value = '3 Months'; break;
      case 0.3333: value = '4 Months'; break;
      case 0.4615: value = '24 Weeks'; break;
      case 0.5: value = '6 Months'; break;
      case 0.5384: value = '28 Weeks'; break;
      case 0.5833: value = '7 Months'; break;
      case 0.6154: value = '32 Weeks'; break;
      case 0.6346: value = '33 Weeks'; break;
      case 0.75: value = '9 Months'; break;
      case 1: value = '1 Year'; break;
      case 1.25: value = '15 Months'; break;
      case 1.3333: value = '16 Months'; break;
      case 2.9167: value = '35 Months'; break;
      default: value = inputValue + ' Years'; break;
    }
    return value;
  }

  public calculatePrice(fsPrice: any) {
    let price: number = 0;
    let codePrice = fsPrice['code_price'];
    let ndcPrice = fsPrice['ndc_price'];
    if (codePrice !== null && codePrice !== undefined && codePrice > 0) {
      price = codePrice * this.codeUnits;
    } else if (ndcPrice !== null && ndcPrice !== undefined && ndcPrice > 0) {
      if (this.billedUnit.numhcpcsunits !== null && this.billedUnit.numhcpcsunits !== undefined) {
        price = ndcPrice * this.billedUnit.numhcpcsunits;
      } else {
        price = ndcPrice;
      }
    }
    return price.toFixed(2);
  }

  public getFSPricing() {
    const jsonData = {
      r_id: this.rcId,
      ndc: this.selectedNdcCode
    };
    this.unitConversionService.getFSPricing(jsonData).subscribe(response => {
      if (response !== null && response['fsPrices'] !== null) {
        this.fsPrices = response['fsPrices'];
        this.cdr.detectChanges();
      } else if (response !== null && response['fsPrices'] === null) {
        this.fsPricesStatus = false
      }   
      
      if (response !== null && (response['status'] === 4 || response['status'] === '4' || this.fsPrices.length === 0)) {
        this.fsPricesStatus = true
      } else {
        this.fsPricesStatus = false
      }
    });
  }

  public getFNDetails() {
    if (this.isFN) {
      let tempCodeNav = sessionStorage.getItem('codeNavigator') ? sessionStorage.getItem('codeNavigator') : ''
      if (tempCodeNav && tempCodeNav.toString() !== "null" && tempCodeNav.toString() !== "undefined") {
        this.codeNavigator = JSON.parse(tempCodeNav)
      } else {
        this.codeNavigator = null
      }

      let tempNDCNav = sessionStorage.getItem('ndcNavigator') ? sessionStorage.getItem('ndcNavigator') : ''
      if (tempNDCNav && tempNDCNav.toString() !== "null" && tempNDCNav.toString() !== "undefined") {
        this.ndcNavigator = JSON.parse(tempNDCNav)
      } else {
        this.ndcNavigator = null
      }
    }
  }

  public getIconDetails(data, status) {
    this.iconDetails = data
    this.iconDetails['status'] = status
    this.modalService.open(
      this.navIconDetails,
      { windowClass: 'WastageModalContainer', size: 'lg' }
    )
  }

  public getFNLegends() {
    if (!sessionStorage.getItem('navigatorLegends') || sessionStorage.getItem('navigatorLegends') == 'undefined') {
      let siteCode = JSON.parse(sessionStorage.getItem("config")).FNSiteCode
      let jsonBody = { "siteCode": siteCode }
      this.unitConversionService.getFNDetails(jsonBody).subscribe(res => {
        this.navigatorLegends = []
        res['restrictionFields'].map(data => {       
          if(data['imageFileName'].includes('/')) {
            data['imageFileName'] = data['imageFileName'].split('/')[1]
          }
          this.navigatorLegends.push(data)
        })
        res['tierFields'].map(data => {          
          if(data['imageFileName'].includes('/')) {
            data['imageFileName'] = data['imageFileName'].split('/')[1]
          }
          this.navigatorLegends.push(data)
        })
      })
    } else {
      this.navigatorLegends = JSON.parse(sessionStorage.getItem('navigatorLegends'))
    }
  }
}

