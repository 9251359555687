import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bcbstn-contact',
  templateUrl: './bcbstn-contact.component.html',
  styleUrls: ['./bcbstn-contact.component.scss']
})
export class BcbstnContactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','10670');
  }

}
