import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-unauth-header',
  templateUrl: './unauth-header.component.html',
  styleUrls: ['unauth-header.component.scss']
})
export class UnauthHeaderComponent implements OnInit {

  public isWhiteLabel: boolean = sessionStorage.getItem('whiteLabel') ? true : false;
  public orgId: string = sessionStorage.getItem('whiteLabel') ? sessionStorage.getItem('whiteLabel') : '';
  public isLogin: boolean = false;
  
  constructor(
    private router: Router
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        let url = event.url
        if (url.includes('access-denied-rcca') || url.includes('invalid-signature-rcca')) {
          this.isLogin = true
        }
        else {
          this.isLogin = false
        }
      }

      if (event instanceof NavigationEnd) {
        let url = event.url
        if (url.includes('access-denied-rcca') || url.includes('invalid-signature-rcca')) {
          this.isLogin = true
        }
        else {
          this.isLogin = false
        }
      }
    })
  }

  public ngOnInit(): void { }

  navigateContact() {
    this.isWhiteLabel = sessionStorage.getItem('whiteLabel') ? true : false;
    this.orgId = sessionStorage.getItem('whiteLabel') ? sessionStorage.getItem('whiteLabel') : '';
    console.log(this.isWhiteLabel)
    if (this.isWhiteLabel) {
      if (this.orgId === '11019') {
        this.router.navigate(['/contact-aetnava-white-label']);
      } else if (this.orgId === '666') {
        this.router.navigate(['/contact-cfhp-white-label']);
      } else if (this.orgId === '4480') {
        this.router.navigate(['/contact-rcca-white-label']);
      } else if (this.orgId === '10860') {
        this.router.navigate(['/contact-rcca-lite-white-label']);
      } else if (this.orgId === '10670') {
        this.router.navigate(['/contact-bcbstn-white-label']);
      } else {
        this.router.navigate(['/contactus'])
      }
    } else {
      this.router.navigate(['/contactus'])
    }
  }

  navigateLogin() {
    this.isWhiteLabel = sessionStorage.getItem('whiteLabel') ? true : false;
    this.orgId = sessionStorage.getItem('whiteLabel') ? sessionStorage.getItem('whiteLabel') : '';
    console.log(this.isWhiteLabel)
    if (this.isWhiteLabel) {
      if (this.orgId === '11019') {
        this.router.navigate(['/login-form-rcca-aetnava-white-label']);
      } else if (this.orgId === '4480') {
        this.router.navigate(['/login-form-white-label']);
      } else if (this.orgId === '666') {
        this.router.navigate(['/login-form-rc-claim-assist-cfhp']);
      } else if (this.orgId === '10860') {
        this.router.navigate(['/login-form-rcca-lite-white-label']);
      } else if (this.orgId === '10670') {
        this.router.navigate(['/login-form-rcca-lite-bcbstn-white-label']);
      } else {
        this.router.navigate(['/login'])
      }
    } else {
      this.router.navigate(['/login'])
    }
  }

  navigateRegister() {
    this.isWhiteLabel = sessionStorage.getItem('whiteLabel') ? true : false;
    this.orgId = sessionStorage.getItem('whiteLabel') ? sessionStorage.getItem('whiteLabel') : '';
    console.log(this.isWhiteLabel)
    if (this.isWhiteLabel) {
      if (this.orgId === '11019') {
        this.router.navigate(['/pre-registration-form-rcca-npi-aetnava-white-label']);
      } else if (this.orgId === '4480') {
        this.router.navigate(['/pre-registration-form-npi-white-label']);
      } else if (this.orgId === '666') {
        this.router.navigate(['/pre-registration-form-rcca-npi-cfhp-white-label']);
      } else if (this.orgId === '10860') {
        this.router.navigate(['/pre-registration-form-rcca-lite-npi-white-label']);
      } else if (this.orgId === '10670') {
        this.router.navigate(['/pre-registration-form-rcca-lite-npi-bcbstn-white-label']);
      } else {
        this.router.navigate(['/register'])
      }
    } else {
      this.router.navigate(['/register'])
    }
  }

}
