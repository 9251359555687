import {
  Component,
  OnInit,
  ViewChild,
  Pipe,
  PipeTransform,
} from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeStyle,
  SafeScript,
  SafeUrl,
  SafeResourceUrl,
} from '@angular/platform-browser';

@Pipe({ name: 'safeHtml' })
export class Safe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  // tslint:disable-next-line: no-any
  public transform(html:any) :any {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}

