import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class CheckAuthService {
  public url: string = '';
  constructor(public http: HttpClient) { }

  public loadConfig(url: string) {
    this.url = url
    return this.http.head(this.url, {observe: "response"});
  }
}
