import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unauth-footer',
  templateUrl: './unauth-footer.component.html',
  styleUrls: ['./unauth-footer.component.scss']
})
export class UnauthFooterComponent implements OnInit {
  @Input() isContact: boolean = false;
  public showFooterContact: boolean = false;
  public showFooterFaq: boolean = false;
  public isWhiteLabel: boolean = false;
  public currentYear: number;
  public orgId: string = sessionStorage.getItem('whiteLabel') ? sessionStorage.getItem('whiteLabel') : '';

  constructor(
    private router: Router
  ) { }

  public ngOnInit(): void {
    this.isWhiteLabel = sessionStorage.getItem('whiteLabel') ? true : false;
    this.currentYear = (new Date()).getFullYear();
    this.setFooterFlag();
  }

  public setFooterFlag(): void {
    if (this.isContact === true) {
      this.showFooterContact = false;
      this.showFooterFaq = true;
    }
    else {
      this.showFooterContact = true;
      this.showFooterFaq = false;
    }
  }
  navigateContact() {
    console.log(this.isWhiteLabel)
    if (this.isWhiteLabel) {
      if (this.orgId === '11019') {
        this.router.navigate(['/contact-aetnava-white-label']);
      } else if (this.orgId === '666') {
        this.router.navigate(['/contact-cfhp-white-label']);
      } else if (this.orgId === '4480') {
        this.router.navigate(['/contact-rcca-white-label']);
      } else if (this.orgId === '10860') {
        this.router.navigate(['/contact-rcca-lite-white-label']);
      } else if (this.orgId === '10670') {
        this.router.navigate(['/contact-bcbstn-white-label']);
      } else {
        this.router.navigate(['/contactus'])
      }
    } else {
      this.router.navigate(['/contactus'])
    }
  }
}
