import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { HcpcSearchService } from './hcpc-search.service';
import { PagerService } from '../../shared/pagination/pager.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NavService } from '../../shared/services/commonServices';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DrugInfoComponent } from '../../../app/main/layout/drug-info/drug-info.component';
import { AuthorizationServices } from '../../shared/services/authorizationServices';
import { GlobalSearchViews } from '../../shared/models/global-search-views.data.model';
import { NdcSearchService } from '../ndc-search/ndc-search.service';
import { EnvironmentData } from 'src/app/shared/models/environment.data.model';
// tslint:disable-next-line: no-any
declare var $: any;
@Component({
  selector: 'app-hcpc-search',
  templateUrl: './hcpc-search.component.html',
  styleUrls: ['./hcpc-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HcpcSearchComponent implements OnInit {
  public selectedHcpcCodeIsNoc: boolean | string;
  public usertypedValue: string;
  public selectedHcpcCodeDescr: string;
  public selectedHcpcCode: string;
  public isFirstTimeLoad: boolean = true;
  public isFromUnitConversion: string = '';
  public searchTextChanged: Subject<string> = new Subject<string>();
  public isBegningNDC: string = 'false';
  public showErrorMesage: boolean = false;
  public errorMessage: string;
  public hcpcsCodes: GlobalSearchViews[] = [];
  public model: { hcpccode: string } = { hcpccode: null };
  public isResultFound: boolean = false;
  public pageindex: number;
  // tslint:disable-next-line: no-any
  public pager: any = {};

  public pagedItems: GlobalSearchViews[] = [];

  public display: string = 'none';

  public isFromNdc: string = 'false';
  public selectedNdcCode: string;
  public selectedNdcCodeDescr: string;
  public reImbCode: string;
  public rcdescr: string;
  public isFromNdcOnPreviousClick: string = '';
  public selectedHcpcCodeOnHcpcSearch: string;
  public selectedHcpcDescrOnHcpcSearch: string;
  public UsertypedValueOnHcpc: string = '';
  public userTypedValueOnNdc: string = null;
  public selectedHcpcCodefromHcpc: string;
  public selectedHcpcDescrfromHcpc: string;
  public rcId: string = null;
  public accountId: number;
  public showNdcDrugNameSection: boolean;
  public ndcStatus: string = null;
  public hcpcStatus: string = null;
  public hideHcpcFooter: boolean = false;
  public pageName: string;
  public ndcManufacturer: string;
  public isRotate: boolean = false;
  public termDate: string;
  public responseMessage: string;
  public mySubscription: Subscription
  public isLoading: boolean = false
  public isFN: boolean = false
  public config: EnvironmentData[] = [];

  @ViewChild(DrugInfoComponent, { static: false }) public appDrug: DrugInfoComponent;
  constructor(private data: HcpcSearchService,
    private pagerService: PagerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private navsvc: NavService,
    private ndcSearchService: NdcSearchService,
    private authService: AuthorizationServices,
    private cdr: ChangeDetectorRef) { }

  public ngOnInit(): void {
    this.pageName = 'hcpc';
    this.usertypedValue = '';
    this.model.hcpccode = '';
    this.isResultFound = false;
    this.decideFeature();
    this.config = JSON.parse(sessionStorage.getItem('config'));
    console.log(this.config)
    this.ndcManufacturer = this.activatedRoute.snapshot.queryParamMap.get('ndcManufacturer');
    this.isFromNdcOnPreviousClick = this.activatedRoute.snapshot.queryParamMap.get('isFromNdcOnPreviousClick');
    this.isFromNdc = this.activatedRoute.snapshot.queryParamMap.get('isFromNdc');
    this.isFromUnitConversion = this.activatedRoute.snapshot.queryParamMap.get('isFromUnitConversion');
    this.userTypedValueOnNdc = this.activatedRoute.snapshot.queryParamMap.get('userTypedValue');
    this.selectedHcpcCodefromHcpc = this.activatedRoute.snapshot.queryParamMap.get('selectedHcpcCodefromHcpc');
    this.selectedHcpcDescrfromHcpc = this.activatedRoute.snapshot.queryParamMap.get('selectedHcpcDescrfromHcpc');

    this.selectedHcpcCodeOnHcpcSearch = this.activatedRoute.snapshot.queryParamMap.get('hcpccode');
    this.selectedHcpcDescrOnHcpcSearch = this.activatedRoute.snapshot.queryParamMap.get('hcpcdescr');
    this.accountId = JSON.parse(sessionStorage.getItem('accountDetails')).account_id;
    this.ndcStatus = this.activatedRoute.snapshot.queryParamMap.get('status');
    this.termDate = this.activatedRoute.snapshot.queryParamMap.get('termDate');

    if (this.isFromNdc === 'true' || this.isFromNdcOnPreviousClick) {
      this.isFirstTimeLoad = false;
      this.pageName = 'hcpcFromNdc';
      this.rcId = this.activatedRoute.snapshot.queryParamMap.get('rcId');
      if (this.isFromNdcOnPreviousClick) this.pageName = 'hcpc';

      this.selectedHcpcCodeIsNoc = this.activatedRoute.snapshot.queryParamMap.get('isnoc') === 'Y';
      this.isBegningNDC = this.activatedRoute.snapshot.queryParamMap.get('isBegningNDC ');
      this.selectedNdcCode = this.activatedRoute.snapshot.queryParamMap.get('ndccode');
      this.selectedNdcCodeDescr = this.activatedRoute.snapshot.queryParamMap.get('ndcdescr');
      this.reImbCode = this.activatedRoute.snapshot.queryParamMap.get('reImbCode');
      this.rcdescr = this.activatedRoute.snapshot.queryParamMap.get('rcdescr');
      this.hcpcStatus = this.activatedRoute.snapshot.queryParamMap.get('rcstatus');
      this.model.hcpccode = this.activatedRoute.snapshot.queryParamMap.get('reImbCode');
      if (this.isFromNdcOnPreviousClick) {
        this.model.hcpccode = this.activatedRoute.snapshot.queryParamMap.get('userTypedValue');
      }
      this.getListOfJcodeValue(this.model.hcpccode);
    }
    else if (this.isFromUnitConversion === 'true') {
      this.isFirstTimeLoad = false;
      this.pageName = 'hcpcFromNdc';
      this.selectedNdcCode = this.activatedRoute.snapshot.queryParamMap.get('ndccode');
      this.selectedNdcCodeDescr = this.activatedRoute.snapshot.queryParamMap.get('ndcdescr');
      this.reImbCode = this.activatedRoute.snapshot.queryParamMap.get('reImbCode');
      this.rcdescr = this.activatedRoute.snapshot.queryParamMap.get('rcdescr');
      this.hcpcStatus = this.activatedRoute.snapshot.queryParamMap.get('hcpcStatus');
      this.ndcStatus = this.activatedRoute.snapshot.queryParamMap.get('status');

    }
    this.isSessionActive();
    this.searchTextChanged.pipe(debounceTime(1000))
      .subscribe((x) => this.getListOfJcodeValue(x));

    if (!this.cdr['destroyed']) this.cdr.detectChanges();
  }

  public callOnKeyUp(e: Event): void {
    if ((e['which'] < 48 || e['which'] > 105) && e['which'] !== 8 && e['which'] !== 16) {
      return;
    }
    if (e['which'] === 91 || e['which'] === 92 || e['which'] === 93) {
      return;
    }
    this.hcpcsCodes = [];
    this.pagedItems = [];
    this.pager = {};
    this.isResultFound = false;
    this.selectedNavItem(1);
    if (this.model.hcpccode.length >= 3) {
      this.searchTextChanged.next(e.target['value']);
      if (!this.cdr['destroyed']) this.cdr.detectChanges();
    }
    else {
      return;
    }
    if (!this.cdr['destroyed']) this.cdr.detectChanges();
  }
  public selectedNavItem(item: number): void {
    if (!this.hideHcpcFooter) {
      this.navsvc.changeNav(item);
    }
  }

  public decideFeature(): void {
    var globalFeatures = JSON.parse(sessionStorage.getItem('accountDetails')).features;
    var featureCode = globalFeatures.split(',');
    for (let value of featureCode) {
      if (value === 'ON') {
        this.hideHcpcFooter = true;
      }
      if (value === 'FN') {
        this.isFN = true
        sessionStorage.removeItem('codeNavigator')
      }
    }
  }
  public getListOfJcodeValue(x: string): void {
    this.usertypedValue = this.model.hcpccode;
    this.data.getJcodeResponse(this.accountId, x).subscribe(data => {
      let status = data.status
      if (status === 200) {
        if (data.body === null) {
          this.isResultFound = true;
          this.responseMessage = 'No results returned for this search.'
          if (!this.cdr['destroyed']) this.cdr.detectChanges();
          return;
        } else {
          this.isResultFound = false
          this.responseMessage = ""
          this.filterUnwantedData(Object.assign([], data.body));
        }
        // this.filterUnwantedData(Object.assign([], data.body));
      } else if (status === 204) {
        this.isResultFound = true
        this.responseMessage = "No results returned for this search."
      } else if (status === 205) {
        this.isResultFound = true
        this.responseMessage = "Search Results are not managed by this Organization."
      }
    },
      error => {
        this.isResultFound = true;
      });
  }


  public clearModel(): void {
    this.isRotate = !this.isRotate;
    this.model.hcpccode = '';
    this.hcpcsCodes = [];
    this.pagedItems = [];
    this.pager = {};
    this.isResultFound = false;
    this.usertypedValue = null;
  }

  public filterUnwantedData(hcpcsCodesList: GlobalSearchViews[]): void {

    if (hcpcsCodesList !== undefined) {
      if (this.isFirstTimeLoad || this.isFromNdcOnPreviousClick === 'true') {
        this.selectedNavItem(1);
      }
      this.hcpcsCodes = [];
      for (let obj of hcpcsCodesList) {
        if (obj.reimb_code && (obj.rc_descr || obj.rc_desc)) {
          this.hcpcsCodes.push(obj);
          if (!this.cdr['destroyed']) this.cdr.detectChanges();
        }
      }

      this.hcpcsCodes = this.removeDuplicate(this.hcpcsCodes);
      this.hcpcsCodes.sort((a, b) => a.reimb_code.localeCompare(b.reimb_code));
      this.hcpcsCodes.sort((a, b) => a.sort_order.localeCompare(b.sort_order));
      this.isResultFound = !(this.hcpcsCodes.length > 0);
      if (!this.cdr['destroyed']) this.cdr.detectChanges();
      this.setPage(1);
    } else {
      this.isResultFound = true;
    }

  }

  public setHcpcCode(index: number): void {
    this.isLoading = true
    if (this.isFirstTimeLoad === true || this.isFromNdcOnPreviousClick) {
      this.selectedHcpcCode = this.pagedItems[index].reimb_code;
      this.selectedHcpcCodeDescr = this.pagedItems[index].rc_descr;
      this.selectedHcpcCodeIsNoc = this.pagedItems[index].is_noc;
      this.isFirstTimeLoad = false;
      let navigationExtras: NavigationExtras = {
        queryParams: {
          ndcManufacturer: this.ndcManufacturer,
          hcpccode: this.selectedHcpcCode,
          hcpcdescr: this.selectedHcpcCodeDescr,
          isnoc: this.selectedHcpcCodeIsNoc,
          isFromHCpc: 'true',
          rcId: this.pagedItems[index].rc_id,
          usertypedValue: this.usertypedValue,
          hcpcStatus: this.pagedItems[index].rc_status,
          termDate: this.termDate,
        },
      };
      let jsonBody = { "siteCode": this.config['FNSiteCode'], "reimbursementCode": this.selectedHcpcCode }
      if (this.isFN) {
        this.ndcSearchService.getNDCFNSearch(jsonBody).subscribe(data => {
          if (data && data['coverages']) {
            let navigator = data['coverages'][0]
            this.isLoading = false;
            sessionStorage.setItem('codeNavigator', JSON.stringify(navigator))
            this.router.navigate(['/layout/ndcsearch'], navigationExtras);
          } else {
            this.isLoading = false;
            this.router.navigate(['/layout/ndcsearch'], navigationExtras);
          }
        });
      } else  {
        this.isLoading = false;
        this.router.navigate(['/layout/ndcsearch'], navigationExtras);
      }

    }


    else if (this.isFromNdc === 'true' || this.isFromUnitConversion === 'true') {
      this.selectedHcpcCode = this.reImbCode;
      this.selectedHcpcCodeDescr = this.rcdescr;
      if (this.rcId === null || this.rcId === undefined) this.rcId = this.activatedRoute.snapshot.queryParamMap.get('rcId');
      if (this.selectedHcpcCodeIsNoc === null || this.selectedHcpcCodeIsNoc === undefined) this.selectedHcpcCodeIsNoc = this.activatedRoute.snapshot.queryParamMap.get('isnoc') === 'Y' || this.activatedRoute.snapshot.queryParamMap.get('isnoc') === 'true';
      this.isFirstTimeLoad = false;
      let navigationExtras: NavigationExtras = {
        queryParams: {
          ndcManufacturer: this.ndcManufacturer,
          selectedNdcCode: this.selectedNdcCode,
          selectedNdcCodeDescr: this.selectedNdcCodeDescr,
          hcpccode: this.selectedHcpcCode,
          hcpcdescr: this.selectedHcpcCodeDescr,
          isFromHCpc: 'true',
          rcId: this.rcId,
          status: this.ndcStatus,
          hcpcStatus: this.hcpcStatus,
          usertypedValueonNdc: this.userTypedValueOnNdc,
          termDate: this.termDate,
          isnoc: this.selectedHcpcCodeIsNoc,
        },
      };

      let jsonBody = { "siteCode": this.config['FNSiteCode'], "reimbursementCode": this.selectedHcpcCode }
      if (this.isFN) {
        this.ndcSearchService.getNDCFNSearch(jsonBody).subscribe(data => {
          if (data && data['coverages']) {
            let ndccode = this.selectedNdcCode.replace(/[^0-9]+/g, '');
            let navigator = data['coverages'].filter(obj => {
              if (obj.ndc === ndccode) {
                return obj
              }
            })
            if (navigator.length > 0) sessionStorage.setItem('codeNavigator', JSON.stringify(navigator[0]))
            this.isLoading = false;
            this.router.navigate(['layout/unit-conversion'], navigationExtras);
          } else {
            this.isLoading = false;
            this.router.navigate(['layout/unit-conversion'], navigationExtras);
          }
        });
      } else  {
        this.isLoading = false;
        this.router.navigate(['layout/unit-conversion'], navigationExtras);
      }
      
      
    }
  }

  public setPage(page: number): void {
    if (page < 1) {
      return;
    }
    if (page !== 1 && page > this.pager.totalPages) {
      return;
    }
    this.pageindex = page;
    // get pager object from service
    this.pager = this.pagerService.getPager(this.hcpcsCodes.length, page);

    // get current page of items
    this.pagedItems = this.hcpcsCodes.slice(this.pager.startIndex, this.pager.endIndex + 1);

    if (!this.cdr['destroyed']) this.cdr.detectChanges();
  }

  public redirectToNdcOnPreviousClick(): void {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        userTypedValueOnNdc: this.userTypedValueOnNdc,
        isFromHCpcOnPreviousClick: 'true',
      },
    };
    if (this.isFN) {
      sessionStorage.removeItem('codeNavigator')
    }
    this.router.navigate(['/layout/ndcsearch'], navigationExtras);
  }

  public removeDuplicate(origArr: GlobalSearchViews[]): GlobalSearchViews[] {
    var newArr: GlobalSearchViews[] = [];
    var origLen = origArr.length;
    var found, x, y;

    for (x = 0; x < origLen; x++) {
      found = undefined;
      for (y = 0; y < newArr.length; y++) {
        if (origArr[x].reimb_code === newArr[y].reimb_code &&
          origArr[x].rc_status === newArr[y].rc_status && origArr[x].rc_descr === newArr[y].rc_descr) {
          found = true;
          break;
        }
      }
      if (!found) {
        if (origArr[x] === undefined) {
          continue;
        }
        newArr.push(origArr[x]);
      }
    }
    return newArr;
  }

  public isSessionActive(): void {
    let sessionId;
    if (window.name) {
      sessionId = window.name ? JSON.parse(window.name)['session_id'] : '';
    } else {
      sessionId = sessionStorage.getItem('sessionId') ? sessionStorage.getItem('sessionId') : '';
    }

    if (sessionId) {
      this.authService.checkSession(sessionId).subscribe(response => {
        if (window.name) {
          if (!response.session_id && !JSON.parse(window.name).multi_logins_allowed) {
            this.openModal();
          }
        } else {
          if (!response.session_id && sessionId !== 'multi_logins_allowed') {
            this.openModal();
          }
        }
      }, (err: Response) => {
        if (window.name) {
          if (err['error'].message === 'Unauthorized - Session Does Not Exist' && !JSON.parse(window.name).multi_logins_allowed) {
            this.openModal();
          }
        } else {
          if (err['error'].message === 'Unauthorized - Session Does Not Exist' && sessionId !== 'multi_logins_allowed') {
            this.openModal();
          }
        }
      });
    }
  }

  public openModal(): void {
    this.display = 'block';

  }

  public onCloseHandled(): void {
    this.display = 'none';
    sessionStorage.removeItem('isValidUser');
    sessionStorage.removeItem('response');
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('encKey');
    location.replace(JSON.parse(sessionStorage['config']).amaApplicationUrl);
    sessionStorage.removeItem('config');
  }

}
