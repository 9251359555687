import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bcbstn-forgot-password',
  templateUrl: './bcbstn-forgot-password.component.html',
  styleUrls: ['./bcbstn-forgot-password.component.scss']
})
export class BcbstnForgotPasswordComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','10670');
  }

  navigateForm() {
    this.router.navigate(['/login-form-rcca-lite-bcbstn-white-label']);
  }
}
