import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountDetails } from 'src/app/shared/services/account-details';
import { NavService } from '../../shared/services/commonServices';

// tslint:disable-next-line: no-any
declare var $: any;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  public showAetnaVAContact: boolean = false
  constructor(private _router: Router, private _route: ActivatedRoute, private navsvc: NavService) { }

  public ngOnInit(): void {
    let orgId = JSON.parse(sessionStorage.getItem('accountDetails')).org_id
    let aetnaVaWLOrgId = JSON.parse(sessionStorage.getItem('config')).AetnaVaWLOrgId

    console.log("orgId:", orgId)
    console.log("aetnaVAOrgId:", aetnaVaWLOrgId)

    if (orgId == aetnaVaWLOrgId) {
      this.showAetnaVAContact = true
    }

    this.selectedNavItem(4);
  }
  public selectedNavItem(item: number): void {
    this.navsvc.changeNav(item);
  }

}
