import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-stepwizard',
  templateUrl: './stepwizard.component.html',
  styleUrls: ['./stepwizard.component.scss'],
})
export class StepwizardComponent implements OnInit {
  public labelOne: string;
  public labelTwo: string;
  public labelThree: string;

  public stepOneLabel: string;
  public stepTwoLabel: string;
  public stepThreeLabel: string;
  public stepOneHighlighteClass: string = 'btn btn-circle disabled';
  public stepTwoHighlighteClass: string = 'btn btn-circle disabled';
  public unitConHighlighteClass: string = 'btn btn-circle disabled';

  constructor() {//
  }
  @Input() public pageName: string;

  public ngOnInit(): void {
    if (this.pageName === 'hcpc' || this.pageName === 'ndcFromHcpc' || this.pageName === 'unitConversionFromNdcFromHcpc') {
      this.labelOne = '1';
      this.labelTwo = '2';
      this.labelThree = '3';

      this.stepOneLabel = 'Select HCPCS Code';
      this.stepTwoLabel = 'Select NDC Code';
      this.stepThreeLabel = 'Unit Conversion';

      if (this.pageName === 'hcpc') {
        this.stepOneHighlighteClass = 'btn btn-circle btn-org';
        this.stepTwoHighlighteClass = 'btn btn-default btn-circle disabled';
        this.unitConHighlighteClass = 'btn btn-default btn-circle disabled';
      }
      if (this.pageName === 'ndcFromHcpc') {
        this.stepOneHighlighteClass = 'btn btn-primary btn-circle disabled';
        this.stepTwoHighlighteClass = 'btn btn-circle btn-org';
        this.unitConHighlighteClass = 'btn btn-default btn-circle disabled';
      }
      if (this.pageName === 'unitConversionFromNdcFromHcpc') {
        this.stepOneHighlighteClass = 'btn btn-primary btn-circle disabled';
        this.stepTwoHighlighteClass = 'btn btn-primary btn-circle disabled';
        this.unitConHighlighteClass = 'btn btn-circle btn-org';
      }
    }
    if (this.pageName === 'ndc' || this.pageName === 'hcpcFromNdc' || this.pageName === 'unitConversionFromHcpcFromNdc') {
      this.labelOne = '1';
      this.labelTwo = '2';
      this.labelThree = '3';

      this.stepOneLabel = 'Select NDC Code';
      this.stepTwoLabel = 'Select HCPCS Code';
      this.stepThreeLabel = 'Unit Conversion';

      if (this.pageName === 'hcpcFromNdc') {
        this.stepOneHighlighteClass = 'btn  btn-primary btn-circle disabled';
        this.stepTwoHighlighteClass = 'btn btn-circle btn-org';
        this.unitConHighlighteClass = 'btn  btn-default btn-circle disabled';
      }
      if (this.pageName === 'ndc') {
        this.stepOneHighlighteClass = 'btn btn-circle  btn-org';
        this.stepTwoHighlighteClass = 'btn btn-default btn-circle disabled';
        this.unitConHighlighteClass = 'btn btn-default btn-circle disabled';
      }
      if (this.pageName === 'unitConversionFromHcpcFromNdc') {
        this.stepOneHighlighteClass = 'btn btn-primary btn-circle disabled';
        this.stepTwoHighlighteClass = 'btn btn-primary btn-circle  disabled';
        this.unitConHighlighteClass = 'btn btn-circle btn-org';
      }
    }

    if (this.pageName === 'drug' || this.pageName === 'unitConversionFromNdcFromDrug' || this.pageName === 'ndcFromDrug') {
      this.labelOne = '1';
      this.labelTwo = '2';
      this.labelThree = '3';

      this.stepOneLabel = 'Select Drug Code';
      this.stepTwoLabel = 'Select NDC Code';
      this.stepThreeLabel = 'Unit Conversion';

      if (this.pageName === 'drug') {
        this.stepOneHighlighteClass = 'btn btn-circle btn-org';
        this.stepTwoHighlighteClass = 'btn btn-default btn-circle disabled';
        this.unitConHighlighteClass = 'btn btn-default btn-circle disabled';
      }
      if (this.pageName === 'ndcFromDrug') {
        this.stepOneHighlighteClass = 'btn btn-primary btn-circle disabled';
        this.stepTwoHighlighteClass = 'btn btn-circle btn-org ';
        this.unitConHighlighteClass = 'btn btn-default btn-circle disabled';
      }
      if (this.pageName === 'unitConversionFromNdcFromDrug') {
        this.stepOneHighlighteClass = 'btn btn-primary btn-circle disabled';
        this.stepTwoHighlighteClass = 'btn btn-primary btn-circle disabled';
        this.unitConHighlighteClass = 'btn btn-circle btn-org';
      }
    }
  }


}
