import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Renderer2,
	ElementRef,
} from '@angular/core';
import { AccountOutput } from 'src/app/shared/models/account-output.data.model';
import { AuthorizationServices } from '../../shared/services/authorizationServices';
import { Logger } from '../../shared/services/logger.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '../../shared/services/toast.service';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ResponseData } from '../../shared/models/response.data.model';
import { EnvironmentData } from '../../shared/models/environment.data.model';
import { Router } from '@angular/router';

@Component({
	selector: 'app-password',
	templateUrl: './password.component.html',
	styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
	public error: string;
	public passwordUpdateForm: FormGroup;
	public oldPassword: string = undefined;
	public newPassword: string;
	public reEnteredPassword: string;
	public accountDetails: AccountOutput;
	public isSuccess: boolean = true;
	public showMessage: boolean = true;
	public message: string;
	public filledFormSubmitted: boolean = false;
	public showAjaxLoader: boolean = false;
	public headers: HttpHeaders = null;
	public config: EnvironmentData;
	public ssoFlag: string = '';

	constructor(
		private cdr: ChangeDetectorRef,
		private authService: AuthorizationServices,
		private logger: Logger,
		private formBuilder: FormBuilder,
		private toastService: ToastService,
		private renderer: Renderer2,
		private inputEmpty: ElementRef,
		private _http: HttpClient,
		private router: Router,
	) {
		const i = 0;
	}

	public ngOnInit(): void {
		this.accountDetails = JSON.parse(sessionStorage.getItem('accountDetails'));
		this.passwordUpdateFormControl();
		this.onChanges();
		this.showSuccess("test")
		this.ssoFlag = this.accountDetails['sso'];
	}

	private passwordUpdateFormControl(): void {
		this.passwordUpdateForm = this.formBuilder.group({
			oldPassword: ['',
				Validators.compose([Validators.required, Validators.maxLength(50)])],
			newPassword: ['',
				Validators.compose([Validators.required, Validators.maxLength(50)])],
			reEnteredPassword: ['',
				Validators.compose([Validators.required, Validators.maxLength(50)])],
		});
		if (!this.cdr['destroyed']) this.cdr.detectChanges();
	}

	public onChanges(): void {
		this.passwordUpdateForm.valueChanges.subscribe(val => {
			if (val) {
				this.filledFormSubmitted = true;
			} else {
				return;
			}
		});
	}

	public saveResetPassword(): boolean {
		this.showMessage = false;
		if (this.ssoFlag === 'Y') {
			alert('Password reset is disabled for SSO users');
		} else if (this.passwordUpdateForm.invalid) {
			let myTag = this.inputEmpty.nativeElement.querySelectorAll('input');
			myTag.forEach(element => {
				element.classList.add('invalid');
			});
			this.cdr.detectChanges()

		} else {
			if (this.passwordUpdateForm.valid && this.filledFormSubmitted === true) {
				this.oldPassword = this.passwordUpdateForm.get('oldPassword').value;
				this.newPassword = this.passwordUpdateForm.get('newPassword').value;
				this.reEnteredPassword = this.passwordUpdateForm.get('reEnteredPassword').value;
				if (this.oldPassword !== undefined) {
					if (this.newPassword !== undefined) {
						if (this.reEnteredPassword !== undefined) {
							if (this.newPassword === this.oldPassword) {
								this.isSuccess = false;
								this.showMessage = true;
								this.message = 'Current password and new password must be different';
								const element = this.renderer.selectRootElement('#newPassword');
								setTimeout(() => element.focus(), 0);
								return false;
							}
							if (this.newPassword !== this.reEnteredPassword) {
								this.isSuccess = false;
								this.showMessage = true;
								this.message = 'New password and re-entered password must be the same';
								const element = this.renderer.selectRootElement('#reEnteredPassword');
								setTimeout(() => element.focus(), 0);
								return false;
							} else {
								this.showAjaxLoader = true;
								const updatePassword = {
									current_password: this.oldPassword,
									new_password: this.newPassword,
								};
								this.resetPassword(updatePassword).subscribe(res => {
									this.showAjaxLoader = false;
									this.showSuccess('Password Updated Successfully!');
									this.getAccountDetails();
									this.filledFormSubmitted = false;
									this.passwordUpdateForm.reset()
									let myTag = this.inputEmpty.nativeElement.querySelectorAll('input');
									myTag.forEach(element => {
										element.classList.remove('invalid');
									});
									if (!this.cdr['destroyed']) this.cdr.detectChanges();
									this.showMessage = false;
									alert("Password reset successful!")
									this.router.navigate(['/layout/search']);
								}, (err: Response) => {
									this.showAjaxLoader = false;
									this.logger.error(err['error'].message);
									this.isSuccess = false;
									this.showMessage = true;
									if (err.status === 400) {
										var message = err['error'].message;
										if (message.length > 14) {
											message = message.substring(14);
										}
										if (message.toLowerCase().startsWith('invalid details')) {
											this.message = 'Current password is incorrect';
										} else {
											this.message = message;
										}
									} else if (err.status === 403) {
										this.message = 'Invalid Session';
									} else {
										this.message = 'Unknown Error';
									}
									const element = this.inputEmpty.nativeElement.querySelector('#oldPassword');
									element.focus();
									element.classList.add('invalid');
									return false;
								});

							}
						}
						else {
							return false;
						}
					}
					else {
						return false;
					}
				}
				else {
					return false;
				}
			}
		}
	}

	public getAccountDetails(): void {
		const accountId = this.accountDetails.account_id.toString();
		this.authService.getAccountDetails(accountId).subscribe(res => {
			sessionStorage.setItem('accountDetails', JSON.stringify(res[0]));
			if (!this.cdr['destroyed']) this.cdr.detectChanges();
		}, (err: Response) => {
			this.logger.error(err['error'].message);
		});
	}

	public showDanger(dangerTpl: string): void {
		this.toastService.show(dangerTpl, { classname: 'bg-danger text-light', delay: 3000 });
	}

	public showSuccess(successMessageLayoutOption: string): void {
		this.toastService.show(successMessageLayoutOption, { classname: 'bg-success text-light', delay: 3000 });
	}

	private setHeaders(): HttpHeaders {
		this.config = JSON.parse(sessionStorage.getItem('config'));
		let apiKey = sessionStorage.getItem('ak')
		return this.headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('x-api-key', apiKey)
			.set('Authorization', sessionStorage.getItem('uid') ? sessionStorage.getItem('uid') : this.config.Authorization);
	}

	public resetPassword(jsonData: object): Observable<ResponseData> {
		let headers = this.setHeaders();
		return this._http.post(this.config.ServiceUrl + '/resetPassword', jsonData, { headers });
	}
}
