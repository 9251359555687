import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cfhp-contact',
  templateUrl: './cfhp-contact.component.html',
  styleUrls: ['./cfhp-contact.component.scss']
})
export class CfhpContactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','666');
  }

}
