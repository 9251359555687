import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './main/about/about.component';
import { ContactComponent } from './main/contact/contact.component';
import { DrugNameSearchComponent } from './main/drug-name-search/drug-name-search.component';
import { FaqComponent } from './main/faq/faq.component';
import { HcpcSearchComponent } from './main/hcpc-search/hcpc-search.component';
import { LayoutComponent } from './main/layout/layout.component';
import { SessiontimeoutComponent } from './main/layout/sessiontimeout/sessiontimeout.component';
import { NdcSearchComponent } from './main/ndc-search/ndc-search.component';
import { PasswordComponent } from './main/password/password.component';
import { SearchComponent } from './main/search/search.component';
import { UnauthFaqComponent } from './main/unauth/unauth-faq/unauth-faq.component';
import { ForgotPasswordComponent } from './main/unauth/forgot-password/forgot-password.component';
import { LoginComponent } from './main/unauth/login/login.component';
import { NpiValidateComponent } from './main/unauth/npi-validate/npi-validate.component';
import { PrivacyPolicyComponent } from './main/unauth/privacy-policy/privacy-policy.component';
import { RegisterComponent } from './main/unauth/register/register.component';
import { TermsAndConditionsComponent } from './main/unauth/terms-and-conditions/terms-and-conditions.component';
import { UnauthAboutComponent } from './main/unauth/unauth-about/unauth-about.component';
import { UnauthContactComponent } from './main/unauth/unauth-contact/unauth-contact.component';
import { UnauthComponent } from './main/unauth/unauth.component';
import { UnitConversionComponent } from './main/unit-conversion/unit-conversion.component';
import { AetnaVaContactComponent } from './main/wlunauth/aetnaVA/aetna-va-contact/aetna-va-contact.component';
import { AetnaVALoginComponent } from './main/wlunauth/aetnaVA/aetna-va-login/aetna-va-login.component';
import { AetnaVAPreRegisterComponent } from './main/wlunauth/aetnaVA/aetna-va-pre-register/aetna-va-pre-register.component';
import { AetnaVARegisterComponent } from './main/wlunauth/aetnaVA/aetna-va-register/aetna-va-register.component';
import { BcbstnContactComponent } from './main/wlunauth/bcbstn/bcbstn-contact/bcbstn-contact.component';
import { BcbstnForgotPasswordComponent } from './main/wlunauth/bcbstn/bcbstn-forgot-password/bcbstn-forgot-password.component';
import { BcbstnLoginComponent } from './main/wlunauth/bcbstn/bcbstn-login/bcbstn-login.component';
import { BcbstnPreRegisterComponent } from './main/wlunauth/bcbstn/bcbstn-pre-register/bcbstn-pre-register.component';
import { BcbstnRegisterComponent } from './main/wlunauth/bcbstn/bcbstn-register/bcbstn-register.component';
import { BlueCrossContactComponent } from './main/wlunauth/blue-cross/blue-cross-contact/blue-cross-contact.component';
import { BlueCrossForgotPasswordComponent } from './main/wlunauth/blue-cross/blue-cross-forgot-password/blue-cross-forgot-password.component';
import { BlueCrossLoginComponent } from './main/wlunauth/blue-cross/blue-cross-login/blue-cross-login.component';
import { BlueCrossPreRegisterComponent } from './main/wlunauth/blue-cross/blue-cross-pre-register/blue-cross-pre-register.component';
import { BlueCrossRegisterComponent } from './main/wlunauth/blue-cross/blue-cross-register/blue-cross-register.component';
import { CfhpContactComponent } from './main/wlunauth/cfhp/cfhp-contact/cfhp-contact.component';
import { CfhpForgotPasswordComponent } from './main/wlunauth/cfhp/cfhp-forgot-password/cfhp-forgot-password.component';
import { CfhpLoginComponent } from './main/wlunauth/cfhp/cfhp-login/cfhp-login.component';
import { CfhpPreRegisterComponent } from './main/wlunauth/cfhp/cfhp-pre-register/cfhp-pre-register.component';
import { CfhpRegisterComponent } from './main/wlunauth/cfhp/cfhp-register/cfhp-register.component';
import { MedicaidContactComponent } from './main/wlunauth/north-dakota-medicaid/medicaid-contact/medicaid-contact.component';
import { MedicaidForgotPasswordComponent } from './main/wlunauth/north-dakota-medicaid/medicaid-forgot-password/medicaid-forgot-password.component';
import { MedicaidLoginComponent } from './main/wlunauth/north-dakota-medicaid/medicaid-login/medicaid-login.component';
import { MedicaidPreRegisterComponent } from './main/wlunauth/north-dakota-medicaid/medicaid-pre-register/medicaid-pre-register.component';
import { MedicaidRegisterComponent } from './main/wlunauth/north-dakota-medicaid/medicaid-register/medicaid-register.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { AetnaVaForgotPasswordComponent } from './main/wlunauth/aetnaVA/aetna-va-forgot-password/aetna-va-forgot-password.component';
import { AccessDeniedComponent } from './main/unauth/access-denied/access-denied.component';
import { InvalidSignatureComponent } from './main/unauth/invalid-signature/invalid-signature.component';

const routes: Routes = [
  {
    path: '',
    component: UnauthComponent,
    children: [
      { path: 'about', component: UnauthAboutComponent },
      { path: 'contactus', component: UnauthContactComponent },
      { path: 'faqs', component: UnauthFaqComponent },
      { path: 'login', component: LoginComponent },
      { path: 'register', component: NpiValidateComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reg-form', component: RegisterComponent },
      { path: 'tandc', component: TermsAndConditionsComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },

      { path: 'login-form-rcca-aetnava-white-label', component: AetnaVALoginComponent },
      { path: 'aetnava-white-label-forgot-password', component: AetnaVaForgotPasswordComponent },
      { path: 'pre-registration-form-rcca-npi-aetnava-white-label', component: AetnaVAPreRegisterComponent },
      { path: 'registration-rcca-aetnava-white-label', component: AetnaVARegisterComponent },
      { path: 'contact-aetnava-white-label', component: AetnaVaContactComponent },
      { path: 'login-form-rcca-lite-bcbstn-white-label', component: BcbstnLoginComponent },
      { path: 'bcbstn-white-label-forgot-password', component: BcbstnForgotPasswordComponent },
      { path: 'pre-registration-form-rcca-lite-npi-bcbstn-white-label', component: BcbstnPreRegisterComponent },
      { path: 'registration-rcca-bcbstn-white-label', component: BcbstnRegisterComponent }, 
      { path: 'contact-bcbstn-white-label', component: BcbstnContactComponent }, 
      { path: 'login-form-white-label', component: BlueCrossLoginComponent },
      { path: 'white-label-forgot-password', component: BlueCrossForgotPasswordComponent }, 
      { path: 'pre-registration-form-npi-white-label', component: BlueCrossPreRegisterComponent },
      { path: 'registration-rcca-white-label', component: BlueCrossRegisterComponent },
      { path: 'contact-rcca-white-label', component: BlueCrossContactComponent },  
      { path: 'login-form-rc-claim-assist-cfhp', component: CfhpLoginComponent },
      { path: 'cfhp-white-label-forgot-password', component: CfhpForgotPasswordComponent },
      { path: 'pre-registration-form-rcca-npi-cfhp-white-label', component: CfhpPreRegisterComponent },
      { path: 'registration-rcca-cfhp-white-label', component: CfhpRegisterComponent },
      { path: 'contact-cfhp-white-label', component: CfhpContactComponent },
      { path: 'login-form-rcca-lite-white-label', component: MedicaidLoginComponent },
      { path: 'north-dakota-medicaid-forgot-password', component: MedicaidForgotPasswordComponent },
      { path: 'pre-registration-form-rcca-lite-npi-white-label', component: MedicaidPreRegisterComponent },
      { path: 'registration-rcca-lite-white-label', component: MedicaidRegisterComponent },  
      { path: 'contact-rcca-lite-white-label', component: MedicaidContactComponent }, 
      { path: 'access-denied-rcca', component: AccessDeniedComponent},
      { path: 'invalid-signature-rcca', component: InvalidSignatureComponent},
      { path: '', component: UnauthAboutComponent },
      // {path: '**', redirectTo: ''}
    ],
  },
  {
    path: 'layout',
    component: LayoutComponent, canActivate: [AuthGuard],
    //component: LayoutComponent,
    children: [
      {
        path: '',
        component: SearchComponent,
      },
      {
        path: 'about',
        component: AboutComponent,
        //loadChildren: 'app/main/about/'
      },
      {
        path: 'faq',
        component: FaqComponent,
      },
      {
        path: 'contact',
        component: ContactComponent,
      },
      {
        path: 'search',
        component: SearchComponent,
      },
      {
        path: 'unit-conversion',
        component: UnitConversionComponent,
      },
      {
        path: 'hcpcsearch',
        component: HcpcSearchComponent,
      },
      {
        path: 'ndcsearch',
        component: NdcSearchComponent,
      },
      {
        path: 'drugnamesearch',
        component: DrugNameSearchComponent,
      },
      {
        path: 'sessiontimeout',
        component: SessiontimeoutComponent,
      },
      {
        path: 'password',
        component: PasswordComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
})
export class AppRoutingModule { }
