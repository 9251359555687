import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'rcca';

  public ngOnInit(): void {
    this.setRoute();
  }
  
  constructor( private router: Router ) { }

  public setRoute(): void {
    let name = sessionStorage.getItem('navigatePath')
    if (!name.includes('#') && !name.includes('?') && name.includes('.com/')) {
      let index = name.lastIndexOf('.com/') + 5
      console.log(index)
      let path2 = name.slice(index)
      console.log("path2: " + path2)
      this.router.navigate([path2])
    }
  }

}
