import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cfhp-pre-register',
  templateUrl: './cfhp-pre-register.component.html',
  styleUrls: ['./cfhp-pre-register.component.scss']
})
export class CfhpPreRegisterComponent implements OnInit {
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','666');
   }


  navigateForm() {
    this.router.navigate(['/registration-rcca-cfhp-white-label']);
  }
}
