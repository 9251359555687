import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bcbstn-pre-register',
  templateUrl: './bcbstn-pre-register.component.html',
  styleUrls: ['./bcbstn-pre-register.component.scss']
})
export class BcbstnPreRegisterComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','10670');
  }

  navigateForm() {
    this.router.navigate(['/registration-rcca-bcbstn-white-label']);
  }
}
