import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
})

export class TermsAndConditionsComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

  public closeWindow(): void {
    window.close();
  }
}
