import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bcbstn-login',
  templateUrl: './bcbstn-login.component.html',
  styleUrls: ['./bcbstn-login.component.scss']
})
export class BcbstnLoginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','10670');
  }

}
