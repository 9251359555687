import { ChangeDetectorRef, Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as LogRocket from 'logrocket';
import { EventEmitter } from 'protractor';
import { AccountOutput } from 'src/app/shared/models/account-output.data.model';
import { EnvironmentData } from 'src/app/shared/models/environment.data.model';
import { AuthorizationServices } from 'src/app/shared/services/authorizationServices';
import { GlobalServiceService } from 'src/app/shared/services/globalServices';
import { Logger } from 'src/app/shared/services/logger.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-wl-login',
  templateUrl: './wl-login.component.html',
  styleUrls: ['./wl-login.component.scss']
})
export class WlLoginComponent implements OnInit {
  // @Output submitData(): EventEmitter;

  public username: string = '';
  public password: string = '';
  public error: boolean = false;
  public errMsg: string = '';
  public success: boolean = false;
  public successMsg: string = '';
  public delMsg: boolean = false;
  public isTerminated: boolean = false;
  public isValid: boolean = false;
  public message: string = '';
  public config: EnvironmentData;
  public encKey: string = '';
  public selectedAccountId: string = '';
  public dateTimePassedByAma: string = new Date().toString();
  public showUserDropdown: boolean = false;
  public display: string = 'none';
  public siteKey: string = '';

  LoginForm = this.fb.group({
    username: [null, Validators.required],
    password: [null, Validators.required],
    recaptcha: [null, Validators.required]
  })

  constructor(
    private router: Router,
    private toastService: ToastService,
    private authService: AuthorizationServices,
    private cdr: ChangeDetectorRef,
    private globalwindowService: GlobalServiceService,
    private logger: Logger,
    private loginService: LoginService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    sessionStorage.setItem('isPageLoadedFirstTime', 'true');
    this.config = JSON.parse(sessionStorage.getItem('config'));
    this.siteKey = sessionStorage.getItem('sk');
  }
  submitData() {
    this.error = false;
    console.log("submitData");
    this.isValid = true;
    this.username = this.LoginForm.controls.username.value;
    this.password = this.LoginForm.controls.password.value;
    console.log(this.username + this.password);
    if (this.username === null || this.username === undefined || this.username.length === 0) {
      this.error = true;
      this.errMsg = 'Username is required.';
      this.isValid = false;
    } else if (this.password === null || this.password === undefined || this.password.length === 0) {
      this.error = true;
      this.errMsg = 'Password is required.';
      this.isValid = false;
    }
    let recaptacha = this.LoginForm.controls.recaptcha;
    if (!(recaptacha !== null && recaptacha !== undefined && recaptacha.status === 'VALID')) {
      this.isValid = false;
      this.error = true;
      this.errMsg = 'Please complete reCaptcha';
    }

    let jsonData =
    {
      'username': this.username,
      'password': this.password
    };
    if (this.isValid) {
      this.success = true;
      this.successMsg = 'Authenticating...'
      this.loginService.getAmaLoginDetails(jsonData).subscribe(res => {
        console.log(res);
        if (res && res[0]['session_id']) {
          if (res[0]['termination_date']) this.checkValidDate(res[0]['termination_date']);
          console.log(this.isTerminated);
          if (this.isTerminated) {
            this.success = false;
            this.successMsg = '';
            this.error = true;
            this.errMsg = 'Your account has been terminated';
          } else {
            sessionStorage.setItem('uid', res[0]['session_id']);
            this.success = true;
            this.successMsg = 'Login Successful';
            this.selectedAccountId = res[0]['account_id'];
            if (res[0]['website_product_name'] === 'RCClaimAssist') {
              this.getAccountDetails(this.selectedAccountId);
            } else {
              this.loginService.rc_encryptPassword(this.password, this.username, res[0]['aa_id'], res);
            }
          }
        } else if (res && res['message']) {
          this.success = false;
          this.successMsg = '';
          this.delMsg = true;
          this.message = res['message'];
        } else {
          this.success = false;
          this.successMsg = '';
          this.error = true;
          this.errMsg = 'Username / Password is incorrect.';
        }

      }, err => {
        console.log(err);
        this.successMsg = '';
        if (err.status === 401 || err.status === '401') {
          this.success = false;
          this.successMsg = '';
          this.delMsg = true;
          console.log(err['error'].message);
          this.message = err['error'].message;
        } else {
          this.error = true;
          this.errMsg = err['error'].message;
        }
      });
    }
  }

  checkValidDate(dateValue: string = null) {
    const dateFilter = new Date(dateValue);
    const currentDate = new Date();
    this.isTerminated = currentDate.getTime() > dateFilter.getTime() ? true : false
  }

  deleteExistingSession() {
    //console.log(this.message.split(':')[1]);
    this.success = false;
    this.errMsg = 'Please Wait';
    this.error = true;
    const session_id = this.message.split(':')[1];
    sessionStorage.setItem('uid', session_id);
    this.authService.deleteSession(session_id).subscribe(res => {
      this.error = false;
      this.delMsg = false;
      this.username = '';
      this.password = '';
    }, err => {
      this.error = true;
      this.errMsg = 'Error in deleting session'
    });
  }

  
  public getAccountDetails(accountId: string): void {
    this.authService.getAccountDetails(accountId).subscribe(res => {
      var loggedInDateTime = new Date(this.dateTimePassedByAma);
      var currentDateTime = new Date(res['current_date_time']);
      loggedInDateTime.setMinutes(loggedInDateTime.getMinutes() + 10);
      if (loggedInDateTime < currentDateTime) {
        this.showDanger('Login token time has expired/Invalid token.');
        return;
      }
      sessionStorage['isValidUser'] = true;
      sessionStorage.setItem('accountDetails', JSON.stringify(res[0]));
      if (!this.cdr['destroyed']) this.cdr.detectChanges();
      this.logRocket(res[0]);
      setTimeout(() => {
        this.router.navigate(['/layout']);
      }, 1000);

    }, (err: Response) => {
      this.showDanger('error in service:' + err['error'].message);

    });
  }

  public logRocket(accountDetails: AccountOutput): void {
    if (!this.config) {
      this.globalwindowService.getConfig().subscribe(res => {
        sessionStorage.setItem('config', JSON.stringify(res));
        this.identifyLogRocket(accountDetails, res['CurrentEnvironment']);
      }, (err: Response) => {
        this.logger.error(err['error'].message);
        this.showDanger('error in service:' + err['error'].message);
      });
    } else {
      this.identifyLogRocket(accountDetails, this.config['CurrentEnvironment']);
    }
  }

  private identifyLogRocket(accountDetails: AccountOutput, env: string): void {
    // only enable LR for UAT env
    if (env === 'UAT') {
      // filter out automated testing user
      if (accountDetails.username !== 'AutoFSUser1') {
        LogRocket.init('rj-health/rccalite');
        LogRocket.identify('rj-health/rccalite', {
          name: accountDetails.username,
          email: accountDetails.email,
        });
      }
    }
  }

  public showDanger(dangerTpl: string): void {
    this.toastService.show(dangerTpl, { classname: 'bg-danger text-light', delay: 15000 });
  }

  public openModal(): void {
    this.display = 'block';
  }

  public onCloseHandled(): void {
    this.display = 'none';
    sessionStorage.clear();
    sessionStorage.removeItem('encKey');
    location.replace(this.config.RCCAUrl);
  }

}
