import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blue-cross-pre-register',
  templateUrl: './blue-cross-pre-register.component.html',
  styleUrls: ['./blue-cross-pre-register.component.scss']
})
export class BlueCrossPreRegisterComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','4480');
   }


  navigateForm() {
    this.router.navigate(['/registration-rcca-white-label']);
  }

}
