import {
  Component, OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Logger } from '../../../shared/services/logger.service';
import { UnitConversionService } from '../../unit-conversion/unit-conversion.service';

@Component({
  selector: 'app-drug-info',
  templateUrl: './drug-info.component.html',
  styleUrls: ['./drug-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrugInfoComponent implements OnInit {
  public billedUnitPkgSizeUom: string;
  public billedUnitwacprice: string;
  public billedUnitawpprice: string;

  @Input() public isFromNdc: string;
  public firstTimeLoadOnHCPC: boolean = true;
  public isFromHCPCS: string;
  public isLoadingFromNdcForDrug: string;
  public isLoadingFromNdc: string;
  public isLoadingFromHcpc: string;
  public isFromUnitConversion: string = '';
  public stepOneLabel: string;
  public stepTwoLabel: string;
  public stepThreeLabel: string;
  public stepOneHighlighteClass: string;
  public stepTwoHighlighteClass: string;
  public unitConHighlighteClass: string;
  public SelectedDesc1: string;
  public SelectedDesc2: string;
  public selectedHcpcCodeFromUnitConversion: string;
  public selectedHcpcDescrFromUnitConversion: string;
  public selectedHcpcCodefromDrug: string;
  public selectedHcpcDescrfromDrug: string;
  public billedUnitnumhcpcsunits: string;
  public checkHcpcCodeIsNoc: boolean;
  public screenWidth: number;
  public openPopover: string;
  public unitdec: number;
  public ndcNavigator: any = [];
  public codeNavigator: any = [];
  public isFN: boolean = false;
  @Input() public IsNoc: boolean;
  @Input() public showBillableUnitSection: boolean;
  @Input() public showPricingSection: boolean;
  @Input() public isPT: boolean;
  @Input() public isCC: boolean;
  @Input() public isCE: boolean;
  @Input() public isCG: boolean;
  @Input() public isCM: boolean;
  @Input() public isCU: boolean;
  constructor(private activatedRoute: ActivatedRoute,
    private logger: Logger,
    private unitConversionService: UnitConversionService,
    private cdr: ChangeDetectorRef) { }

  @Input() public hcpcStatus: string;
  @Input() public selectedHcpcCodeOnHcpcSearch: string;
  @Input() public selectedHcpcDescrOnHcpcSearch: string;
  @Input() public selectedHcpcCodefromHcpc: string;
  @Input() public selectedHcpcDescrfromHcpc: string;
  @Input() public selectedNdcCode: string;
  @Input() public selectedNdcCodeDesc: string;
  @Input() public ndccode: string;
  @Input() public ndcdescr: string;
  @Input() public selectedDrugCodesDescr: string;
  @Input() public selectedDrugCodes: string;
  @Input() public selectedNdcCodeFromHcpc: string;
  @Input() public selectedNdcCodeDescr: string;
  @Input() public pageName: string;
  @Input() public selectedHcpcCodeOnNdcSearchFromDrug: string;
  @Input() public selectedHcpcDescrOnNdcSearchFromDrug: string;
  @Input() public selectedHcpcCode: string;
  @Input() public selectedHcpcCodeDescr: string;
  @Input() public ndcStatus: string;
  @Input() public status: string;
  @Input() public ndcManufacturer: string;
  @Input() public codeBillableUnits: string;
  @Input() public ndcUnitOfMeasure: string;
  @Input() public ndcPackageType: string;
  @Input() public featureCodeUnitConversionFoundForBillUnit: string;
  @Input() public termDate: string;
  public ngOnInit(): void {

    this.isFromNdc = this.activatedRoute.snapshot.queryParamMap.get('isFromNdc');
    this.ndcManufacturer = this.activatedRoute.snapshot.queryParamMap.get('ndcManufacturer');
    this.selectedHcpcCodeOnHcpcSearch = this.activatedRoute.snapshot.queryParamMap.get('hcpccode');
    this.selectedHcpcDescrOnHcpcSearch = this.activatedRoute.snapshot.queryParamMap.get('hcpcdescr');
    if (this.pageName === 'isFromUnitConversionForDrug') {
      this.selectedHcpcCodeFromUnitConversion = this.activatedRoute.snapshot.queryParamMap.get('selectedHcpcCodeFromUnitConversion');
      this.selectedHcpcDescrFromUnitConversion = this.activatedRoute.snapshot.queryParamMap.get('selectedHcpcDescrFromUnitConversion');
    }
    this.selectedHcpcCodeOnNdcSearchFromDrug = this.activatedRoute.snapshot.queryParamMap.get('selectedHcpcCodeOnNdcSearchFromDrug');
    this.selectedHcpcDescrOnNdcSearchFromDrug = this.activatedRoute.snapshot.queryParamMap.get('selectedHcpcDescrOnNdcSearchFromDrug');
    this.selectedNdcCode = this.activatedRoute.snapshot.queryParamMap.get('ndccode');
    this.selectedNdcCodeDesc = this.activatedRoute.snapshot.queryParamMap.get('ndcdescr');
    this.selectedHcpcCodefromHcpc = this.activatedRoute.snapshot.queryParamMap.get('selectedHcpcCodefromHcpc');
    this.selectedHcpcDescrfromHcpc = this.activatedRoute.snapshot.queryParamMap.get('selectedHcpcDescrfromHcpc');
    this.selectedNdcCodeDescr = this.activatedRoute.snapshot.queryParamMap.get('selectedNdcCodeDescr');
    this.selectedHcpcCodefromDrug = this.activatedRoute.snapshot.queryParamMap.get('selectedHcpcCodefromDrug');
    this.selectedHcpcDescrfromDrug = this.activatedRoute.snapshot.queryParamMap.get('selectedHcpcDescrfromDrug');
    this.termDate = this.activatedRoute.snapshot.queryParamMap.get('termDate');
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 1199) {
      this.openPopover = 'click';
    }
    else {
      this.openPopover = 'mouseenter:mouseleave, click';
    }
    if (this.pageName === 'hcpc' || this.pageName === 'ndcFromHcpc' || this.pageName === 'unitConversionFromNdcFromHcpc') {

      this.stepOneLabel = 'HCPCS Code';
      this.stepTwoLabel = 'NDC Code';
      this.stepThreeLabel = 'Unit Conversion';

      if (this.pageName === 'hcpc') {
        this.SelectedDesc1 = 'Your selected HCPCS/CPT® Code will be displayed here.';
        this.SelectedDesc2 = 'Your selected NDC code will be displayed here';
        this.stepOneHighlighteClass = 'is-active-value is-hcpc';
        this.stepTwoHighlighteClass = 'is-have-value';
        this.unitConHighlighteClass = 'is-have-value';
      }
      if (this.pageName === 'ndcFromHcpc') {
        this.SelectedDesc1 = this.selectedHcpcDescrOnHcpcSearch;
        this.SelectedDesc2 = 'Your selected NDC code will be displayed here';
        this.stepOneHighlighteClass = 'is-have-value is-hcpc';
        this.stepTwoHighlighteClass = 'is-active-value';
        this.unitConHighlighteClass = 'is-have-value';
        this.getFNDetails()
      }
      if (this.pageName === 'unitConversionFromNdcFromHcpc') {
        this.SelectedDesc1 = this.selectedHcpcDescrOnHcpcSearch;

        this.SelectedDesc2 = this.selectedNdcCodeDesc;

        this.stepOneHighlighteClass = 'is-have-value is-hcpc';
        this.stepTwoHighlighteClass = 'is-have-value';
        this.unitConHighlighteClass = 'is-active-value';
        this.getFNDetails()
      }
    }
    if (this.pageName === 'ndc' || this.pageName === 'hcpcFromNdc' || this.pageName === 'unitConversionFromHcpcFromNdc') {

      this.stepOneLabel = 'HCPCS Code';
      this.stepTwoLabel = 'NDC Code';
      this.stepThreeLabel = 'Unit Conversion';

      if (this.pageName === 'ndc') {
        this.SelectedDesc1 = 'Your selected HCPCS/CPT® Code will be displayed here.';
        this.SelectedDesc2 = 'Your selected NDC code will be displayed here';
        this.stepOneHighlighteClass = 'is-have-value';
        this.stepTwoHighlighteClass = 'is-active-value is-ndc';
        this.unitConHighlighteClass = 'is-have-value';
      }
      if (this.pageName === 'hcpcFromNdc') {
        this.SelectedDesc2 = this.selectedNdcCodeDesc;
        this.stepOneHighlighteClass = 'is-have-value';
        this.stepTwoHighlighteClass = 'is-active-value is-ndc';
        this.unitConHighlighteClass = 'is-have-value';
      }
      if (this.pageName === 'unitConversionFromHcpcFromNdc') {
        this.SelectedDesc1 = this.selectedHcpcDescrOnHcpcSearch;
        this.selectedNdcCode = this.activatedRoute.snapshot.queryParamMap.get('selectedNdcCode');
        this.SelectedDesc2 = this.selectedNdcCodeDescr;
        this.stepOneHighlighteClass = 'is-active-value is-ndc';
        this.stepTwoHighlighteClass = 'is-have-value';
        this.unitConHighlighteClass = 'is-have-value';
        this.getFNDetails()
      }
    }

    if (this.pageName === 'drug' || this.pageName === 'unitConversionFromNdcFromDrug' || this.pageName === 'ndcFromDrug') {

      this.stepOneLabel = 'Drug Code';
      this.stepTwoLabel = 'NDC Code';
      this.stepThreeLabel = 'Unit Conversion';

      if (this.pageName === 'drug') {
        this.SelectedDesc1 = 'Your selected drug code will be displayed here.';
        this.SelectedDesc2 = 'Your selected NDC code will be displayed here';
        this.stepOneHighlighteClass = 'is-active-value is-drug';
        this.stepTwoHighlighteClass = 'is-have-value';
        this.unitConHighlighteClass = 'is-have-value';
      }
      if (this.pageName === 'ndcFromDrug') {
        this.SelectedDesc1 = this.selectedHcpcDescrOnHcpcSearch;
        this.SelectedDesc2 = 'Your selected NDC code will be displayed here';
        this.stepOneHighlighteClass = 'is-have-value is-drug';
        this.stepTwoHighlighteClass = 'is-active-value';
        this.unitConHighlighteClass = 'is-have-value';
        this.getFNDetails()
      }
      if (this.pageName === 'unitConversionFromNdcFromDrug') {
        this.SelectedDesc1 = this.selectedHcpcDescrOnHcpcSearch;
        this.SelectedDesc2 = this.selectedNdcCodeDesc;
        this.stepOneHighlighteClass = 'is-have-value is-drug';
        this.stepTwoHighlighteClass = 'is-have-value';
        this.unitConHighlighteClass = 'is-active-value';
        this.getFNDetails()
      }
    }
    this.unitdec = JSON.parse(sessionStorage.getItem("accountDetails")).unitdec;
    let globalFeatures = JSON.parse(sessionStorage.getItem('accountDetails')).features;
    if (globalFeatures.includes('FN')) {
      this.isFN = true
      this.getFNDetails()
    }

  }
  public setData(jsonData: { numhcpcsunits: string, awpprice: string, wacprice: string, pkg_size_uom: string }): void {
    this.billedUnitnumhcpcsunits = jsonData.numhcpcsunits;
    this.billedUnitawpprice = jsonData.awpprice;
    this.billedUnitwacprice = jsonData.wacprice;
    this.billedUnitPkgSizeUom = jsonData.pkg_size_uom;
    if (!this.cdr['destroyed']) this.cdr.detectChanges();
  }

  public getFNDetails() {
    if (this.isFN) {
      let tempCodeNav = sessionStorage.getItem('codeNavigator') ? sessionStorage.getItem('codeNavigator') : ''
      if (tempCodeNav && tempCodeNav.toString() !== "null" && tempCodeNav.toString() !== "undefined") {
        this.codeNavigator = JSON.parse(tempCodeNav)
      } else {
        this.codeNavigator = null
      }

      let tempNDCNav = sessionStorage.getItem('ndcNavigator') ? sessionStorage.getItem('ndcNavigator') : ''
      if (tempNDCNav && tempNDCNav.toString() !== "null" && tempNDCNav.toString() !== "undefined") {
        this.ndcNavigator = JSON.parse(tempNDCNav)
      } else {
        this.ndcNavigator = null
      }
    }
  }

}
