import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blue-cross-login',
  templateUrl: './blue-cross-login.component.html',
  styleUrls: ['./blue-cross-login.component.scss']
})
export class BlueCrossLoginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    sessionStorage.setItem('whiteLabel','4480');
  }

}
