import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  selector: 'app-wl-pre-register',
  templateUrl: './wl-pre-register.component.html',
  styleUrls: ['./wl-pre-register.component.scss']
})
export class WlPreRegisterComponent implements OnInit {
  @Output() returnResponse: EventEmitter<string> = new EventEmitter()
  public npiValue: string = '';
  public orgId: string = '';
  public error: boolean = false;
  public errMsg: string = '';
  public isNpiValid: boolean = false;
  public npiResponse: any = [];
  public success: boolean = false;
  public successMsg: string = '';
  public isAfterNpi: boolean = false;

  NpiForm = this.fb.group({
    npi: [null, Validators.required]
  })

  constructor(
    private loginService: LoginService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.isAfterNpi = false;
    sessionStorage.getItem('registerDetails') ? sessionStorage.removeItem('registerDetails') : '';
  }

  submitData() {
    this.error = false;
    this.isAfterNpi = false;
    this.npiValue = this.NpiForm.controls.npi.value;
    this.isNpiValid = true;
    if (this.npiValue === null || this.npiValue === undefined || this.npiValue.length === 0) {
      this.error = true;
      this.errMsg = 'NPI is required.';
      this.isNpiValid = false;
    }
    if (this.isNpiValid) {
      this.success = true;
      this.successMsg = 'Authenticating...';
      console.log("NPI Submitted sucessfully: " + this.npiValue);
      this.loginService.npi_lookup(this.npiValue).subscribe(res => {
        this.error = false;
        if (res.status === 200) {
          this.success = true;
          this.successMsg = 'NPI is Valid.';
          this.isAfterNpi = true;
          this.npiResponse = res.body;
        } else if (res.status === 204) {
          this.success = false;
          this.successMsg = '';
          this.error = true;
          this.errMsg = 'Your NPI is incorrect.';
        }
      }, () => {
        this.success = false;
        this.successMsg = '';
        this.error = true;
        this.errMsg = 'Error';
      });
    }
  }

  selectOrg(resId) {
    this.success = false;
    this.successMsg = '';
    this.error = false;
    this.orgId = this.npiResponse[resId]['org_id'];

    let jsonData =
    {
      "orgId": this.orgId, 
      "site": "2"
    }

    this.loginService.validateOrg(jsonData).subscribe(response => {
      let status = response['status'];
      if (status === 0 || status === '0') {
        this.success = true;
        this.successMsg = "Org is valid"
        sessionStorage.setItem('registerDetails', JSON.stringify(this.npiResponse[resId]));
        this.returnResponse.emit();
        this.isAfterNpi = false;
      } else if (status === '1' || status === 1) {
        this.error = true;
        this.errMsg = 'Organization has been terminated.';
      } else if (status === '2' || status === 2) {
        this.error = true;
        this.errMsg = 'No slots available in organization';
      } else {
        this.error = true;
        this.errMsg = 'Error';
      }
    }, () => {
      this.success = false;
      this.successMsg = '';
      this.error = true;
      this.errMsg = 'Error';
    });
  }

  navigateForm() {
    if (this.orgId === '11019' || this.orgId.toString() === '11019') {
      this.router.navigate(['/registration-rcca-aetnava-white-label']);
    } else if (this.orgId === '4480' || this.orgId.toString() === '4480') {
      this.router.navigate(['/registration-rcca-white-label']);
    } else if (this.orgId === '666' || this.orgId.toString() === '666') {
      this.router.navigate(['/registration-rcca-cfhp-white-label']);
    } else if (this.orgId === '10860' || this.orgId.toString() === '10860') {
      this.router.navigate(['/registration-rcca-lite-white-label']);
    } else if (this.orgId === '10670' || this.orgId.toString() === '10670') {
      this.router.navigate(['/registration-rcca-bcbstn-white-label']);
    } else {
      this.router.navigate(['/reg-form'])
    }
  }
}