import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[numeric]',
})

export class NumericDirective {
    // tslint:disable-next-line:no-input-rename
    @Input('decimals') private decimals: number = 0;

    // tslint:disable-next-line:no-input-any
    // tslint:disable-next-line: no-any
    private check(value: string, decimals: number): any {
        if (value) {
            if (value.length === 1 && value.indexOf('.') !== -1) {
                return true;
            }
            //var b = String(value).match(new RegExp(/^\d+$/));//only digit
            var regExpString = '^\\s*((\\d+(\\.\\d{0,2})?)|((\\d*(\\.\\d{1,2}))))\\s*$';
            //var regExpString =  "/(?<=^| )\d+(\.\d+)?(?=$| )|(?<=^| )\.\d+(?=$| )/";
            //var i =  String(value).match(new RegExp(regExpString));
            return String(value).match(new RegExp(regExpString));
        }
    }

    private specialKeys: string[] = [
        'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete',
    ];

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    public onKeyDown(event: KeyboardEvent): void {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        // Do not use event.keycode this is deprecated.
        // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
        let current: string = this.el.nativeElement.value;
        let next: string = current.concat(event.key);
        if (next && !this.check(next, this.decimals)) {
            event.preventDefault();
        }
    }
}
